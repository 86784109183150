import React from "react"
import "./button.css"

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * How large should the button be?
   */
  size?: "small" | "medium" | "large"
  /**
   * Button contents
   */
  label: string
  /**
   * Optional click handler
   */
  onClick?: () => void
  startIcon?: React.ReactNode
  fullwidth?: boolean
  style?: React.CSSProperties
  id?: string
  disabled?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary = false,
  size = "medium",
  label,
  startIcon,
  fullwidth = false,
  style,
  id,
  disabled = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      id={id}
      type="button"
      className="ee-button"
      style={{ width: fullwidth ? "100%" : "inherit", ...style }}
      {...props}
      disabled={disabled}
    >
      {startIcon} {label}
    </button>
  )
}
