import * as React from "react"
import _ from "lodash"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "../../context/AppContext"
import EELoading from "../../component/Loading"
import { useNavigate } from "react-router-dom"
import URLs from "../../routes/urls"
import { getSessionData, setSessionData } from "../../common/util"
import { SESSION_STORAGE_KEY } from "../../constant"
import PerformanceChartPanel from "../Chart/PerformanceChartPanel"
import { Button } from "../../component/Button"
import { RemoveIcon } from "../../assets/images/removeIcon"
import { SettingsIcon } from "../../assets/images/settingsIcon"
import { AddIcon } from "../../assets/images/addIcon"

interface IEEPerformanceProps {}

const EEPerformance: React.FunctionComponent<IEEPerformanceProps> = (props) => {
  const appContext = useAppContext()
  const [selectOption, setSelectOption] = React.useState<any>()
  const { getPerformanceSelectOption, getChartSetting } = appContext
  const [chartCount, setChartCount] = React.useState<number>(1)
  const [defaultSelect, setDefaultSelect] = React.useState<
    {
      id: number
      plotCategoryId: number
      timeRange: null | string
      container: string
      stack: null
      node: null
      state: number
    }[]
  >([])
  const sessionSearch = getSessionData(SESSION_STORAGE_KEY.performanceChart)
  React.useEffect(() => {
    // console.log("sessionSearch", JSON.parse(sessionSearch))
    if (sessionSearch) setDefaultSelect(sessionSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (defaultSelect.length === 0) return
    setSessionData(SESSION_STORAGE_KEY.performanceChart, defaultSelect)
    setChartCount(defaultSelect.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelect])
  const navigate = useNavigate()
  const { data, isPending } = useQuery({
    queryKey: ["operationSelect"],
    queryFn: async () => {
      const data = await getPerformanceSelectOption()
      return data
    },
  })
  const { data: defaultChart } = useQuery({
    queryKey: ["defaultChart"],
    queryFn: async () => {
      const data = await getChartSetting()
      return data
    },
  })
  React.useEffect(() => {
    if (data) {
      const newOption = data.data.map((item) => {
        return { ...item, value: item.id }
      })
      setSelectOption(newOption)
      // setDefaultSelect([0, 1])
      // setChartCount(2)
    }
  }, [data])
  React.useEffect(() => {
    if (defaultChart && !sessionSearch) {
      setDefaultSelect(defaultChart.data.performance)
    }
    if (!defaultChart && !sessionSearch) {
      setDefaultSelect([
        {
          id: 4,
          plotCategoryId: 14,
          timeRange: null,
          container: "1",
          stack: null,
          node: null,
          state: 2,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChart])
  if (isPending) {
    return <EELoading />
  }
  return (
    <div className="page-section-content-wrapper">
      <div className="operation-page-button-wrapper">
        <Button
          onClick={() => {
            setChartCount(chartCount + 1)
            setDefaultSelect([...defaultSelect, defaultSelect[0]])
          }}
          label="Add Chart"
          startIcon={<AddIcon fill="rgb(25, 118, 210)" />}
          style={{
            width: "145px",
            backgroundColor: "transparent",
            border: "1px solid rgba(25, 118, 210, 0.5)",
            color: "rgb(25, 118, 210)",
            boxShadow: "none",
          }}
        ></Button>
        <Button
          disabled={chartCount < 2}
          onClick={() => {
            if (chartCount > 1) {
              setChartCount(chartCount - 1)
              setDefaultSelect((prev) => prev.slice(0, defaultSelect.length - 1))
            }
          }}
          startIcon={<RemoveIcon fill="rgb(25, 118, 210)" />}
          label="Remove Chart"
          style={{
            width: "165px",
            backgroundColor: "transparent",
            border: "1px solid rgba(25, 118, 210, 0.5)",
            color: "rgb(25, 118, 210)",
            boxShadow: "none",
          }}
        ></Button>
        <Button
          onClick={() => navigate(URLs.Profile)}
          startIcon={<SettingsIcon fill="rgb(25, 118, 210)" />}
          label=""
          style={{
            width: "117px",
            backgroundColor: "transparent",
            border: "0px",
            color: "rgb(25, 118, 210)",
            boxShadow: "none",
          }}
        ></Button>
      </div>
      <div className="page-section-content-wrapper">
        {_.times(chartCount, (count) => {
          return (
            <PerformanceChartPanel
              selectOption={selectOption}
              chartId={count}
              defaultSelect={count < chartCount && defaultSelect ? defaultSelect[count] : null}
            />
          )
        })}
      </div>
    </div>
  )
}

export default EEPerformance
