// import React from "react"

// import DialogTitle from "@mui/material/DialogTitle"
// import Dialog from "@mui/material/Dialog"
// import { Box, Button, DialogActions } from "@mui/material"
// import "./eeBasicDialogs.scss"

// export const EEBasicDialog: React.FC<EEBasicDialogProps> = ({ content, open, onClose, title, maxWidth = true }) => {
//   return (
//     <Dialog onClose={onClose} open={open} maxWidth={maxWidth ? "md" : false} fullWidth className="ee-dialog-wrapper">
//       <DialogTitle className="dialog-title-wrapper">{title}</DialogTitle>
//       <Box>{content}</Box>
//       <DialogActions className="dialog-bottom">
//         <Button
//           onClick={onClose}
//           variant="contained"
//           size="large"
//           sx={{ marginRight: "36px", marginBottom: "24px" }}
//           id="ee-dialog-close-button"
//         >
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   )
// }

import React from "react"
import "./eeBasicDialogs.scss"
import { Button } from "../Button"
export interface EEBasicDialogProps {
  open: boolean
  title: any
  children: React.ReactNode
  onClose: () => void
}

export const EEBasicDialog: React.FC<EEBasicDialogProps> = ({ open, title, children, onClose }) => {
  if (!open) return null

  return (
    <div className="custom-dialog-overlay ee-dialog-wrapper">
      <div className="custom-dialog ">
        <div className="dialog-title-wrapper">{title}</div>
        <div>{children}</div>
        <div className="custom-dialog-actions dialog-bottom">
          <Button onClick={onClose} label="Close" id="ee-dialog-close-button" />
        </div>
      </div>
    </div>
  )
}
