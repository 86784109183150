import * as React from "react"
import PeriodForm from "../../component/PeriodForm"
import "./chartWithPeriod.scss"

import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import EELegend from "../../component/charts/EELegend"
import EESelect from "../../component/EESelect"
import {
  PeriodOptions,
  OPTION_NONE_SELECT_VALUE,
  PlotType,
  chartLevel,
  Period,
  SESSION_STORAGE_KEY,
  PLANT_AC_STATUS_LEGEND,
  SYSTEM_STACK_STATUS_LEGEND,
  SYSTEM_NODE_STATUS_LEGEND,
} from "../../constant"
import {
  createNodeOption,
  createStackOption,
  dataConvert,
  dataTimelineConvert,
  getSessionData,
  getStartTime,
  setSessionData,
} from "../../common/util"
import EELoading from "../../component/Loading"
import { chartColors } from "../../common/chartColors"
import EEOperationChart from "../../component/charts/EEOperationChart"
import { useAppContext } from "../../context/AppContext"
import _ from "lodash"
import { getAccessToken } from "../../cookie"
import { useQuery } from "@tanstack/react-query"
import { OperationParam } from "../../type/chart"
import EETimelineChart from "../../component/charts/EETimelineChart"
import EEPopover from "../../component/EEPopover"
import { ZoomOutIcon } from "../../assets/ZoomOutIcon"
import { InfoIcon } from "../../assets/images/info"
import { Button } from "../../component/Button"
interface IChartWithPeriodProps {
  selectOption?: []
  chartId: string
  defaultSelect?: any
}

const ChartWithPeriod: React.FunctionComponent<IChartWithPeriodProps> = ({ selectOption, chartId, defaultSelect }) => {
  const [currentData, setCurrentData] = useState<any>([])
  const [updateData, setUpdateData] = useState<any>([])
  const [dataParam, setDataParam] = useState<OperationParam>({
    container: defaultSelect?.container ? defaultSelect.container : 1,
    plotCategoryId: defaultSelect ? defaultSelect.plotCategoryId : PlotType.voltage,
    plotType: defaultSelect ? defaultSelect.plotCategoryId : PlotType.voltage,
    node: defaultSelect ? defaultSelect.node : null,
    stack: defaultSelect ? defaultSelect.stack : null,
    start: defaultSelect
      ? defaultSelect.start
        ? defaultSelect.start
        : getStartTime(defaultSelect.timeRange)
      : dayjs(dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`))
          .subtract(7, "day")
          .valueOf(),
    end: defaultSelect ? (defaultSelect.end ? defaultSelect.end : dayjs().valueOf()) : dayjs().valueOf(),
  })
  const [noData, setNodata] = useState<boolean>(false)
  const [periodSelect, setPeriodSelect] = useState(
    defaultSelect
      ? defaultSelect.timeRange
        ? PeriodOptions.find((item) => item.value === defaultSelect.timeRange)
        : PeriodOptions[0]
      : PeriodOptions[0]
  )
  const [containerOption, setContainerOption] = useState([])
  const [stackOption, setStackOption] = useState<any>()
  const [nodeOption, setNodeOption] = useState<any>()
  const [newLegend, setNewLegend] = useState<any>()
  const [nodeDisabled, setNodeDisabled] = useState<boolean>(false)
  const appContext = useAppContext()
  const { postOperationChartData, getContainerOptions } = appContext
  const [maxPoint, setMaxPoint] = useState(0)
  const [minPoint, setMinPoint] = useState(0)
  const [zoomedData, setZoomedData] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<any>(
    defaultSelect ? selectOption?.find((item) => item.value === defaultSelect.plotCategoryId) : null
  )
  const [errorMessage, setErrorMessage] = useState("")
  const [unit, setUnit] = useState()
  const [yDomain, setYDomain] = useState<any>([])
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  useEffect(() => {
    if (selectOption) {
      setSelectedOption(
        defaultSelect
          ? selectOption?.find((item) => item.value === defaultSelect.plotCategoryId)
          : selectOption[0].value
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOption])
  const handleToggleChange = (toggleIndex) => {
    const nextLegend = newLegend.map((item, index) => {
      if (toggleIndex === index) {
        return { ...item, checked: !item.checked }
      }
      return item
    })

    setNewLegend(nextLegend)
  }
  const { data, isPending } = useQuery({
    queryKey: ["operationData", dataParam],
    queryFn: async () => {
      const data = await postOperationChartData(dataParam)

      if (data.statusCode === 500 || data.statusCode === 400) {
        setErrorMessage("Internal Server Error")
        setCurrentData(null)
        setUpdateData(null)
        setNewLegend(null)
        return null
      }
      return data
    },
  })
  // const containerOption = createContainerOption()
  const createContainerOption = async () => {
    const containerOptionsResponse = await getContainerOptions()
    if (containerOptionsResponse && containerOptionsResponse.statusCode === 200) {
      setContainerOption(containerOptionsResponse.data)
      setDataParam((prev) => Object.assign({ ...prev, container: containerOptionsResponse.data[0].value }))
    }
  }

  useEffect(() => {
    createContainerOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const createData = () => {
    if (data) setNodata(false)

    setErrorMessage("")
    setMaxPoint(data.maxPoint)
    setMinPoint(data.minPoint)
    setUnit(data.unit)
    const keyArray = Object.keys(data.plotData).filter(function (letter) {
      return letter !== "time"
    })
    if (dataParam.plotCategoryId === PlotType.status) {
      const newarray = keyArray.slice().reverse()
      setYDomain(newarray)
      // console.log("after revers", keyArray)
    }
    if (data.plotData && keyArray.length > 0) {
      const timeValue = data.plotData.time
      if (timeValue.length < 1) {
        setNodata(true)

        return
      }
      const newDataValue = _.pick(data.plotData, keyArray)
      const chartData: any = []
      _.times(keyArray.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = keyArray[item]
        dataNew.values =
          dataParam.plotCategoryId === PlotType.status
            ? dataTimelineConvert({ time: timeValue, value: newDataValue[keyArray[item]] || [] })
            : dataConvert({ time: timeValue, value: newDataValue[keyArray[item]] || [] })
        if (dataParam.plotCategoryId === PlotType.status) {
          dataNew.translate = data.level === chartLevel.stack ? (item + 1) * 21 : (item + 1) * 31
        }
        chartData.push(dataNew)
      })

      setCurrentData(chartData)
      setUpdateData(chartData)
      // console.log("data exist???", chartData)
      setNodata(false)
    } else {
      setNodata(true)
    }
  }

  useEffect(() => {
    if (!getAccessToken()) {
      return
    }
    if (data && Object.keys(data).length > 0) {
      createData()
    } else {
      setNodata(true)
      setCurrentData(null)
      setUpdateData(null)
      setNewLegend(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, getAccessToken()])

  useEffect(() => {
    const legend: any = []
    if (currentData && currentData.length > 0) {
      const dataKeys = Object.keys(currentData)
      dataKeys.map((item, index) => {
        return legend.push({ title: currentData[index].name, color: currentData[index].color, checked: true })
      })
      setNewLegend(legend)
    }
  }, [currentData])

  useEffect(() => {
    if (newLegend) {
      const nextData: any = []
      newLegend.map((item, index) => {
        if (item.checked) return nextData.push(currentData[index])
        return null
      })
      setUpdateData(nextData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLegend])

  const handleDisplayAll = () => {
    if (newLegend) {
      const nextLegend = newLegend.map((item) => {
        return { ...item, checked: true }
      })
      setNewLegend(nextLegend)
    }
  }
  useEffect(() => {
    if (selectedOption) {
      const sOption = !selectedOption.level.stack.activate ? null : createStackOption(selectedOption)
      const nOption = !selectedOption.level.node.activate ? null : createNodeOption(selectedOption)
      setStackOption(sOption)
      setNodeOption(nOption)
      if (nOption === null) {
        setDataParam((prev) => Object.assign({ ...prev, node: null }))
      }
      if (sOption === null) {
        setDataParam((prev) => Object.assign({ ...prev, stack: null }))
      }
    }
  }, [selectedOption])
  const handleFromDateChange = useCallback(
    (value) => {
      if (dataParam.end) {
        if (dayjs(dataParam.end).isAfter(dayjs(value)) || dayjs(dataParam.end).isSame(dayjs(value))) {
          // setFromdate(value)
          setDataParam((prev) => Object.assign({ ...prev, start: dayjs(value).valueOf() }))
          //api call
          return
        } else {
          alert(`Choose Date before ${dayjs(dataParam.end).format("YYYY-MM-DD")}`)
          // setDataParam((prev) => Object.assign({ ...prev, start: null }))
          return
        }
      } else {
        setDataParam((prev) => Object.assign({ ...prev, start: dayjs(value).valueOf() }))
        return
      }
    },
    [dataParam]
  )
  const handleToDateChange = useCallback(
    (value) => {
      if (dataParam.start) {
        if (dayjs(dataParam.start).isBefore(dayjs(value)) || dayjs(dataParam.start).isSame(dayjs(value))) {
          //api call
          setDataParam((prev) => Object.assign({ ...prev, end: dayjs(value).valueOf() }))
          return
        } else {
          alert(`Choose Date after ${dayjs(dataParam.start).format("YYYY MM DD")}`)
          // setDataParam((prev) => Object.assign({ ...prev, end: null }))
          return
        }
      } else {
        setDataParam((prev) => Object.assign({ ...prev, end: dayjs(value).valueOf() }))

        return
      }
    },
    [dataParam]
  )
  const handleSelectChange = useCallback((newValue) => {
    if (newValue.value === Period.oneHour) {
      setDataParam((prev) =>
        Object.assign({
          ...prev,
          start: dayjs().subtract(1, "hour").valueOf(),
          end: dayjs().valueOf(),
          timeRange: newValue,
        })
      )
    }
    if (newValue.value === Period.oneDay) {
      setDataParam((prev) =>
        Object.assign({
          ...prev,
          start: dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`)
            .subtract(1, "day")
            .valueOf(),
          end: dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`).valueOf(),
          timeRange: newValue,
        })
      )
    }
    if (newValue.value === Period.sevenDays) {
      setDataParam((prev) =>
        Object.assign({
          ...prev,
          start: dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`)
            .subtract(7, "day")
            .valueOf(),
          end: dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`).valueOf(),
          timeRange: newValue,
        })
      )
    }
    setPeriodSelect(newValue)
  }, [])

  useEffect(() => {
    if (dataParam.plotCategoryId === PlotType.temperature && dataParam.stack === null) {
      setDataParam((prev) => Object.assign({ ...prev, stack: 1, node: null }))
    }
    if (dataParam.stack === null && dataParam.plotCategoryId !== PlotType.temperature) {
      setNodeDisabled(true)
    } else if (dataParam.node === null) {
      setNodeDisabled(false)
    } else if (dataParam.stack !== null) {
      setNodeDisabled(false)
    } else {
      setNodeDisabled(false)
    }
    const sessionData = getSessionData(SESSION_STORAGE_KEY.operationChart)
    if (sessionData) {
      const newSessionData = sessionData.map((item, index) =>
        index === Number(chartId) ? { ...item, ...dataParam } : item
      )
      setSessionData(SESSION_STORAGE_KEY.operationChart, newSessionData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParam])
  const handleBrushed = useCallback(
    (timeRange) => {
      if (dayjs(timeRange.end).valueOf() - dayjs(timeRange.start).valueOf() < 10000) {
        return
      }
      setZoomedData([
        ...zoomedData,
        {
          start: dataParam.start,
          end: dataParam.end,
        },
      ])
      setDataParam((prev) =>
        Object.assign({ ...prev, start: dayjs(timeRange.start).valueOf(), end: dayjs(timeRange.end).valueOf() })
      )
      setUpdateData(null)

      return true
    },
    [zoomedData, dataParam]
  )
  const handleZoomout = useCallback(() => {
    if (zoomedData.length > 0) {
      setDataParam((prev) =>
        Object.assign({
          ...prev,
          start: zoomedData[zoomedData.length - 1].start,
          end: zoomedData[zoomedData.length - 1].end,
        })
      )
      setZoomedData((prev) => prev.slice(0, zoomedData.length - 1))
    }
  }, [zoomedData])
  if (isPending) {
    return (
      <div className="line-chart-wrapper">
        <EELoading />
      </div>
    )
  }
  return (
    <div
      className="line-chart-wrapper"
      key={chartId}
      style={{
        height: dataParam.plotCategoryId === PlotType.status && data.level === chartLevel.stack ? "900px" : "600px",
      }}
    >
      <div className="line-chart-title">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          {selectOption ? (
            <EESelect
              value={dataParam.plotCategoryId}
              onChange={(newValue) => {
                setDataParam((prev) =>
                  Object.assign({ ...prev, plotCategoryId: newValue.value, plotType: newValue.value })
                )
                // console.log("newValue", newValue)
                setSelectedOption(newValue)
              }}
              options={selectOption}
            />
          ) : null}
          {/* {data && data.cycleCount ? (
            <div style={{ display: "flex", flexDirection: "row", marginLeft: "24px" }}>
              <div style={{ fontSize: "1.2rem" }}>Cycle Count</div>

              <EEPopover
                content={
                  <span>
                    Avg. state of charge (SoC) <br />
                    based cycle of all the nodes <br />
                    in the selected level
                  </span>
                }
                children={<InfoIcon width="16px" fill="var(--chart-axis-line)" />}
              ></EEPopover>
              <div style={{ fontSize: "1.2rem", marginRight: "24px" }}>: {data.cycleCount}</div>
            </div>
          ) : null} */}
        </div>
        <div className="chart-form-wrapper">
          <div onClick={handleZoomout} style={{ marginRight: "16px", cursor: "pointer", marginLeft: "16px" }}>
            <ZoomOutIcon fill="var(--tooltip-text)" />
          </div>

          {containerOption ? (
            <>
              <EESelect
                // value="1"
                value={dataParam.container}
                onChange={(newValue) => {
                  setDataParam((prev) => Object.assign({ ...prev, container: Number(newValue.value) }))
                }}
                options={containerOption}
              />
            </>
          ) : null}
          {stackOption ? (
            <EESelect
              value={dataParam.stack === null ? OPTION_NONE_SELECT_VALUE : dataParam.stack.toString()}
              onChange={(newValue) => {
                const stackId = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
                setDataParam((prev) => Object.assign({ ...prev, stack: stackId === null ? null : Number(stackId) }))
              }}
              options={stackOption}
            />
          ) : null}
          {nodeOption ? (
            <EESelect
              disabled={nodeDisabled}
              value={dataParam.node === null ? OPTION_NONE_SELECT_VALUE : dataParam.node}
              onChange={(newValue) => {
                const nodeId =
                  newValue.value === OPTION_NONE_SELECT_VALUE || dataParam.stack === null ? null : newValue.value
                setDataParam((prev) => Object.assign({ ...prev, node: nodeId }))
              }}
              options={nodeOption}
            />
          ) : null}

          <PeriodForm
            toDate={dayjs(dataParam.end).format("YYYY-MM-DD HH:mm:ss")}
            fromDate={dayjs(dataParam.start).format("YYYY-MM-DD HH:mm:ss")}
            onToDateChange={handleToDateChange}
            onFromDateChange={handleFromDateChange}
          />
          <EESelect
            onChange={handleSelectChange}
            options={PeriodOptions}
            value={periodSelect ? periodSelect.value : PeriodOptions[0].value}
          />
        </div>
      </div>
      <div className="chart-legend-wrapper">
        <div style={{ width: "100%" }}>
          {updateData && updateData.length > 0 ? (
            <>
              {dataParam.plotCategoryId === PlotType.status ? (
                <div>
                  <EETimelineChart data={updateData} yDomain={yDomain} level={data.level} />
                  <div
                    style={{
                      display: "block",
                      float: "left",
                      position: "relative",
                      marginLeft: "55px",
                      marginTop: data.level === 3 ? "15px" : "20px",
                    }}
                  >
                    {data.level === 0
                      ? PLANT_AC_STATUS_LEGEND.map((item, index) => (
                          <div className="system-legend" key={index}>
                            <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                            <span>{item.label}</span>
                          </div>
                        ))
                      : data.level === 2
                      ? SYSTEM_STACK_STATUS_LEGEND.map((item, index) => (
                          <div className="system-legend" key={index} style={{ float: "left" }}>
                            <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                            <span>{item.label}</span>
                          </div>
                        ))
                      : SYSTEM_NODE_STATUS_LEGEND.map((item, index) => (
                          <div className="system-legend" key={index} style={{ float: "left" }}>
                            <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                            <span>{item.label}</span>
                          </div>
                        ))}
                  </div>
                </div>
              ) : (
                <EEOperationChart
                  data={updateData}
                  handleBrushed={handleBrushed}
                  maxPoint={maxPoint}
                  minPoint={minPoint}
                  divId={chartId}
                  scale={unit}
                />
              )}
            </>
          ) : !noData ? (
            <EELoading />
          ) : errorMessage ? (
            <div className="chart-no-data">{errorMessage}</div>
          ) : (
            <div className="chart-no-data">No Data</div>
          )}
        </div>

        <div className="ee-legend-container">
          {updateData && updateData.length > 0 ? (
            <div className="ee-legend-button" onClick={handleDisplayAll}>
              Display All
            </div>
          ) : null}
          {newLegend &&
            newLegend.map((item, index) => (
              <EELegend
                title={item.title}
                color={dataParam.plotCategoryId === PlotType.status ? "" : item.color}
                isToggle
                checked={item.checked}
                onToggleChange={() => handleToggleChange(index)}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ChartWithPeriod
