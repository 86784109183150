import { PlotType } from "../constant"

export const SystemData = [
  {
    stackIdx: 1,
    label: "Stack 1",
    stackStatus: 0,
    warningCount: 2,
    warningNode: 5,
    nodeId: 5,
    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5.01",
      },
      {
        label: "Power",
        value: "39468kW",
      },
    ],
  },
  {
    stackIdx: 2,
    label: "Stack 2",
    stackStatus: 1,
    warningNode: 0,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5",
      },
      {
        label: "Power",
        value: "48746kW",
      },
    ],
  },
  {
    stackIdx: 3,
    label: "Stack 3",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5.02",
      },
      {
        label: "Power",
        value: "39602kW",
      },
    ],
  },
  {
    stackIdx: 4,
    label: "Stack 4",
    stackStatus: 3,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5",
      },
      {
        label: "Power",
        value: "39671kW",
      },
    ],
  },
  {
    stackIdx: 5,
    label: "Stack 5",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5",
      },
      {
        label: "Power",
        value: "39772kW",
      },
    ],
  },
  {
    stackIdx: 6,
    label: "Stack 6",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5.01",
      },
      {
        label: "Power",
        value: "39566kW",
      },
    ],
  },
  {
    stackIdx: 7,
    label: "Stack 7",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "4.99",
      },
      {
        label: "Power",
        value: "35824kW",
      },
    ],
  },
  {
    stackIdx: 8,
    label: "Stack 8",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "4.97",
      },
      {
        label: "Power",
        value: "39588kW",
      },
    ],
  },
  {
    stackIdx: 9,
    label: "Stack 9",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "4.99",
      },
      {
        label: "Power",
        value: "39498kW",
      },
    ],
  },
  {
    stackIdx: 10,
    label: "Stack 10",
    stackStatus: 4,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5.01",
      },
      {
        label: "Power",
        value: "39639kW",
      },
    ],
  },
  {
    stackIdx: 11,
    label: "Stack 11",
    stackStatus: 2,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5.01",
      },
      {
        label: "Power",
        value: "39435kW",
      },
    ],
  },
  {
    stackIdx: 12,
    label: "Stack 12",
    stackStatus: 1,
    warningNode: 5,
    nodeId: null,

    info: [
      {
        label: "SoS Warning",
        value: "0",
      },
      {
        label: "Alerts",
        value: "0",
      },
      {
        label: "Activated Nodes",
        value: "0",
      },
      {
        label: "SoC",
        value: "5.04",
      },
      {
        label: "Power",
        value: "39636kW",
      },
    ],
  },
]

export const BpuData = [
  { label: "BPU 1", bpuStatus: 1 },
  { label: "BPU 2", bpuStatus: 1 },
  { label: "BPU 3", bpuStatus: 1 },
  { label: "BPU 4", bpuStatus: 2 },
  { label: "BPU 5", bpuStatus: 1 },
  { label: "BPU 6", bpuStatus: 1 },
  { label: "BPU 7", bpuStatus: 3 },
  { label: "BPU 8", bpuStatus: 1 },
  { label: "BPU 9", bpuStatus: 1 },
  { label: "BPU 10", bpuStatus: 4 },
  { label: "BPU 11", bpuStatus: 1 },
  { label: "BPU 12", bpuStatus: 5 },
]

export const StackInfoData = [
  { label: "1 Left", value: 85, nodeStatus: 1, id: 1 },
  { label: "1 Right", value: 85, nodeStatus: 1, id: 2 },
  { label: "2 Left", value: 85, nodeStatus: 1, id: 3 },
  { label: "2 Right", value: 0, nodeStatus: 5, id: 4 },
  { label: "3 Left", value: 0, nodeStatus: 5, id: 5 },
  { label: "3 Right", value: 85, nodeStatus: 1, id: 6 },
  { label: "4 Left", value: 85, nodeStatus: 1, id: 7 },
  { label: "4 Right", value: 85, nodeStatus: 1, id: 8 },
  { label: "5 Left", value: 24, nodeStatus: 2, id: 9 },
  { label: "5 Right", value: 24, nodeStatus: 2, id: 10 },
  { label: "6 Left", value: 85, nodeStatus: 1, id: 11 },
  { label: "6 Right", value: 85, nodeStatus: 1, id: 12 },
  { label: "7 Left", value: 85, nodeStatus: 1, id: 13 },
  { label: "7 Right", value: 85, nodeStatus: 1, id: 14 },
  { label: "8 Left", value: null, nodeStatus: 4, id: 15 },
  { label: "8 Right", value: null, nodeStatus: 4, id: 16 },
  { label: "9 Left", value: 85, nodeStatus: 1, id: 17 },
  { label: "9 Right", value: 85, nodeStatus: 1, id: 18 },
  { label: "10 Left", value: 85, nodeStatus: 1, id: 19 },
  { label: "10 Right", value: 85, nodeStatus: 1, id: 10 },
  { label: "11 Left", value: 85, nodeStatus: 1, id: 21 },
  { label: "11 Right", value: 85, nodeStatus: 1, id: 22 },
  { label: "12 Left", value: 85, nodeStatus: 1, id: 23 },
  { label: "12 Right", value: 85, nodeStatus: 1, id: 24 },
  { label: "13 Left", value: 50, nodeStatus: 3, id: 25 },
  { label: "13 Right", value: 50, nodeStatus: 3, id: 26 },
  { label: "14 Left", value: 85, nodeStatus: 1, id: 27 },
  { label: "14 Right", value: 85, nodeStatus: 1, id: 28 },
  { label: "15 Left", value: 85, nodeStatus: 1, id: 29 },
  { label: "15 Right", value: 85, nodeStatus: 1, id: 30 },
  { label: "16 Left", value: 85, nodeStatus: 1, id: 31 },
  { label: "16 Right", value: 85, nodeStatus: 1, id: 32 },
]
export const StackStatus = { charging: 1, discharging: 2, standby: 3, shutdown: 4, nodata: 5, warning: 0, alert: 12 }
export const StackStatusColor = {
  [StackStatus.charging]: "#48b848",
  [StackStatus.discharging]: "#F2D852",
  [StackStatus.standby]: "#1E85FF",
  [StackStatus.shutdown]: "#ADADAD",
  [StackStatus.nodata]: "#E63535",
  [StackStatus.warning]: "#E63535",
  [StackStatus.alert]: "#E63535",
}

export const StackStatusLabel = {
  [StackStatus.charging]: "Charging",
  [StackStatus.discharging]: "Discharging",
  [StackStatus.standby]: "Standby",
  [StackStatus.shutdown]: "Shutdown",
  [StackStatus.nodata]: "NoData",
}
export const ContainerSampleData = [
  { status: StackStatus.charging, container: "container 1", isAlarm: false },
  { status: StackStatus.charging, container: "container 2", isAlarm: true },
  { status: StackStatus.charging, container: "container 3", isAlarm: false },
  { status: StackStatus.charging, container: "container 4", isAlarm: false },
  { status: StackStatus.charging, container: "container 5", isAlarm: false },
  { status: StackStatus.charging, container: "container 6", isAlarm: false },
  { status: StackStatus.charging, container: "container 7", isAlarm: false },
  { status: StackStatus.discharging, container: "container 8", isAlarm: false },
  { status: StackStatus.charging, container: "container 9", isAlarm: false },
  { status: StackStatus.charging, container: "container 10", isAlarm: false },
  { status: StackStatus.charging, container: "container 11", isAlarm: false },
  { status: StackStatus.shutdown, container: "container 12", isAlarm: false },
  { status: StackStatus.charging, container: "container 13", isAlarm: false },
  { status: StackStatus.charging, container: "container 14", isAlarm: false },
  { status: StackStatus.charging, container: "container 15", isAlarm: false },
  { status: StackStatus.charging, container: "container 16", isAlarm: false },
  { status: StackStatus.charging, container: "container 17", isAlarm: true },
  { status: StackStatus.discharging, container: "container 18", isAlarm: false },
  { status: StackStatus.discharging, container: "container 19", isAlarm: false },
  { status: StackStatus.charging, container: "container 20", isAlarm: false },
  { status: StackStatus.charging, container: "container 21", isAlarm: false },
  { status: StackStatus.charging, container: "container 22", isAlarm: false },
  { status: StackStatus.charging, container: "container 23", isAlarm: false },
]
export const ProfileOperationSampleData = [
  {
    plotType: PlotType.voltage,
    container: 1,
    node: "1-L",
    stack: 1,
    period: 1,
  },
  {
    plotType: PlotType.current,
    container: 1,
    node: null,
    stack: 1,
    period: 1,
  },
  {
    plotType: PlotType.status,
    container: 1,
    node: null,
    stack: 1,
    period: 1,
  },
]
export const ProfilePerformanceSampleData = [
  {
    plotType: PlotType.capacity,
    container: 1,
    node: "1-L",
    stack: 1,
  },
  {
    plotType: PlotType.energy,
    container: 1,
    node: "1-L",
    stack: 1,
  },
]
export const PlantContainerData = [
  {
    containerId: "1",
    container: "Container 1",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "2",
    container: "Container 2",
    status: 1,
    isAlarm: true,
    detail: {
      soc: "73%",
      critical: 2,
      warning: 3,
      alert: 1,
    },
  },
  {
    containerId: "3",
    container: "Container 3",
    status: 4,
    isAlarm: false,
    detail: {
      soc: "-",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "4",
    container: "Container 4",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "5",
    container: "Container 5",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "6",
    container: "Container 6",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "7",
    container: "Container 7",
    status: 2,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "8",
    container: "Container 8",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "9",
    container: "Container 9",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "10",
    container: "Container 10",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "11",
    container: "Container 11",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "12",
    container: "Container 12",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "13",
    container: "Container 13",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "14",
    container: "Container 14",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "15",
    container: "Container 15",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "16",
    container: "Container 16",
    status: 4,
    isAlarm: false,
    detail: {
      soc: "-",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "17",
    container: "Container 17",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73.5%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "18",
    container: "Container 18",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73.5%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "19",
    container: "Container 19",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73.5%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "20",
    container: "Container 20",
    status: 1,
    isAlarm: true,
    detail: {
      soc: "73.5%",
      critical: 1,
      warning: 2,
      alert: 3,
    },
  },
  {
    containerId: "21",
    container: "Container 21",
    status: 2,
    isAlarm: false,
    detail: {
      soc: "73.5%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "22",
    container: "Container 22",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73.5%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
  {
    containerId: "23",
    container: "Container 23",
    status: 1,
    isAlarm: false,
    detail: {
      soc: "73.5%",
      critical: 0,
      warning: 0,
      alert: 0,
    },
  },
]

export const PlantChartData = {
  statusCode: 200,
  success: true,
  data: {
    plotData: {
      cycle: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
      Capacity: [
        146.935, 132.519, 169.516, 224.109, 170.197, 169.945, 171.446, 169.741, 169.095, 169.199, 169.265, 169.785,
        174.289, 173.166, 174.102, 174.454, 174.742, 286.149, 197.055, 174.531,
      ],
      "Percent 1": [
        83.867, 75.639, 96.756, 127.916, 97.144, 97.001, 97.858, 96.884, 96.516, 96.575, 96.613, 96.909, 99.48, 98.839,
        99.373, 99.574, 99.739, 163.327, 112.474, 99.618,
      ],
      Sod: [
        146.935, 132.519, 169.516, 224.109, 170.197, 169.945, 171.446, 169.741, 169.095, 169.199, 169.265, 169.785,
        174.289, 173.166, 174.102, 174.454, 174.742, 286.149, 197.055, 174.531,
      ],
      "Percent 2": [
        93.867, 85.639, 106.756, 137.916, 107.144, 107.001, 107.858, 106.884, 106.516, 106.575, 106.613, 106.909,
        109.48, 108.839, 109.373, 109.574, 109.739, 173.327, 122.474, 109.618,
      ],
    },
    maxValuePoint: 286.149,
    minValuePoint: 132.519,
    maxPercentPoint: 173.327,
    minPercentPoint: 75.639,
    defaultColumns: null,
    level: 2,
    unit: ["%", "Ah"],
  },
  msg: "Success",
}

export const PlantAlarmData = [
  { date: "04.25", alert: 1, warning: 3, critical: 2 },
  { date: "04.26", alert: 1, warning: 4, critical: 0 },
  { date: "04.27", alert: 3, warning: 2, critical: 2 },
  { date: "04.28", alert: 2, warning: 5, critical: 1 },
  { date: "04.29", alert: 3, warning: 3, critical: 2 },
  { date: "04.30", alert: 1, warning: 2, critical: 0 },
  { date: "05.01", alert: 3, warning: 1, critical: 1 },
]
