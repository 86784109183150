const PostLandingStack = "landing/v1/main_image/stack/"
const PostLandingNode = "landing/v1/main_image/node/"
const GetOperationSelectOption = "ops/v1/plot/selections"
const PostOperationData = "ops/v1/plot"
const LoginCheck = "user/validate"
const Logout = "user/logout"
const GetUserInfo = "user/v1/info"
const AuthCodeRequest = "user/v1/mobile/request"
const AuthCodeVerify = "user/v1/mobile/verify"
const GetAlarmList = "alarm/v1/alarm/list"
const GetAlarm = "alarm/v1/alarm"
const GetMainStats = "landing/v1/stats/main"
const GetMainMetrics = "landing/v1/stats/metrics"
const GetMainImage = "landing/v1/image/main"
const GetPerformanceOption = "perf/v1/plot/selections"
const GetPerformanceData = "perf/v1/plot"
const GetSosData = "sos/v1/plot"
const GetSodData = "sod/v1/plot"
const SSOAzureLogin = "user/login/azureadv2"
const GetRequestList = "alarm/v1/request/list"
const CreateRequest = "alarm/v1/request/new"
const GetFlagedSoS = "sos/v1/flag"
const UpdateOperateStatus = "alarm/v1/request"
const GetRequest = "alarm/v1/request"
const GetUserList = "admin/v1/user/list"
const UpdateUser = "admin/v1/user"
const GetRXLData = "ai/1v/plot "
const UserChartSetting = "settings/v1/plot"
const GetPlantPageData = "landing/v1/image/plant"
const GetContainerOptions = "option/v1/container/list"
const GetStackOptions = "option/v1/stack/list"
const GetNodeOptions = "option/v1/node/list"
const WeatherApi =
  "https://forecast.weather.gov/MapClick.php?lat=33.1774&lon=-99.5687&unit=0&lg=english&FcstType=json&TextType=1"
const UpdateContainerName = "admin/v1/plot/container_label"
const GetPlotCategory = "admin/v1/plot/category"
const UpdatePlotCategoryState = "admin/v1/plot/category"
const GetAlarmSettingList = "alarm/v1/alarm/setting/list"
const UpdateAlarmSetting = "alarm/v1/alarm/setting"
const GetEnergyThoughput = "landing/v1/stats/energy"

const ServerURLs = {
  PostLandingStack,
  PostLandingNode,
  GetOperationSelectOption,
  PostOperationData,
  LoginCheck,
  GetUserInfo,
  GetAlarm,
  GetAlarmList,
  GetMainStats,
  GetMainMetrics,
  GetMainImage,
  GetPerformanceOption,
  GetPerformanceData,
  GetSosData,
  GetSodData,
  SSOAzureLogin,
  GetRequestList,
  CreateRequest,
  GetFlagedSoS,
  UpdateOperateStatus,
  GetRequest,
  Logout,
  GetUserList,
  GetRXLData,
  UserChartSetting,
  UpdateUser,
  AuthCodeRequest,
  AuthCodeVerify,
  GetPlantPageData,
  GetContainerOptions,
  WeatherApi,
  GetStackOptions,
  GetNodeOptions,
  UpdateContainerName,
  GetPlotCategory,
  UpdatePlotCategoryState,
  GetAlarmSettingList,
  UpdateAlarmSetting,
  GetEnergyThoughput,
}
export default ServerURLs
