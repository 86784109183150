import React from "react"

const Gauge = ({ percent }) => {
  const gaugeArray = [
    <path
      d="M215.763 137.232C216.174 137.232 216.507 136.899 216.503 136.489C216.503 136.476 216.503 136.464 216.503 136.452C216.498 136.041 216.158 135.715 215.747 135.723C215.337 135.732 215.011 136.075 215.015 136.485C215.019 136.896 215.352 137.232 215.763 137.232Z"
      fill="#E9EDF0"
    />,
    <path
      d="M233.265 137C233.601 137 233.874 136.728 233.872 136.391C233.869 136.055 233.593 135.786 233.257 135.79L220.828 135.947C220.539 135.95 220.308 136.188 220.309 136.477C220.311 136.766 220.545 137 220.835 137H233.265Z"
      fill="#E9EDF0"
    />,
    <path
      d="M215.483 130.367C215.893 130.331 216.196 129.97 216.156 129.561C216.155 129.549 216.153 129.537 216.152 129.525C216.112 129.116 215.745 128.821 215.336 128.865C214.928 128.909 214.633 129.279 214.673 129.688C214.713 130.097 215.074 130.403 215.483 130.367Z"
      fill="#E9EDF0"
    />,
    <path
      d="M232.898 128.61C233.233 128.581 233.481 128.285 233.45 127.951C233.418 127.616 233.12 127.372 232.785 127.406L220.417 128.644C220.129 128.673 219.92 128.93 219.947 129.218C219.974 129.505 220.227 129.719 220.515 129.693L232.898 128.61Z"
      fill="#E9EDF0"
    />,
    <path
      d="M214.607 123.552C215.011 123.48 215.282 123.095 215.206 122.691C215.204 122.679 215.202 122.667 215.2 122.655C215.124 122.251 214.732 121.989 214.329 122.068C213.926 122.148 213.665 122.542 213.741 122.946C213.816 123.35 214.202 123.623 214.607 123.552Z"
      fill="#E9EDF0"
    />,
    <path
      d="M231.802 120.284C232.133 120.226 232.355 119.91 232.294 119.579C232.234 119.248 231.915 119.031 231.585 119.094L219.372 121.406C219.088 121.46 218.901 121.734 218.953 122.018C219.005 122.302 219.276 122.493 219.561 122.442L231.802 120.284Z"
      fill="#E9EDF0"
    />,
    <path
      d="M213.139 116.839C213.536 116.733 213.772 116.325 213.662 115.929C213.658 115.917 213.655 115.905 213.652 115.894C213.541 115.498 213.128 115.271 212.734 115.385C212.339 115.5 212.113 115.915 212.224 116.311C212.334 116.707 212.743 116.945 213.139 116.839Z"
      fill="#E9EDF0"
    />,
    <path
      d="M229.985 112.085C230.309 111.998 230.502 111.664 230.413 111.34C230.324 111.016 229.988 110.827 229.664 110.918L217.699 114.286C217.421 114.364 217.259 114.653 217.335 114.932C217.412 115.211 217.699 115.377 217.978 115.302L229.985 112.085Z"
      fill="#E9EDF0"
    />,
    <path
      d="M211.092 110.28C211.478 110.139 211.678 109.712 211.533 109.328C211.529 109.316 211.525 109.305 211.52 109.293C211.376 108.909 210.945 108.719 210.562 108.867C210.179 109.015 209.99 109.449 210.134 109.834C210.279 110.218 210.706 110.42 211.092 110.28Z"
      fill="#E9EDF0"
    />,
    <path
      d="M227.459 104.075C227.775 103.96 227.938 103.611 227.821 103.296C227.704 102.981 227.353 102.822 227.038 102.941L215.412 107.339C215.142 107.441 215.006 107.744 215.106 108.015C215.207 108.286 215.507 108.426 215.779 108.327L227.459 104.075Z"
      fill="#E9EDF0"
    />,
    <path
      d="M208.482 103.924C208.854 103.75 209.015 103.308 208.838 102.937C208.833 102.926 208.827 102.915 208.822 102.904C208.644 102.533 208.198 102.382 207.83 102.563C207.461 102.744 207.311 103.192 207.488 103.563C207.666 103.933 208.109 104.097 208.482 103.924Z"
      fill="#E9EDF0"
    />,
    <path
      d="M224.245 96.3167C224.55 96.1747 224.682 95.8124 224.538 95.5087C224.394 95.2049 224.03 95.0777 223.727 95.2236L212.529 100.618C212.268 100.743 212.159 101.056 212.283 101.317C212.406 101.579 212.718 101.692 212.98 101.57L224.245 96.3167Z"
      fill="#E9EDF0"
    />,
    <path
      d="M205.327 97.8198C205.683 97.6144 205.805 97.1593 205.596 96.8056C205.59 96.795 205.583 96.7845 205.577 96.7739C205.368 96.4203 204.91 96.308 204.559 96.5206C204.208 96.7332 204.097 97.1931 204.306 97.5467C204.515 97.9003 204.971 98.0252 205.327 97.8198Z"
      fill="#E9EDF0"
    />,
    <path
      d="M220.368 88.8676C220.659 88.6995 220.759 88.3272 220.589 88.0371C220.419 87.747 220.045 87.6521 219.756 87.8238L209.071 94.1737C208.822 94.3213 208.74 94.6425 208.887 94.8919C209.033 95.1414 209.353 95.2272 209.603 95.0827L220.368 88.8676Z"
      fill="#E9EDF0"
    />,
    <path
      d="M201.652 92.0135C201.988 91.7779 202.071 91.3139 201.832 90.9798C201.824 90.9698 201.817 90.9598 201.81 90.9498C201.571 90.6158 201.105 90.5438 200.774 90.7862C200.442 91.0287 200.372 91.4964 200.611 91.8305C200.85 92.1645 201.316 92.2491 201.652 92.0135Z"
      fill="#E9EDF0"
    />,
    <path
      d="M215.855 81.7847C216.131 81.5919 216.198 81.2122 216.003 80.9381C215.809 80.6639 215.428 80.6019 215.155 80.7982L205.064 88.0552C204.829 88.224 204.776 88.551 204.943 88.7868C205.11 89.0225 205.436 89.0802 205.673 88.9144L215.855 81.7847Z"
      fill="#E9EDF0"
    />,
    <path
      d="M197.485 86.55C197.8 86.2859 197.841 85.8166 197.574 85.5045C197.566 85.4952 197.558 85.4859 197.55 85.4765C197.283 85.1646 196.813 85.1335 196.503 85.4039C196.194 85.6743 196.165 86.1464 196.432 86.4583C196.7 86.7703 197.171 86.814 197.485 86.55Z"
      fill="#E9EDF0"
    />,
    <path
      d="M210.743 75.122C211.001 74.9059 211.034 74.5219 210.817 74.2657C210.599 74.0096 210.215 73.9809 209.96 74.2002L200.539 82.3092C200.32 82.4978 200.295 82.8282 200.482 83.0485C200.67 83.2688 201 83.2978 201.221 83.1119L210.743 75.122Z"
      fill="#E9EDF0"
    />,
    <path
      d="M192.858 81.4704C193.149 81.1799 193.149 80.7087 192.856 80.4212C192.847 80.4126 192.838 80.404 192.829 80.3954C192.536 80.108 192.065 80.1179 191.78 80.4142C191.496 80.7106 191.508 81.1834 191.801 81.4708C192.095 81.7583 192.568 81.7608 192.858 81.4704Z"
      fill="#E9EDF0"
    />,
    <path
      d="M205.07 68.9304C205.307 68.6927 205.307 68.3072 205.068 68.071C204.829 67.8348 204.444 67.8397 204.209 68.0804L195.531 76.9796C195.329 77.1866 195.333 77.5179 195.539 77.721C195.744 77.9242 196.076 77.9243 196.28 77.7199L205.07 68.9304Z"
      fill="#E9EDF0"
    />,
    <path
      d="M187.806 76.8132C188.07 76.4985 188.029 76.0291 187.712 75.7682C187.702 75.7604 187.693 75.7526 187.683 75.7448C187.366 75.484 186.897 75.535 186.64 75.855C186.382 76.175 186.436 76.6449 186.753 76.9057C187.07 77.1665 187.542 77.1278 187.806 76.8132Z"
      fill="#E9EDF0"
    />,
    <path
      d="M198.878 63.2566C199.094 62.9991 199.06 62.615 198.802 62.4006C198.543 62.1861 198.159 62.2247 197.946 62.4849L190.077 72.1066C189.894 72.3304 189.927 72.66 190.149 72.8445C190.372 73.0289 190.702 73.0002 190.888 72.7787L198.878 63.2566Z"
      fill="#E9EDF0"
    />,
    <path
      d="M182.367 72.6143C182.602 72.2779 182.521 71.8138 182.182 71.5815C182.172 71.5746 182.162 71.5676 182.152 71.5607C181.813 71.3286 181.35 71.4202 181.122 71.7615C180.893 72.1027 180.987 72.5662 181.326 72.7983C181.665 73.0305 182.131 72.9508 182.367 72.6143Z"
      fill="#64A2FF"
    />,
    <path
      d="M192.215 58.1444C192.408 57.869 192.341 57.4893 192.065 57.2982C191.788 57.1071 191.409 57.179 191.22 57.4568L184.219 67.7277C184.056 67.9666 184.118 68.2921 184.355 68.4565C184.593 68.6208 184.92 68.5634 185.085 68.3266L192.215 58.1444Z"
      fill="#64A2FF"
    />,
    <path
      d="M176.583 68.9053C176.788 68.5496 176.666 68.0944 176.309 67.8926C176.298 67.8865 176.287 67.8805 176.276 67.8744C175.919 67.6728 175.466 67.8043 175.268 68.1642C175.07 68.5241 175.204 68.9776 175.562 69.1793C175.92 69.381 176.377 69.2611 176.583 68.9053Z"
      fill="#64A2FF"
    />,
    <path
      d="M185.132 53.6322C185.301 53.341 185.201 52.9686 184.909 52.8024C184.616 52.6361 184.245 52.7407 184.081 53.0339L178.002 63.876C177.86 64.1282 177.95 64.4471 178.201 64.59C178.453 64.733 178.773 64.6474 178.917 64.397L185.132 53.6322Z"
      fill="#64A2FF"
    />,
    <path
      d="M176.583 68.9056C176.788 68.5498 176.666 68.0946 176.309 67.8928C176.298 67.8867 176.287 67.8807 176.276 67.8747C175.919 67.673 175.466 67.8045 175.268 68.1644C175.07 68.5243 175.204 68.9778 175.562 69.1795C175.92 69.3812 176.377 69.2613 176.583 68.9056Z"
      fill="#64A2FF"
    />,
    <path
      d="M185.132 53.6323C185.301 53.3411 185.201 52.9687 184.909 52.8025C184.616 52.6362 184.245 52.7408 184.081 53.034L178.002 63.8761C177.86 64.1283 177.95 64.4472 178.201 64.5901C178.453 64.7331 178.773 64.6475 178.917 64.3971L185.132 53.6323Z"
      fill="#64A2FF"
    />,
    <path
      d="M170.497 65.7148C170.671 65.3425 170.51 64.8997 170.136 64.7298C170.125 64.7247 170.114 64.7196 170.102 64.7145C169.728 64.5449 169.289 64.7153 169.123 65.0911C168.957 65.4669 169.13 65.9069 169.504 66.0767C169.878 66.2465 170.324 66.0871 170.497 65.7148Z"
      fill="#64A2FF"
    />,
    <path
      d="M177.683 49.7545C177.825 49.4498 177.694 49.0875 177.388 48.9474C177.082 48.8072 176.722 48.9437 176.584 49.2502L171.472 60.5808C171.354 60.8444 171.471 61.1542 171.734 61.2747C171.996 61.3953 172.308 61.2821 172.43 61.0201L177.683 49.7545Z"
      fill="#64A2FF"
    />,
    <path
      d="M164.157 63.0664C164.297 62.6804 164.099 62.2532 163.711 62.1166C163.7 62.1125 163.688 62.1084 163.676 62.1043C163.289 61.9679 162.866 62.176 162.734 62.5648C162.601 62.9536 162.812 63.3769 163.199 63.5134C163.587 63.65 164.017 63.4524 164.157 63.0664Z"
      fill="#64A2FF"
    />,
    <path
      d="M169.925 46.5406C170.04 46.2248 169.877 45.8753 169.56 45.7623C169.244 45.6493 168.896 45.8167 168.785 46.1341L164.681 57.8671C164.586 58.14 164.73 58.4384 165.002 58.5356C165.274 58.6328 165.574 58.4928 165.673 58.2212L169.925 46.5406Z"
      fill="#64A2FF"
    />,
    <path
      d="M157.61 60.9809C157.716 60.5841 157.481 60.1759 157.083 60.0736C157.071 60.0705 157.059 60.0674 157.047 60.0644C156.649 59.9622 156.246 60.2064 156.148 60.6053C156.05 61.0041 156.297 61.4074 156.695 61.5097C157.093 61.6119 157.503 61.3777 157.61 60.9809Z"
      fill="#64A2FF"
    />,
    <path
      d="M161.915 44.0153C162.002 43.6906 161.809 43.3566 161.484 43.2716C161.159 43.1867 160.828 43.3837 160.745 43.7095L157.679 55.7556C157.607 56.0358 157.777 56.3206 158.056 56.3936C158.336 56.4667 158.623 56.3011 158.698 56.0219L161.915 44.0153Z"
      fill="#64A2FF"
    />,
    <path
      d="M150.906 59.474C150.977 59.0695 150.707 58.6833 150.302 58.6161C150.29 58.614 150.278 58.612 150.265 58.61C149.86 58.5429 149.48 58.8213 149.417 59.2272C149.354 59.6331 149.635 60.0134 150.04 60.0805C150.446 60.1477 150.834 59.8785 150.906 59.474Z"
      fill="#64A2FF"
    />,
    <path
      d="M153.716 42.1977C153.774 41.8666 153.553 41.5508 153.222 41.4945C152.891 41.4382 152.578 41.6634 152.524 41.9951L150.519 54.2626C150.472 54.5479 150.666 54.8168 150.951 54.8653C151.236 54.9137 151.507 54.7237 151.558 54.439L153.716 42.1977Z"
      fill="#64A2FF"
    />,
    <path
      d="M144.096 58.5571C144.132 58.1479 143.829 57.7867 143.42 57.755C143.407 57.7541 143.395 57.7531 143.383 57.7522C142.973 57.7207 142.619 58.0311 142.591 58.441C142.564 58.8508 142.877 59.2051 143.287 59.2367C143.696 59.2683 144.06 58.9663 144.096 58.5571Z"
      fill="#64A2FF"
    />,
    <path
      d="M145.39 41.1016C145.419 40.7667 145.172 40.4713 144.837 40.4441C144.501 40.4169 144.209 40.6685 144.184 41.0037L143.257 53.3992C143.235 53.6875 143.451 53.9386 143.739 53.962C144.028 53.9853 144.281 53.7725 144.307 53.4845L145.39 41.1016Z"
      fill="#64A2FF"
    />,
    <path
      d="M137.232 58.237C137.232 57.8262 136.899 57.4928 136.489 57.4969C136.476 57.4971 136.464 57.4972 136.452 57.4973C136.041 57.5016 135.715 57.8417 135.723 58.2524C135.732 58.6631 136.075 58.9888 136.485 58.9846C136.896 58.9804 137.232 58.6477 137.232 58.237Z"
      fill="#64A2FF"
    />,
    <path
      d="M137 40.7351C137 40.3989 136.728 40.1262 136.391 40.1283C136.055 40.1304 135.786 40.4066 135.79 40.7427L135.947 53.1719C135.95 53.4609 136.188 53.6922 136.477 53.6904C136.766 53.6885 137 53.4544 137 53.1653L137 40.7351Z"
      fill="#64A2FF"
    />,
    <path
      d="M130.367 58.5166C130.331 58.1074 129.97 57.8043 129.561 57.8442C129.549 57.8454 129.537 57.8465 129.525 57.8477C129.116 57.8878 128.821 58.2551 128.865 58.6635C128.909 59.0719 129.279 59.3664 129.688 59.3264C130.097 59.2864 130.403 58.9258 130.367 58.5166Z"
      fill="#64A2FF"
    />,
    <path
      d="M128.61 41.1015C128.581 40.7666 128.285 40.5187 127.951 40.5501C127.616 40.5815 127.372 40.88 127.406 41.2145L128.644 53.5828C128.673 53.8705 128.93 54.0801 129.218 54.0531C129.505 54.0261 129.719 53.7724 129.693 53.4844L128.61 41.1015Z"
      fill="#64A2FF"
    />,
    <path
      d="M123.552 59.3932C123.48 58.9887 123.095 58.7182 122.691 58.7935C122.679 58.7958 122.667 58.7981 122.655 58.8003C122.251 58.8759 121.989 59.2675 122.068 59.6705C122.148 60.0735 122.542 60.3346 122.946 60.2591C123.35 60.1837 123.623 59.7977 123.552 59.3932Z"
      fill="#64A2FF"
    />,
    <path
      d="M120.284 42.1976C120.225 41.8665 119.91 41.6453 119.579 41.7057C119.248 41.7662 119.031 42.0848 119.094 42.4151L121.406 54.6284C121.46 54.9124 121.734 55.0989 122.018 55.0469C122.302 54.995 122.492 54.7236 122.442 54.4389L120.284 42.1976Z"
      fill="#64A2FF"
    />,
    <path
      d="M116.839 60.8605C116.733 60.4638 116.325 60.228 115.929 60.3382C115.917 60.3415 115.905 60.3448 115.894 60.3481C115.498 60.4586 115.271 60.8716 115.385 61.2661C115.5 61.6606 115.915 61.8864 116.311 61.776C116.707 61.6656 116.945 61.2573 116.839 60.8605Z"
      fill="#64A2FF"
    />,
    <path
      d="M112.085 44.0152C111.998 43.6905 111.664 43.4976 111.34 43.5867C111.016 43.6757 110.827 44.0121 110.918 44.3357L114.286 56.3009C114.364 56.5792 114.653 56.7411 114.932 56.6646C115.211 56.588 115.377 56.3011 115.302 56.0219L112.085 44.0152Z"
      fill="#64A2FF"
    />,
    <path
      d="M110.28 62.9074C110.139 62.5215 109.712 62.3221 109.328 62.4664C109.316 62.4707 109.305 62.4751 109.293 62.4794C108.909 62.6239 108.718 63.0551 108.867 63.4381C109.015 63.8212 109.449 64.0099 109.833 63.8654C110.218 63.721 110.42 63.2935 110.28 62.9074Z"
      fill="#64A2FF"
    />,
    <path
      d="M104.075 46.5406C103.96 46.2247 103.611 46.0616 103.296 46.1786C102.981 46.2955 102.822 46.647 102.941 46.9614L107.339 58.5876C107.441 58.858 107.743 58.9942 108.014 58.8936C108.285 58.793 108.426 58.4928 108.327 58.2211L104.075 46.5406Z"
      fill="#64A2FF"
    />,
    <path
      d="M103.924 65.5183C103.75 65.146 103.308 64.9846 102.937 65.1619C102.926 65.1672 102.915 65.1725 102.904 65.1778C102.533 65.3553 102.382 65.8014 102.563 66.1701C102.744 66.5387 103.192 66.6889 103.563 66.5115C103.933 66.3341 104.098 65.8906 103.924 65.5183Z"
      fill="#64A2FF"
    />,
    <path
      d="M96.3167 49.7544C96.1747 49.4497 95.8124 49.3177 95.5087 49.4617C95.2049 49.6057 95.0777 49.9696 95.2236 50.2725L100.618 61.4711C100.743 61.7316 101.056 61.8409 101.317 61.717C101.579 61.5932 101.692 61.2819 101.57 61.0199L96.3167 49.7544Z"
      fill="#64A2FF"
    />,
    <path
      d="M97.8196 68.673C97.6142 68.3173 97.1591 68.1951 96.8055 68.404C96.7949 68.4103 96.7843 68.4165 96.7737 68.4228C96.4201 68.6319 96.3078 69.0895 96.5204 69.4409C96.7331 69.7924 97.1929 69.903 97.5465 69.6939C97.9001 69.4849 98.025 69.0288 97.8196 68.673Z"
      fill="#64A2FF"
    />,
    <path
      d="M88.8674 53.6321C88.6993 53.3409 88.327 53.241 88.0369 53.4109C87.7469 53.5809 87.6519 53.9545 87.8236 54.2435L94.1735 64.9294C94.3212 65.1779 94.6423 65.2595 94.8917 65.1134C95.1412 64.9673 95.2271 64.6473 95.0825 64.3969L88.8674 53.6321Z"
      fill="#64A2FF"
    />,
    <path
      d="M97.8198 68.6731C97.6144 68.3174 97.1593 68.1952 96.8057 68.4041C96.7951 68.4104 96.7845 68.4166 96.7739 68.4229C96.4203 68.632 96.308 69.0896 96.5206 69.4411C96.7333 69.7925 97.1931 69.9031 97.5467 69.6941C97.9003 69.485 98.0252 69.0289 97.8198 68.6731Z"
      fill="#64A2FF"
    />,
    <path
      d="M88.8677 53.6322C88.6996 53.3411 88.3272 53.2412 88.0371 53.4111C87.7471 53.581 87.6521 53.9546 87.8239 54.2436L94.1737 64.9295C94.3214 65.178 94.6425 65.2596 94.892 65.1135C95.1414 64.9674 95.2273 64.6474 95.0827 64.397L88.8677 53.6322Z"
      fill="#64A2FF"
    />,
    <path
      d="M92.0137 72.3479C91.7781 72.0115 91.3141 71.9294 90.98 72.1683C90.9699 72.1755 90.9599 72.1826 90.9499 72.1898C90.616 72.4289 90.5439 72.8946 90.7864 73.2262C91.0288 73.5578 91.4966 73.6278 91.8306 73.3888C92.1647 73.1497 92.2493 72.6844 92.0137 72.3479Z"
      fill="#64A2FF"
    />,
    <path
      d="M81.7847 58.1445C81.5919 57.8691 81.2122 57.802 80.9381 57.9966C80.6639 58.1911 80.6019 58.5716 80.7981 58.8445L88.0552 68.9363C88.224 69.1711 88.551 69.2244 88.7867 69.0571C89.0225 68.8898 89.0802 68.5635 88.9143 68.3267L81.7847 58.1445Z"
      fill="#64A2FF"
    />,
    <path
      d="M86.5502 76.5146C86.2862 76.1999 85.8168 76.1586 85.5048 76.4258C85.4954 76.4338 85.4861 76.4418 85.4768 76.4498C85.1649 76.7171 85.1337 77.1873 85.4041 77.4965C85.6746 77.8057 86.1466 77.8347 86.4586 77.5674C86.7705 77.3002 86.8143 76.8293 86.5502 76.5146Z"
      fill="#64A2FF"
    />,
    <path
      d="M75.1223 63.2568C74.9062 62.9993 74.5222 62.9656 74.266 63.1833C74.0098 63.401 73.9812 63.7854 74.2005 64.0402L82.3095 73.4611C82.4981 73.6802 82.8285 73.7048 83.0488 73.5176C83.2691 73.3304 83.2981 73.0003 83.1122 72.7789L75.1223 63.2568Z"
      fill="#64A2FF"
    />,
    <path
      d="M81.4703 81.1417C81.1799 80.8513 80.7087 80.851 80.4211 81.1443C80.4125 81.1531 80.4039 81.1619 80.3953 81.1707C80.1079 81.4642 80.1178 81.9353 80.4142 82.2197C80.7105 82.5042 81.1833 82.492 81.4708 82.1985C81.7582 81.9051 81.7608 81.4322 81.4703 81.1417Z"
      fill="#64A2FF"
    />,
    <path
      d="M68.9304 68.9304C68.6927 68.6927 68.3072 68.6925 68.071 68.9317C67.8348 69.1709 67.8398 69.5564 68.0804 69.7911L76.9796 78.4694C77.1866 78.6713 77.5179 78.667 77.721 78.4613C77.9242 78.2556 77.9243 77.9242 77.7199 77.7198L68.9304 68.9304Z"
      fill="#64A2FF"
    />,
    <path
      d="M76.8133 86.1942C76.4987 85.9301 76.0292 85.971 75.7684 86.2882C75.7605 86.2977 75.7527 86.3072 75.7449 86.3167C75.4842 86.6342 75.5352 87.1026 75.8552 87.3602C76.1752 87.6177 76.6451 87.5643 76.9059 87.2469C77.1667 86.9296 77.128 86.4582 76.8133 86.1942Z"
      fill="#64A2FF"
    />,
    <path
      d="M63.2569 75.1221C62.9993 74.906 62.6153 74.9395 62.4008 75.1984C62.1863 75.4572 62.2249 75.8408 62.4851 76.0537L72.1068 83.9233C72.3306 84.1064 72.6603 84.0732 72.8447 83.8506C73.0291 83.628 73.0004 83.2979 72.7789 83.1121L63.2569 75.1221Z"
      fill="#64A2FF"
    />,
    <path
      d="M72.6143 91.6333C72.2779 91.3977 71.8138 91.4792 71.5815 91.8181C71.5746 91.8282 71.5676 91.8383 71.5607 91.8485C71.3286 92.1874 71.4202 92.6497 71.7614 92.8783C72.1027 93.107 72.5662 93.0128 72.7983 92.674C73.0304 92.3351 72.9508 91.8689 72.6143 91.6333Z"
      fill="#64A2FF"
    />,
    <path
      d="M58.1444 81.785C57.869 81.5922 57.4893 81.659 57.2983 81.9356C57.1072 82.2121 57.179 82.5909 57.4568 82.7803L67.7278 89.7814C67.9667 89.9442 68.2922 89.8825 68.4565 89.6446C68.6208 89.4068 68.5634 89.0805 68.3266 88.9146L58.1444 81.785Z"
      fill="#64A2FF"
    />,
    <path
      d="M68.9055 97.4173C68.5498 97.2119 68.0946 97.3336 67.8928 97.6914C67.8867 97.7021 67.8807 97.7128 67.8746 97.7235C67.673 98.0814 67.8045 98.5339 68.1644 98.7319C68.5243 98.93 68.9778 98.7958 69.1795 98.438C69.3812 98.0801 69.2613 97.6227 68.9055 97.4173Z"
      fill="#64A2FF"
    />,
    <path
      d="M53.6323 88.8675C53.3411 88.6994 52.9687 88.7991 52.8025 89.0912C52.6362 89.3834 52.7408 89.7545 53.034 89.9189L63.8761 95.9982C64.1283 96.1396 64.4472 96.0497 64.5901 95.7985C64.7331 95.5472 64.6475 95.2271 64.3971 95.0826L53.6323 88.8675Z"
      fill="#64A2FF"
    />,
    <path
      d="M65.7146 103.503C65.3423 103.329 64.8994 103.49 64.7296 103.864C64.7245 103.875 64.7194 103.887 64.7143 103.898C64.5446 104.272 64.7151 104.711 65.0908 104.877C65.4666 105.043 65.9067 104.87 66.0765 104.496C66.2462 104.122 66.0869 103.676 65.7146 103.503Z"
      fill="#64A2FF"
    />,
    <path
      d="M49.7543 96.3167C49.4496 96.1747 49.0873 96.3064 48.9472 96.6119C48.807 96.9175 48.9435 97.278 49.25 97.4163L60.5807 102.527C60.8442 102.646 61.154 102.529 61.2746 102.266C61.3951 102.003 61.2819 101.692 61.0199 101.57L49.7543 96.3167Z"
      fill="#64A2FF"
    />,
    <path
      d="M63.0665 109.843C62.6805 109.703 62.2534 109.902 62.1167 110.289C62.1126 110.3 62.1085 110.312 62.1045 110.324C61.968 110.711 62.1761 111.134 62.5649 111.266C62.9537 111.399 63.377 111.188 63.5136 110.801C63.6501 110.413 63.4525 109.984 63.0665 109.843Z"
      fill="#64A2FF"
    />,
    <path
      d="M46.5407 104.075C46.2248 103.961 45.8753 104.123 45.7623 104.44C45.6493 104.757 45.8168 105.104 46.1341 105.215L57.8671 109.319C58.14 109.415 58.4385 109.271 58.5356 108.998C58.6328 108.726 58.4929 108.426 58.2212 108.327L46.5407 104.075Z"
      fill="#64A2FF"
    />,
    <path
      d="M60.9809 116.39C60.5841 116.284 60.1759 116.519 60.0736 116.917C60.0705 116.929 60.0674 116.941 60.0644 116.953C59.9622 117.351 60.2064 117.754 60.6053 117.852C61.0041 117.95 61.4074 117.703 61.5097 117.305C61.6119 116.907 61.3777 116.496 60.9809 116.39Z"
      fill="#64A2FF"
    />,
    <path
      d="M44.0153 112.085C43.6905 111.998 43.3566 112.19 43.2716 112.516C43.1867 112.841 43.3837 113.172 43.7095 113.255L55.7556 116.321C56.0358 116.392 56.3205 116.223 56.3936 115.943C56.4667 115.664 56.3011 115.377 56.0219 115.302L44.0153 112.085Z"
      fill="#64A2FF"
    />,
    <path
      d="M59.4738 123.094C59.0692 123.023 58.6831 123.293 58.6158 123.698C58.6138 123.71 58.6118 123.722 58.6098 123.735C58.5427 124.14 58.821 124.52 59.2269 124.583C59.6329 124.646 60.0131 124.365 60.0803 123.96C60.1475 123.554 59.8783 123.165 59.4738 123.094Z"
      fill="#64A2FF"
    />,
    <path
      d="M42.1975 120.284C41.8665 120.225 41.5506 120.446 41.4943 120.778C41.438 121.109 41.6632 121.422 41.995 121.476L54.2625 123.481C54.5478 123.528 54.8167 123.334 54.8651 123.049C54.9135 122.764 54.7236 122.493 54.4389 122.442L42.1975 120.284Z"
      fill="#64A2FF"
    />,
    <path
      d="M58.5569 129.904C58.1477 129.868 57.7866 130.171 57.7549 130.58C57.7539 130.593 57.753 130.605 57.752 130.617C57.7205 131.027 58.0309 131.381 58.4408 131.409C58.8506 131.436 59.2049 131.123 59.2366 130.713C59.2682 130.304 58.9661 129.94 58.5569 129.904Z"
      fill="#64A2FF"
    />,
    <path
      d="M41.1014 128.61C40.7665 128.581 40.4711 128.828 40.4439 129.163C40.4167 129.499 40.6683 129.791 41.0035 129.816L53.399 130.743C53.6873 130.765 53.9384 130.549 53.9618 130.261C53.9851 129.972 53.7723 129.719 53.4843 129.693L41.1014 128.61Z"
      fill="#64A2FF"
    />,
    <path
      d="M58.2369 136.768C57.8261 136.768 57.4927 137.101 57.4969 137.511C57.497 137.524 57.4971 137.536 57.4972 137.548C57.5015 137.959 57.8417 138.285 58.2524 138.277C58.6631 138.268 58.9887 137.925 58.9845 137.515C58.9803 137.104 58.6477 136.768 58.2369 136.768Z"
      fill="#64A2FF"
    />,
    <path
      d="M40.735 137C40.3989 137 40.1262 137.273 40.1283 137.609C40.1304 137.945 40.4065 138.214 40.7426 138.21L53.1718 138.053C53.4609 138.05 53.6921 137.813 53.6903 137.523C53.6885 137.234 53.4543 137 53.1652 137L40.735 137Z"
      fill="#64A2FF"
    />,
  ]
  const percentValue = Math.floor(percent * gaugeArray.length)

  return (
    <>
      <svg width="274" height="214" viewBox="0 0 274 214" fill="none" xmlns="http://www.w3.org/2000/svg">
        {gaugeArray.reverse().map((path, index) => {
          return {
            ...path,
            props: { d: path.props.d, fill: index < percentValue ? "#64A2FF" : "#C4C4C4" },
          }
        })}
        <line y1="145" x1="20" x2="250" y2="145" stroke="#564CFF" strokeWidth="2" />
      </svg>
    </>
  )
}

export default Gauge
