import * as React from "react"
import ChartWithSelect from "../Chart/ChartWithSelect"
import { AI_PLOT_TYPE_TITLE, PlotType } from "../../constant"
import { useEffect, useState } from "react"
import _ from "lodash"
import { Button } from "../../component/Button"
import { AddIcon } from "../../assets/images/addIcon"
import { RemoveIcon } from "../../assets/images/removeIcon"

interface IRXLProps {}

const RXL: React.FunctionComponent<IRXLProps> = (props) => {
  const rxlSelectOption = [
    {
      id: PlotType.rul,
      value: PlotType.rul,
      label: AI_PLOT_TYPE_TITLE[PlotType.rul],
      level: { node: { activate: true, noneValue: false }, stack: { activate: true, noneValue: false } },
      node: { activate: true, noneValue: false },
      stack: { activate: true, noneValue: false },
    },
    {
      id: PlotType.rsl,
      value: PlotType.rsl,
      label: AI_PLOT_TYPE_TITLE[PlotType.rsl],
      level: { node: { activate: true, noneValue: false }, stack: { activate: true, noneValue: false } },
      node: { activate: true, noneValue: false },
      stack: { activate: true, noneValue: false },
    },
  ]
  const [chartCount, setChartCount] = useState<number>(0)
  const [defaultSelect, setDefaultSelect] = useState<number[]>([20, 21])
  useEffect(() => {
    setChartCount(2)
  }, [])
  return (
    <div className="page-section-content-wrapper">
      <div className="operation-page-button-wrapper">
        <Button
          onClick={() => {
            setChartCount(chartCount + 1)
            setDefaultSelect([])
          }}
          label="Add Chart"
          style={{
            width: "145px",
            backgroundColor: "transparent",
            border: "1px solid rgba(25, 118, 210, 0.5)",
            color: "rgb(25, 118, 210)",
            boxShadow: "none",
          }}
          startIcon={<AddIcon fill="rgb(25, 118, 210)" />}
        ></Button>
        <Button
          disabled={chartCount < 2}
          onClick={() => {
            if (chartCount > 1) {
              setChartCount(chartCount - 1)
              setDefaultSelect([])
            }
          }}
          label="Remove Chart"
          startIcon={<RemoveIcon fill="rgb(25, 118, 210)" />}
          style={{
            width: "165px",
            backgroundColor: "transparent",
            border: chartCount < 2 ? "1px solid rgba(25, 118, 210, 0.2)" : "1px solid rgba(25, 118, 210, 0.5)",
            color: chartCount < 2 ? "rgba(25, 118, 210, 0.5)" : "rgb(25, 118, 210)",
            boxShadow: "none",
          }}
        ></Button>
      </div>
      {_.times(chartCount, (item) => {
        return (
          <ChartWithSelect
            key={item}
            selectOption={rxlSelectOption}
            type={item < 2 && defaultSelect.length > 0 ? defaultSelect[item] : PlotType.rsl}
          />
        )
      })}
    </div>
  )
}

export default RXL
