export const EELogo = ({ fill, width }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width || "432px"}
      height="106.5px"
      viewBox="0 0 432 106.5"
    >
      <g>
        <g>
          <path
            className="st0"
            d="M146.7,57.5h-5.9c-0.9,0-1.2-0.2-1.2-1.2v-39c0-0.9,0.2-1.2,1.2-1.2h5.9V57.5z M147.6,16.1h19.6
			c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2h-19.6V16.1z M147.6,33.6h18.7c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2
			h-18.7V33.6z M147.6,51.3h19.6c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2h-19.6V51.3z"
            fill={fill}
          />
          <path
            className="st0"
            d="M186.2,57.5h-5.9c-0.9,0-1.2-0.2-1.2-1.2v-39c0-0.9,0.2-1.2,1.2-1.2h4.7c0.9,0,1.2,0.2,1.2,1.2V57.5z
			 M187.1,51.3h17.3c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2h-17.3V51.3z"
            fill={fill}
          />
          <path
            className="st0"
            d="M221.7,57.5h-5.9c-0.9,0-1.2-0.2-1.2-1.2v-39c0-0.9,0.2-1.2,1.2-1.2h5.9V57.5z M222.6,16.1h19.6
			c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2h-19.6V16.1z M222.6,33.6h18.7c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2
			h-18.7V33.6z M222.6,51.3h19.6c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2h-19.6V51.3z"
            fill={fill}
          />
          <path
            className="st0"
            d="M261.9,34.8l-2.4,21.3c0,0.9-0.2,1.2-1.2,1.2h-4.7c-0.9,0-1.4-0.2-1.2-1.2l3.8-31L261.9,34.8z M257.2,15.4
			h0.9c0.2,0,0.5,0.2,0.7,0.5L278,47.8l19.2-31.9c0.2-0.5,0.5-0.5,0.7-0.5h0.7l1.2,8.3L279.6,57c-0.5,0.7-0.9,1.2-1.4,1.2h-0.7
			c-0.5,0-0.9-0.5-1.2-1.2l-20.1-33.4L257.2,15.4z M300,25.3l3.8,31c0,0.9-0.2,1.2-1.2,1.2h-4.7c-0.9,0-1.2-0.2-1.2-1.2L294.3,35
			L300,25.3z"
            fill={fill}
          />
          <path
            className="st0"
            d="M321,57.5h-5.9c-0.9,0-1.2-0.2-1.2-1.2v-39c0-0.9,0.2-1.2,1.2-1.2h5.9V57.5z M322,16.1h19.6
			c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2H322V16.1z M322,33.6h18.7c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2H322
			V33.6z M322,51.3h19.6c0.9,0,1.2,0.2,1.2,1.2v3.5c0,0.9-0.2,1.2-1.2,1.2H322V51.3z"
            fill={fill}
          />
          <path
            className="st0"
            d="M388.9,57.7c0,0.5,0,0.5-0.5,0.5H388c-0.5,0-0.7-0.2-0.9-0.5l-33.4-34.8v-7.1c0-0.5,0-0.5,0.5-0.5h0.5
			c0.2,0,0.7,0.2,0.9,0.5l33.4,34.3V57.7z M360.6,31.9v24.4c0,0.9-0.2,1.2-1.2,1.2h-4.5c-0.9,0-1.2-0.2-1.2-1.2V24.4L360.6,31.9z
			 M381.8,41.4V17.3c0-0.9,0.2-1.2,1.2-1.2h4.7c0.9,0,1.2,0.2,1.2,1.2v31.5L381.8,41.4z"
            fill={fill}
          />
          <path
            className="st0"
            d="M417.8,56.3c0,0.9-0.2,1.2-1.2,1.2h-4.5c-0.9,0-1.2-0.2-1.2-1.2V22.5h-13c-0.9,0-1.2-0.2-1.2-1.2v-4
			c0-0.9,0.2-1.2,1.2-1.2H418v40.2H417.8z M419,16.1h11.8c0.9,0,1.2,0.2,1.2,1.2v3.8c0,0.9-0.2,1.2-1.2,1.2H419V16.1z"
            fill={fill}
          />
        </g>
        <rect x="33.4" y="26" className="st1" width="38.8" height="11.1" fill="#1C7138" />
        <g>
          <path className="st0" d="M139.6,92V68.4h15.6V71h-12.8v7.6H155v2.6h-12.5v8h12.8v2.6h-15.6V92z" fill={fill} />
          <path className="st0" d="M208.9,92l-14-18.9V92h-3.1V68.4h3.1l13.7,18.5V68.4h3.1V92H208.9z" fill={fill} />
          <path className="st0" d="M249.1,92V68.4h15.6V71H252v7.6h12.5v2.6H252v8h12.8v2.6h-15.6V92z" fill={fill} />
          <path
            className="st0"
            d="M315.4,92l-6.2-9.5h-4.7V92h-3.1V68.4h9.7c4.5,0,7.6,2.8,7.6,7.1c0,4.3-3.1,6.6-6.2,6.9l6.4,9.7H315.4z
			 M315.6,75.5c0-2.6-1.9-4.5-4.7-4.5h-6.4v9h6.4C313.7,80,315.6,78.1,315.6,75.5z"
            fill={fill}
          />
          <path
            className="st0"
            d="M354.4,80.2c0-7.3,5.4-12.3,12.3-12.3c4.3,0,7.3,1.9,9.2,4.5l-2.4,1.4c-1.4-1.9-4-3.3-6.9-3.3
			c-5.2,0-9.2,4-9.2,9.7c0,5.7,4,9.7,9.2,9.7c2.8,0,5.2-1.4,6.4-2.6v-4.7h-8.3V80h11.1v8.5c-2.1,2.4-5.4,4-9.5,4
			C359.8,92.5,354.4,87.5,354.4,80.2z"
            fill={fill}
          />
          <path
            className="st0"
            d="M419.7,92v-9.9l-9.5-13.7h3.5l7.3,11.1l7.3-11.1h3.5l-9.5,13.7V92H419.7z"
            fill={fill}
          />
        </g>
        <rect x="33.4" y="47.3" className="st2" width="38.8" height="11.1" fill="#48B848" />
        <rect x="33.4" y="68.8" className="st3" width="38.8" height="11.1" fill="#F3A538" />
        <path
          className="st1"
          d="M53,3.1c22,0,40.9,14.4,47.6,34.3h3.1C97,15.6,76.9,0,53,0S9,15.6,2.4,37.1h3.1C12.1,17.3,31,3.1,53,3.1z"
          fill="#1C7138"
        />
        <path
          className="st2"
          d="M103.4,53.2c0,1.9,0,3.5-0.2,5.4h3.1c0.2-1.7,0.2-3.5,0.2-5.4c0-1.9-0.2-3.8-0.2-5.9h-3.1
		C103.2,49.2,103.4,51.3,103.4,53.2z"
          fill="#48B848"
        />
        <path
          className="st2"
          d="M2.8,53.2c0-1.9,0.2-3.8,0.5-5.9H0.2C0,49.2,0,51.1,0,53.2c0,1.9,0,3.5,0.2,5.4h3.1
		C3.1,56.8,2.8,55.1,2.8,53.2z"
          fill="#48B848"
        />
        <path
          className="st3"
          d="M53,103.4c-22.2,0-41.2-14.7-47.8-34.8H2.1c6.6,22,27,37.9,50.9,37.9s44.2-15.9,50.9-37.6h-3.1
		C94.2,89,75.5,103.4,53,103.4z"
          fill="#F3A538"
        />
      </g>
    </svg>
  )
}
