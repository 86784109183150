import * as React from "react"
import "./plant.scss"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import {
  PLANT_AC_STATUS,
  PLANT_AC_STATUS_KEY,
  PLANT_AC_STATUS_LEGEND,
  PLANT_STACK_STATUS_LEGEND,
  PlotType,
} from "../../constant"
import { useAppContext } from "../../context/AppContext"
import { dataTimelineConvert } from "../../common/util"
import { useNavigate } from "react-router-dom"
import URLs from "../../routes/urls"
import _ from "lodash"
import { EEBasicDialog } from "../../component/dialogs/EEBasicDialogs"
import Gauge from "../../assets/soc"
import ToogleButton from "../../component/ToggleButton"
import { StackBar } from "../../assets/stackBar"
import Container2 from "../../component/Container2"
import EELoading from "../../component/Loading"
import { EETooltip } from "../../component/charts/EETooltip"
import PlantDialogContent from "../../component/PlantDialogContent"
import InverterIcon from "../../assets/inverterIcon"
import WeatherSection from "../../component/WeatherSection"
import { ExpandIcon } from "../../assets/ExpandIcon"
import EETimelineChart from "../../component/charts/EETimelineChart"
import dayjs from "dayjs"
import { chartColors } from "../../common/chartColors"
import EEToggle from "../../component/EEToggle"
interface IPlantProps {}

const Plant: React.FunctionComponent<IPlantProps> = (props) => {
  const percent = 0.73 //temporary value
  const socValue = 525 //temporary value
  const socData = 83 // will be replaced with actual data
  const energyThroughput = 73.3 // will be replaced with actual data
  const runningValue = 21 // will be replaced with actual data

  const appContext = useAppContext()
  const { getPlantPageData, getEnergyThroughput, isDarkMode, postOperationChartData, getLandingPageMetrics } =
    appContext
  const authContext = useAuthContext()
  const { userRole } = authContext
  // const [state, setState] = useState<MainState>()
  const [alarmList, setAlarmList] = useState<any>()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const [updateData, setUpdateData] = useState()
  // const [currentData, setCurrentData] = useState<any>()
  const [leftPoint, setLeftPoint] = useState([0, 0])
  const [rightPoint, setRightPoint] = useState([0, 0])
  const [noData, setNodata] = useState<boolean>(false)
  const [unit, setUnit] = useState(["%", "Ah"])
  const [errorMessage, setErrorMessage] = useState("")
  const [interactionDataET, setInteractionDataET] = useState<any>(null)
  const [interactionDataAlarm, setInteractionDataAlarm] = useState<any>(null)
  const [toggleState, setToggleState] = useState<boolean>(false)
  const [containerImageData, setContainerImageData] = useState<any>([])
  const [containerZoomDialogOpen, setContainerZoomDialogOpen] = useState<boolean>(false)
  const [currentData, setCurrentData] = useState<any>()
  const [yDomain, setYDomain] = useState([0, 100])
  const [maxPoint, setMaxPoint] = useState(0)
  const [minPoint, setMinPoint] = useState(0)
  const [newLegend, setNewLegend] = useState<any>()
  const [chartLoading, setChartLoading] = useState<boolean>(false)
  const [energyThroughputData, setEnergyThroughputData] = useState<any>()
  const [metricsData, setMetricData] = useState<any>()
  const [selectedPeriod, setSelectedPeriod] = useState<number>(1)
  // const handleRequest = async (paramData) => {
  //   const response = await createRequest(paramData)
  //   if (response.statusCode === 200) {
  //     getData()
  //   }
  // }
  const getMetrics = async () => {
    const metricsResponse = await getLandingPageMetrics()
    if (metricsResponse && metricsResponse.soc) {
      setMetricData(metricsResponse)
    }
  }
  const createStatusData = (statusData) => {
    if (statusData) setNodata(false)
    setErrorMessage("")
    setMaxPoint(statusData.maxPoint)
    setMinPoint(statusData.minPoint)
    setUnit(statusData.unit)
    const keyArray = Object.keys(statusData.plotData).filter(function (letter) {
      return letter !== "time"
    })

    const newarray = keyArray.slice().reverse()
    setYDomain(newarray)
    // console.log("after revers", keyArray)

    if (statusData.plotData && keyArray.length > 0) {
      const timeValue = statusData.plotData.time
      if (timeValue.length < 1) {
        setNodata(true)
        return
      }
      const newDataValue = _.pick(statusData.plotData, keyArray)
      const chartData: any = []
      _.times(keyArray.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = keyArray[item]
        dataNew.values = dataTimelineConvert({ time: timeValue, value: newDataValue[keyArray[item]] || [] })
        dataNew.translate = item * 17 + 10
        chartData.push(dataNew)
      })

      setCurrentData(chartData)
      setUpdateData(chartData)
      // console.log("data exist???", chartData)
      setNodata(false)
      setChartLoading(false)
    } else {
      setNodata(true)
      setChartLoading(false)
    }
  }
  const createData = async () => {
    await getMetrics()
    const response = await getPlantPageData()

    if (response.statusCode === 200) {
      setContainerImageData(response.data)
    }
  }
  const createEnergyData = async () => {
    const energyThroughput = await getEnergyThroughput()
    if (energyThroughput.statusCode === 200) {
      setEnergyThroughputData(energyThroughput.data.plotData)
    }
  }
  const createChartData = async (period) => {
    setChartLoading(true)
    const data = await postOperationChartData({
      plotType: PlotType.status,
      plotCategoryId: PlotType.status,

      start: dayjs(dayjs(`${dayjs().format("YYYY-MM-DD")}`))
        .subtract(period, "day")
        .valueOf(),
      end: dayjs().valueOf(),
    })
    if (data.statusCode === 500 || data.statusCode === 400) {
      setErrorMessage("Internal Server Error")
      setCurrentData(null)
      setUpdateData(null)
      setNewLegend(null)
      setChartLoading(false)
      return null
    }
    createStatusData(data)
  }

  useEffect(() => {
    createData()
    createChartData(1)
    createEnergyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    createChartData(selectedPeriod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod])

  const getSocValue = () => {
    if (metricsData) {
      const socValue = metricsData?.soc?.percent * 100
      return +socValue.toFixed(2) % 1 === 0 ? socValue.toFixed() : socValue.toFixed(2)
    } else {
      return ""
    }
  }
  const handleContainerClick = (container) => {
    navigate(URLs.System, { state: { container } })
  }
  return (
    <div className="plant-wrapper">
      <EETooltip interactionData={interactionDataET} id="energy-throughput-tooltip" />
      {/* <EETooltip interactionData={interactionDataAlarm} isMulti id="alarm-tooltip" /> */}

      <div className="plant-section-wrapper">
        <WeatherSection />
        <div className="avg-soc-wrapper">
          <div className="plant-section-title">Plant SoC</div>
          <div className="plant-gauge-text" id="soc-data">
            {metricsData ? `${getSocValue()}%` : ""}
          </div>
          <div className="plant-gauge-wrapper">
            <Gauge percent={metricsData ? metricsData?.soc?.percent : 0} />
          </div>
          <div className="plant-sub-unit-text-wrapper">
            <div className="plant-gauge-sub-text" id="soc-value">
              {metricsData ? metricsData?.soc?.value : ""}
            </div>
            {/* <div className="plant-gauge-unit-text">kWH</div> */}
          </div>
        </div>
        <div className="energy-throughput">
          <div>
            <ToogleButton onClick={(value) => setToggleState(value)} isOn={toggleState} />
          </div>
          <div className="energy-throughput-title">Energy Throughtput</div>
          <div className="energy-throughput-value-unit-wrapper">
            <div className="energy-throughput-value" id="energy-throughput">
              {energyThroughputData
                ? toggleState
                  ? energyThroughputData.chargeEnergy[6]
                  : energyThroughputData.dischargeEnergy[6]
                : ""}

              <span style={{ fontSize: "22px", color: "var(--energy-throughput-title)", fontWeight: 600 }}>
                {energyThroughputData
                  ? toggleState
                    ? energyThroughputData.chargeEnergyUnit[6]
                    : energyThroughputData.dischargeEnergyUnit[6]
                  : ""}
              </span>
            </div>
            <div className="energy-throughput-unit">Latest</div>
          </div>
          <div className="energy-throughput-chart-wrapper">
            {energyThroughputData
              ? energyThroughputData.date.map((item, index) => {
                  return (
                    <div key={index}>
                      <StackBar
                        date={item}
                        height={
                          toggleState
                            ? energyThroughputData.chargeEnergyPercent[index] * 100
                            : energyThroughputData.dischargeEnergyPercent[index] * 100
                        }
                        fill="#564cff"
                        onMouseOver={(e) => {
                          setInteractionDataET({
                            xPos: e.pageX - 30,
                            yPos: e.pageY - 80,
                            color: "rgb(94, 246, 159)",
                            x: item,
                            y: `${
                              toggleState
                                ? energyThroughputData.chargeEnergy[index] +
                                  energyThroughputData.chargeEnergyUnit[index]
                                : energyThroughputData.dischargeEnergy[index] +
                                  energyThroughputData.dischargeEnergyUnit[index]
                            } `,
                            name: "",
                          })
                        }}
                        id={`energy-throughput-chart-${index}`}
                        onMouseLeave={() => setInteractionDataET(null)}
                      />
                    </div>
                  )
                })
              : null}
          </div>
        </div>
        <div className="running-info">
          <span className="plant-running-info-title">
            Inertia <span className="plant-running-info-subtitle">has been running for</span>
          </span>
          <span className="plant-running-info-value" id="running-days">
            {metricsData ? metricsData?.daysOfOperation : 0}
            <span className="plant-running-info-unit" style={{ color: "#5EF69F" }}>
              Days
            </span>
          </span>
          <div className="plant-running-divider"></div>
          <span className="plant-running-info-value" id="running-cycle">
            {metricsData ? metricsData.cycleCount : 0}
            <span className="plant-running-info-unit" style={{ color: "#FFD762" }}>
              Cycles
            </span>
          </span>
        </div>
      </div>
      <div className="plant-section-wrapper">
        <div className="plant-container-wrapper">
          <div className="plant-container-top-wrapper">
            <div className="plant-zoom-wrapper">
              <ExpandIcon
                onClick={() => setContainerZoomDialogOpen(true)}
                width="20"
                fill="var(--plant-container-text)"
              />
              <div className="plant-container-title">Inertia</div>
            </div>
            <div className="plant-container-info-wrapper">
              <div className="plant-container-info">
                <div className="plant-container-info-title">
                  <span> Engaged</span> Container
                </div>
                <div className="plant-container-info-value" id="engaged-container">
                  23 <span>/ 23</span>
                </div>
              </div>
              <div className="plant-container-info">
                <div className="plant-container-info-title">
                  <span> Engaged</span> Stack
                </div>
                <div className="plant-container-info-value" id="engaged-container">
                  253 <span>/ 276</span>
                </div>
              </div>
              <div className="plant-container-info">
                <div className="plant-container-info-title">
                  <span> Engaged</span> Node
                </div>
                <div className="plant-container-info-value" id="engaged-container">
                  7607<span>/ 8832</span>
                </div>
              </div>
            </div>
          </div>

          {containerImageData.length > 0 ? (
            <div className="plant-containers-wrapper">
              <div className="plant-containers-section">
                <div className="plant-containers-section-title">
                  <InverterIcon title="Inv 01" />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 01-A</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index < 3) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable ||
                                item.acStatus === null
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 01-B</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 2 && index < 6) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="plant-containers-section">
                <div className="plant-containers-section-title">
                  <InverterIcon title="Inv 02" />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 02-A</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 5 && index < 9) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 02-B</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 8 && index < 12) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="plant-containers-section">
                <div className="plant-containers-section-title">
                  <InverterIcon title="Inv 03" />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 03-A</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 11 && index < 15) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 03-B</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 14 && index < 18) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="plant-containers-section">
                <div className="plant-containers-section-title">
                  <InverterIcon title="Inv 04" />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 04-A</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 17 && index < 21) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                  <div style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className="plant-container-inverter-wrapper">
                      <span>Inv 04-B</span>
                    </div>
                    {containerImageData.map((item, index) => {
                      if (index > 20) {
                        return (
                          <Container2
                            id={`main-container-${index}`}
                            key={index}
                            status={item}
                            onClick={() => {
                              if (
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.numContexts ||
                                PLANT_AC_STATUS[item.acStatus] === PLANT_AC_STATUS_KEY.rebuildBankTable
                              ) {
                                return
                              }

                              handleContainerClick({ value: item.id, label: item.displayName })
                            }}
                            isDarkMode={isDarkMode}
                          />
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EELoading />
          )}
          <div className="plant-container-legend-wrapper">
            <span className="plant-legend-title">Container</span>
            {PLANT_AC_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <div
                  style={{
                    color: item.color,
                    border: `2px solid ${item.color}`,
                    padding: "4px",
                    fontSize: "11px",
                    // marginRight: "4px",
                    backgroundColor: "#ffffff30",
                  }}
                ></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
          <div className="plant-container-legend-wrapper">
            <span className="plant-legend-title">Stack</span>
            {PLANT_STACK_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="plant-chart-wrapper" style={{ paddingTop: "18px" }}>
          <div style={{ textAlign: "center", color: "var(--tooltip-text)", marginBottom: "10px" }}>
            Inertia Status History
          </div>
          <div
            style={{
              // width: "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <ToogleButton
              onClick={(value) => {
                setSelectedPeriod(value)
              }}
              toggleData={[
                { label: "Last 24Hours", value: 1 },
                { label: "Last 7Days", value: 7 },
                { label: "Last 30Days", value: 30 },
              ]}
              selectedToggle={selectedPeriod}
              isOn={false}
            />
          </div>
          <div
            className="chart-legend-wrapper"
            style={{
              width: "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // marginTop: "20px",
              gap: "16px",
              marginBottom: "-20px",
            }}
          >
            {PLANT_AC_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
          <div style={{ width: "100%" }}>
            {updateData && updateData.length > 0 && !chartLoading ? (
              <EETimelineChart data={updateData} yDomain={yDomain} level={0} period={selectedPeriod} />
            ) : // <EEPerformanceChart
            //   data={updateData}
            //   handleBrushed={function (timeRange: any): boolean {
            //     // console.log("brushed")
            //     return true
            //   }}
            //   leftYAxisScale={leftPoint}
            //   rightYAxisScale={rightPoint}
            //   isBrush={false}
            //   divId={1}
            //   unit={unit}
            //   isLinear={true}
            //   customHeight={400}
            // />
            errorMessage ? (
              <div className="chart-no-data">{errorMessage}</div>
            ) : chartLoading ? (
              <div style={{ height: "400px", display: "flex", alignItems: "center" }}>
                <EELoading />
              </div>
            ) : (
              <div className="chart-no-data">No Data</div>
            )}
          </div>
          <div className="ee-legend-container"></div>
        </div>
      </div>
      {/* <div className="plant-section-wrapper">
        <div className="plant-alarm-chart-wrapper">
          <div className="plant-alarm-chart-title-wrapper">
            <div className="plant-alarm-chart-title">
              <AlarmIcon style={{ width: "18px", marginRight: "8px", marginLeft: "16px" }} width="18" />
              SoX Alarm
            </div>
            <div className="plant-alarm-legend-wrapper">
              <div className="plant-alarm-legend" style={{ backgroundColor: "#FFA800" }}></div>{" "}
              <div className="plant-alarm-legend-text">Warning</div>
              <div className="plant-alarm-legend" style={{ backgroundColor: "#FC554F" }}></div>{" "}
              <div className="plant-alarm-legend-text">Alert</div>
            </div>
          </div>
          <div className="plant-alarm-value" id="alarm-count">
            {alarmList ? alarmList.length : 0}
            <span>Today</span>
          </div>
          <div className="energy-throughput-chart-wrapper">
            {PlantAlarmData.map((item, index) => {
              return (
                <div key={index}>
                  <StackBar
                    date={item.date}
                    height={item.warning * 10}
                    fill="#FFA800"
                    isMulti
                    height2={(item.alert + item.critical) * 10}
                    fill2="#FC554F"
                    id={`alarm-chart-${index}`}
                    onMouseOver={(e) => {
                      setInteractionDataAlarm([
                        {
                          xPos: e.pageX - 250,
                          yPos: e.pageY - 80,
                          color: "#FFA800",
                          x: item.date,
                          y: `Warning : ${item.warning}`,
                          name: "",
                        },
                        {
                          xPos: e.pageX - 30,
                          yPos: e.pageY - 80,
                          color: "#FC554F",
                          x: item.date,
                          y: `Critical Alert : ${item.critical},  Alert : ${item.alert}`,
                          name: "",
                        },
                      ])
                    }}
                    onMouseLeave={() => setInteractionDataAlarm(null)}
                  />
                </div>
              )
            })}
          </div>
        </div>
     
      </div> */}
      <EEBasicDialog
        open={alertOpen}
        title={<div>New {alarmList ? alarmList.length : 0} Alerts Recieved</div>}
        onClose={function (): void {
          setAlertOpen(false)
        }}
      >
        {/* <AlarmDialog data={alarmList} request={handleRequest} /> */}
      </EEBasicDialog>
      <EEBasicDialog
        open={containerZoomDialogOpen}
        title={"Inertia 23 Containers"}
        onClose={function (): void {
          setContainerZoomDialogOpen(false)
        }}
      >
        <PlantDialogContent data={containerImageData} />
      </EEBasicDialog>
    </div>
  )
}

export default Plant
