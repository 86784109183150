// import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"

import exp from "constants"
import Plant from "./container/OperateAndManager/Plant"

//swagger token account info
// const UserId = "user"
// const userPW = "element23!"

export const sampleTabData = [
  {
    label: "CAM",
    content: {
      title: "Container Aggregate Metrics",
      content: [
        { label: "Discharge Throughput", value: "1.61MWh" },
        { label: "Exchanged Capacity", value: "1.70kAh" },
        { label: "Averaged State of Charge", value: "32.2%" },
        { label: "Average Temperature", value: "18.9°C" },
        { label: "No. of Cycles Completed", value: "5" },
        { label: "Days in Operation", value: "166" },
      ],
    },
  },
  {
    label: "SP",
    content: {
      title: "Selected Period",
      content: [
        { label: "Discharge Throughput", value: "1.61MWh" },
        { label: "Exchanged Capacity", value: "1.70kAh" },
        { label: "Averaged State of Charge", value: "32.2%" },
        { label: "Average Temperature", value: "18.9°C" },
        { label: "No. of Cycles Completed", value: "5" },
        { label: "Days in Operation", value: "166" },
      ],
    },
  },
  {
    label: "LV",
    content: {
      title: "Live Value",
      content: [
        { label: "System Volted", value: "263v" },
        { label: "Net System Current", value: "-3.91" },
        { label: "Net System Power", value: "1.03kW" },
      ],
    },
  },
]
export const sampleAlertData = [
  {
    id: 1,
    title: "Warning",
    content: `Container 1 Stack 1 Node 5-R Cell 1`,
    date: "2Hrs ago",
    containerId: "2",
    nodeId: "3-R",
    stackId: "4",
    age: 14,
    cellId: 1,
    cycleNo: 5,
    status: 1,
  },
  {
    id: 2,
    title: "Alert",
    content: "Container 1 Stack 1 Node 5-R Cell 2",
    date: "2Hrs ago",
    containerId: "2",
    nodeId: "3-R",
    stackId: "4",
    age: 14,
    cellId: 1,
    cycleNo: 5,
    status: 1,
  },
  {
    id: 3,
    title: "Warning",
    content: "Container 1 Stack 1 Node 5-R Cell 3",
    date: "2023-05-23",
    containerId: "2",
    nodeId: "5-R",
    age: 31,
    stackId: "4",
    cellId: 6,
    cycleNo: 5,
    status: 2,
  },
  {
    id: 4,
    title: "Warning",
    content: "Container 2  Stack 4 Node 8-R Cell 5",
    date: "2023-04-30",
    containerId: "2",
    nodeId: "8-R",
    age: 11,
    stackId: "4",
    cellId: 5,
    cycleNo: 5,
    status: 3,
  },
]
export const sampleRequestData = [
  {
    id: 1,
    title: "Bypass",
    content: `Container 1 Stack 1 Node 5-R Cell 1`,
    date: "2Hrs ago",
    containerId: "2",
    nodeId: "3-R",
    stackId: "4",
    age: 14,
    cellId: 1,
    cycleNo: 5,
    status: 1,
  },
  {
    id: 2,
    title: "Detach",
    content: "Container 1 Stack 1 Node 5-R Cell 2",
    date: "2Hrs ago",
    containerId: "2",
    nodeId: "3-R",
    stackId: "4",
    age: 14,
    cellId: 1,
    cycleNo: 5,
    status: 1,
  },
  {
    id: 3,
    title: "Shutdown",
    content: "Container 1 Stack 1 Node 5-R Cell 3",
    date: "2023-05-23",
    containerId: "2",
    nodeId: "5-R",
    age: 31,
    stackId: "4",
    cellId: 6,
    cycleNo: 5,
    status: 2,
  },
  {
    id: 4,
    title: "Bypass",
    content: "Container 2  Stack 4 Node 8-R Cell 5",
    date: "2023-04-30",
    containerId: "2",
    nodeId: "8-R",
    age: 11,
    stackId: "4",
    cellId: 5,
    cycleNo: 5,
    status: 3,
  },
]

export const Period = {
  thirtyMinutes: "30m",
  oneHour: "1h",
  oneDay: "1d",
  sevenDays: "7d",
}
export const PeriodOptionsText = {
  [Period.thirtyMinutes]: "30 Minutes", // [key]: [value
  [Period.oneHour]: "1 Hour",
  [Period.oneDay]: "1 Day",
  [Period.sevenDays]: "7 Days",
  // "4": "1 Month",
}
export const PeriodOptions = [
  { value: Period.thirtyMinutes, label: PeriodOptionsText[Period.thirtyMinutes] },
  { value: Period.oneHour, label: PeriodOptionsText[Period.oneHour] },
  { value: Period.oneDay, label: PeriodOptionsText[Period.oneDay] },
  { value: Period.sevenDays, label: PeriodOptionsText[Period.sevenDays] },
  // { value: "3", label: "1 Month" },
]

export const OPTION_NONE_SELECT_VALUE = "-1"

export const ContainerCount = 23
export const StackCount = 12
export const NodeCount = 32
export const CellCount = 10
export const NodeName = [
  "Node 1L",
  "Node 1R",
  "Node 2L",
  "Node 2R",
  "Node 3L",
  "Node 3R",
  "Node 4L",
  "Node 4R",
  "Node 5L",
  "Node 5R",
  "Node 6L",
  "Node 6R",
  "Node 7L",
  "Node 7R",
  "Node 8L",
  "Node 8R",
  "Node 9L",
  "Node 9R",
  "Node 10L",
  "Node 10R",
  "Node 11L",
  "Node 12R",
  "Node 13L",
  "Node 13R",
  "Node 14L",
  "Node 14R",
  "Node 15L",
  "Node 15R",
  "Node 16L",
  "Node 16R",
]

export const operationStatus = ["CHARGE", "DISCHARGE", "SHUTDOWN", "STANDBY"]
export const statusText = {
  CHARGE: "CHARGE",
  DISCHARGE: "DISCHARGE",
  SHUTDOWN: "SHUTDOWN",
  STANDBY: "STANDBY",
}
export const statusColor = {
  [statusText.CHARGE]: "#47E975",
  [statusText.DISCHARGE]: "#FFD80A",
  [statusText.SHUTDOWN]: "#ADADAD",
  [statusText.STANDBY]: "#1E85FF",
}
export const chartLevel = {
  system: 1,
  container: 2,
  stack: 3,
  node: 4,
}
export const alertMessageStatus = ["Not Viewed", "Viewed", "Request", "Done"]
export const soxAlarmType = ["Alert", "Warning"]
export const requestOperation = ["Bypass", "Detach", "Shutdown"]
// export const alertRowDataSample = [
//   {
//     id: 1,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "3-R",
//     stackId: "4",
//     age: 14,
//     cellId: 1,
//     cycleNo: 5,
//     status: alertMessageStatus[0],
//     type: soxAlarmType[1],
//   },
//   {
//     id: 2,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "5-R",
//     age: 31,
//     stackId: "4",
//     cellId: 6,
//     cycleNo: 5,
//     status: alertMessageStatus[1],
//     type: soxAlarmType[0],
//   },
//   {
//     id: 3,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "2-R",
//     age: 31,
//     stackId: "4",
//     cellId: 2,
//     cycleNo: 5,
//     status: alertMessageStatus[1],
//     type: soxAlarmType[1],
//   },
//   {
//     id: 4,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "8-R",
//     age: 11,
//     stackId: "4",
//     cellId: 5,
//     cycleNo: 5,
//     status: alertMessageStatus[2],
//     type: soxAlarmType[1],
//   },
//   {
//     id: 5,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "9-R",
//     age: null,
//     stackId: "4",
//     cellId: 10,
//     cycleNo: 5,
//     status: alertMessageStatus[2],
//     type: soxAlarmType[1],
//   },
//   {
//     id: 6,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "12-R",
//     age: 150,
//     stackId: "4",
//     cellId: 2,
//     cycleNo: 5,
//     status: alertMessageStatus[2],
//     type: soxAlarmType[1],
//   },
//   {
//     id: 7,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "15-R",
//     age: 44,
//     stackId: "4",
//     cellId: 8,
//     cycleNo: 5,
//     status: alertMessageStatus[3],
//     type: soxAlarmType[0],
//   },
//   {
//     id: 8,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "1-R",
//     age: 36,
//     stackId: "4",
//     cellId: 1,
//     cycleNo: 5,
//     status: alertMessageStatus[3],
//     type: soxAlarmType[1],
//   },
//   {
//     id: 9,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "4-R",
//     age: 65,
//     stackId: "4",
//     cellId: 1,
//     cycleNo: 5,
//     status: alertMessageStatus[3],
//     type: soxAlarmType[1],
//   },
// ]
// export const requestRowDataSample = [
//   {
//     id: 1,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "3-R",
//     stackId: "4",
//     age: 14,
//     cellId: 1,
//     cycleNo: 5,
//     status: alertMessageStatus[0],
//     type: requestOperation[1],
//   },
//   {
//     id: 2,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "5-R",
//     age: 31,
//     stackId: "4",
//     cellId: 6,
//     cycleNo: 5,
//     status: alertMessageStatus[1],
//     type: requestOperation[0],
//   },
//   {
//     id: 3,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "2-R",
//     age: 31,
//     stackId: "4",
//     cellId: 2,
//     cycleNo: 5,
//     status: alertMessageStatus[1],
//     type: requestOperation[1],
//   },
//   {
//     id: 4,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "8-R",
//     age: 11,
//     stackId: "4",
//     cellId: 5,
//     cycleNo: 5,
//     status: alertMessageStatus[2],
//     type: requestOperation[1],
//   },
//   {
//     id: 5,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "9-R",
//     age: null,
//     stackId: "4",
//     cellId: 10,
//     cycleNo: 5,
//     status: alertMessageStatus[2],
//     type: requestOperation[1],
//   },
//   {
//     id: 6,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "12-R",
//     age: 150,
//     stackId: "4",
//     cellId: 2,
//     cycleNo: 5,
//     status: alertMessageStatus[2],
//     type: requestOperation[1],
//   },
//   {
//     id: 7,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "15-R",
//     age: 44,
//     stackId: "4",
//     cellId: 8,
//     cycleNo: 5,
//     status: alertMessageStatus[3],
//     type: requestOperation[0],
//   },
//   {
//     id: 8,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "1-R",
//     age: 36,
//     stackId: "4",
//     cellId: 1,
//     cycleNo: 5,
//     status: alertMessageStatus[3],
//     type: requestOperation[1],
//   },
//   {
//     id: 9,
//     date: new Date(),
//     containerId: "2",
//     nodeId: "4-R",
//     age: 65,
//     stackId: "4",
//     cellId: 1,
//     cycleNo: 5,
//     status: alertMessageStatus[3],
//     type: requestOperation[1],
//   },
// ]

// export const alertColumnSample: GridColDef[] = [
//   { field: "id", headerName: "ID", width: 90 },
//   {
//     field: "type",
//     headerName: "Type",
//   },
//   {
//     field: "date",
//     headerName: "Date",
//     width: 150,
//   },
//   {
//     field: "containerId",
//     headerName: "Container Id",
//     width: 150,
//   },
//   {
//     field: "stack/nodeId",
//     headerName: "Stack/Node Id",
//     width: 150,
//     valueGetter: (params: GridValueGetterParams) => `${params.row.stackId || ""} / ${params.row.nodeId || ""}`,
//   },
//   {
//     field: "cellId",
//     headerName: "Cell Id",
//     width: 150,
//   },
//   {
//     field: "cycleNo",
//     headerName: "Cycle",
//     width: 150,
//   },
//   {
//     field: "status",
//     headerName: "Status",
//     width: 150,
//   },
// ]

export const SOX_DISPLAY_STATUS = {
  open: 1, // no request nor operation has started
  manualRequest: 2, //waring level can manually requested
  autoRequest: 3, // alert, critical level can manually requested
  rollbackRequest: 4, //user cancel on any type request has been sended
  manualRequestOperating: 5, //warning level request is being operated
  autoRequestOperating: 6, //alert,critical level request is being operated
  rollbackReqeustOperating: 7, //roleback is being operated
  manualRequestDone: 8, //warning level request operation is completed
  autoRequestDone: 9, // alert, critical level request operation is completed
  rollbackRequestDone: 10, // roleback request operation is completed
  closed: 11, // when onm close the warning (this state is not currently used)
}
export const SOX_DISPLAY_STATUS_TEXT = {
  requested: "Requested",
  done: "Done",
  operating: "Operating",
  operatingRollback: "Operating Rollback",
  rollbackRequest: "Requested Rollback",
  closed: "Closed",
}

export const SOX_CATEGORY = { sos: 1, sod: 2, both: 3 }
export const SOX_TYPE = { warning: 1, alert: 2, critical: 3 }

export const SOX_CATEGORY_TEXT = {
  [SOX_CATEGORY.sos]: "SoS",
  [SOX_CATEGORY.sod]: "SoD",
  [SOX_CATEGORY.both]: "SoS & SoD",
}
export const SOX_TYPE_TEXT = {
  [SOX_TYPE.critical]: "Critical Alert",
  [SOX_TYPE.alert]: "Alert",
  [SOX_TYPE.warning]: "Warning",
}

export const USER_ROLE = { onmManager: 2, operator: 3, admin: 1, viewer: 4 }
export const USER_ROLE_TEXT = {
  [USER_ROLE.onmManager]: "OnM",
  [USER_ROLE.operator]: "Operator",
  [USER_ROLE.admin]: "Admin",
}

export const SOX_LEVEL = { critical: 3, alert: 2, warning: 1 }
export const SOX_LEVEL_MESSAGE = {
  [SOX_LEVEL.critical]: "Bypass Request has been assigned to Operator ",
  [SOX_LEVEL.alert]: "Bypass Request will be assigned to Operator within 48hours ",
  [SOX_LEVEL.warning]: "Choose Request button to assign Bypass Request to Operator ",
}
export const SOX_ALARM_LEVEL = {
  all: 1,
  critical: 2,
  criticalAndAlart: 3,
}

export const PlotType = {
  linkVoltage: 6,
  setVoltage: 7,
  voltage: 8,
  power: 9,
  stateOfCharge: 10,
  current: 11,
  temperature: 12,
  currentPower: 13,
  capacity: 14,
  energy: 15,
  sosDefault: 4,
  sodDefault: 5,
  status: 16,
  lli: 17,
  lamp: 18,
  lamn: 19,
  rul: 20,
  rsl: 21,
}
export const PlotTypeText = {
  [PlotType.linkVoltage]: "Link Voltage",
  [PlotType.setVoltage]: "Set Voltage",
  [PlotType.voltage]: "Voltage",
  [PlotType.power]: "Power",
  [PlotType.stateOfCharge]: "State of Charge",
  [PlotType.current]: "Current",
  [PlotType.temperature]: "Temperature",
  [PlotType.currentPower]: "Current Power",
  [PlotType.capacity]: "Capacity",
  [PlotType.energy]: "Energy",
  [PlotType.sosDefault]: "SoS Default",
  [PlotType.sodDefault]: "SoD Default",
  [PlotType.status]: "Status",
  [PlotType.lli]: "SOD LLI",
  [PlotType.lamp]: "SOD LAMP",
  [PlotType.lamn]: "SOD LAMN",
  [PlotType.rul]: "RUL",
  [PlotType.rsl]: "RSL",
}
export const OPERATION_CHART_SETTING_RADIO_OPTION = [
  { value: 1, label: "All" },
  { value: PlotType.status, label: PlotTypeText[PlotType.status] },
  { value: PlotType.current, label: PlotTypeText[PlotType.current] },
  { value: PlotType.power, label: PlotTypeText[PlotType.power] },
  { value: PlotType.stateOfCharge, label: PlotTypeText[PlotType.stateOfCharge] },
  { value: PlotType.temperature, label: PlotTypeText[PlotType.temperature] },
  { value: PlotType.voltage, label: PlotTypeText[PlotType.voltage] },
]
export const PERFORMANCE_CHART_SETTING_RADIO_OPTION = [
  { value: 1, label: "All" },
  { value: PlotType.capacity, label: PlotTypeText[PlotType.capacity] },
  { value: PlotType.energy, label: PlotTypeText[PlotType.energy] },
]
export const SOX_CHART_SETTING_RADIO_OPTION = [
  { value: 1, label: "All" },
  { value: PlotType.sosDefault, label: PlotTypeText[PlotType.sosDefault] },
  { value: PlotType.lli, label: PlotTypeText[PlotType.lli] },
  { value: PlotType.lamp, label: PlotTypeText[PlotType.lamp] },
  { value: PlotType.lamn, label: PlotTypeText[PlotType.lamn] },
]
export const AI_PLOT_TYPE_TITLE = {
  [PlotType.sosDefault]: "SoS",
  [PlotType.sodDefault]: "SoD",
  [PlotType.lli]: "SOD",
  [PlotType.lamp]: "SOD",
  [PlotType.lamn]: "SOD",
  [PlotType.rul]: "RUL",
  [PlotType.rsl]: "RSL",
}
export const SOX_STATE = {
  charge: 1,
  discharge: 2,
}
export const AI_STATE_OPTION = [
  { label: "Charge", value: 1 },
  { label: "Discharge", value: 2 },
]
export const SESSION_STORAGE_KEY = {
  operationChart: "operationSession",
  performanceChart: "performanceSession",
  soxChart: "soxSession",
}
export const ENERGY_THROUGHPUT = [
  { time: "4.25", value: 73 },
  { time: "4.26", value: 78 },
  { time: "4.27", value: 81 },
  { time: "4.28", value: 73 },
  { time: "4.29", value: 71 },
  { time: "4.30", value: 76 },
  { time: "5.01", value: 73 },
]

export const INERTIA_LOCATION = "2930 US-380, Haskell, TX 79521, USA"

export const PLANT_AC_STATUS_KEY = {
  INVALID: 0,
  CONNECTED: 1,
  DISCONNECTED: 2,
  FAULT: 3,
  STANDBY: 4,
  INIT: 5,
}
export const PLANT_STACK_STATUS_KEY = {
  INVALID: 0,
  OPERATE: 1,
  SHUTDOWN: 2,
  STANDBY: 3,
}

export const SYSTEM_STACK_STATUS_KEY = {
  INVALID: 0,
  OPERATE: 1,
  SHUTDOWN: 2,
  STANDBY: 3,
}
export const SYSTEM_NODE_STATUS_KEY = {
  INVALID: 0,
  OPERATE: 1,
  SHUTDOWN: 2,
  FATAL: 3,
  ONLINE: 4,
  BYPASS: 5,
}
export const PLANT_AC_STATUS = ["INVALID", "CONNECTED", "DISCONNECTED", "FAULT", "STANDBY", "INIT"]
export const PLANT_STACK_STATUS = ["INVALID", "OPERATE", "SHUTDOWN", "STANDBY"]

export const SYSTEM_STACK_STATUS = ["INVALID", "OPERATE", "SHUTDOWN", "STANDBY"]
export const SYSTEM_NODE_STATUS = ["INVALID", "OPERATE", "SHUTDOWN", "FATAL", "ONLINE", "BYPASS"]

export const PLANT_AC_STATUS_TEXT = {
  [PLANT_AC_STATUS[0]]: "Invalid",
  [PLANT_AC_STATUS[1]]: "Connected",
  [PLANT_AC_STATUS[2]]: "Disconnected",
  [PLANT_AC_STATUS[3]]: "Fault",
  [PLANT_AC_STATUS[4]]: "Standby",
  [PLANT_AC_STATUS[5]]: "Init",
}
export const PLANT_STACK_STATUS_TEXT = {
  [PLANT_STACK_STATUS[0]]: "Invalid",
  [PLANT_STACK_STATUS[1]]: "Operate",
  [PLANT_STACK_STATUS[2]]: "Shutdown",
  [PLANT_STACK_STATUS[3]]: "Standby",
}
export const SYSTEM_STACK_STATUS_TEXT = {
  [SYSTEM_STACK_STATUS[0]]: "Invalid",
  [SYSTEM_STACK_STATUS[1]]: "Operate",
  [SYSTEM_STACK_STATUS[2]]: "Shutdown",
  [SYSTEM_STACK_STATUS[3]]: "Standby",
}
export const SYSTEM_NODE_STATUS_TEXT = {
  [SYSTEM_NODE_STATUS[0]]: "Invalid",
  [SYSTEM_NODE_STATUS[1]]: "Operate",
  [SYSTEM_NODE_STATUS[2]]: "Shutdown",
  [SYSTEM_NODE_STATUS[3]]: "Fatal",
  [SYSTEM_NODE_STATUS[4]]: "Online",
  [SYSTEM_NODE_STATUS[5]]: "Bypass",
}
export const PLANT_AC_STATUS_COLOR = {
  [PLANT_AC_STATUS[0]]: "var(--num-context)",
  [PLANT_AC_STATUS[1]]: "var(--operate)",
  [PLANT_AC_STATUS[2]]: "var(--ready)",
  [PLANT_AC_STATUS[3]]: "var(--alarm)",
  [PLANT_AC_STATUS[4]]: "var(--discharging)",
  [PLANT_AC_STATUS[5]]: "var(--shutdown)",
}
export const PLANT_STACK_STATUS_COLOR = {
  [PLANT_STACK_STATUS[0]]: "var(--num-context)",
  [PLANT_STACK_STATUS[1]]: "var(--operate)",
  [PLANT_STACK_STATUS[2]]: "var(--ready)",
  [PLANT_STACK_STATUS[3]]: "var(--discharging)",
}
export const SYSTEM_STACK_STATUS_COLOR = {
  [SYSTEM_STACK_STATUS[0]]: "var(--num-context)",
  [SYSTEM_STACK_STATUS[1]]: "var(--operate)",
  [SYSTEM_STACK_STATUS[2]]: "var(--ready)",
  [SYSTEM_STACK_STATUS[3]]: "var(--discharging)",
}
export const SYSTEM_NODE_STATUS_COLOR = {
  [SYSTEM_NODE_STATUS[0]]: "var(--num-context)",
  [SYSTEM_NODE_STATUS[1]]: "var(--operate)",
  [SYSTEM_NODE_STATUS[2]]: "var(--ready)",
  [SYSTEM_NODE_STATUS[3]]: "var(--alarm)",
  [SYSTEM_NODE_STATUS[4]]: "var(--discharging)",
  [SYSTEM_NODE_STATUS[5]]: "var(--stack-query)",
}
export const PLANT_AC_STATUS_LEGEND = [
  { color: PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[0]], label: PLANT_AC_STATUS_TEXT[PLANT_AC_STATUS[0]] },
  { color: PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[1]], label: PLANT_AC_STATUS_TEXT[PLANT_AC_STATUS[1]] },
  { color: PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[2]], label: PLANT_AC_STATUS_TEXT[PLANT_AC_STATUS[2]] },
  { color: PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[3]], label: PLANT_AC_STATUS_TEXT[PLANT_AC_STATUS[3]] },
  { color: PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[4]], label: PLANT_AC_STATUS_TEXT[PLANT_AC_STATUS[4]] },
  { color: PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[5]], label: PLANT_AC_STATUS_TEXT[PLANT_AC_STATUS[5]] },
]

export const PLANT_STACK_STATUS_LEGEND = [
  { color: PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[0]], label: PLANT_STACK_STATUS_TEXT[PLANT_STACK_STATUS[0]] },
  { color: PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[1]], label: PLANT_STACK_STATUS_TEXT[PLANT_STACK_STATUS[1]] },
  { color: PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[2]], label: PLANT_STACK_STATUS_TEXT[PLANT_STACK_STATUS[2]] },
  { color: PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[3]], label: PLANT_STACK_STATUS_TEXT[PLANT_STACK_STATUS[3]] },
]
export const SYSTEM_STACK_STATUS_LEGEND = [
  { color: SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[0]], label: SYSTEM_STACK_STATUS_TEXT[SYSTEM_STACK_STATUS[0]] },
  { color: SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[1]], label: SYSTEM_STACK_STATUS_TEXT[SYSTEM_STACK_STATUS[1]] },
  { color: SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[2]], label: SYSTEM_STACK_STATUS_TEXT[SYSTEM_STACK_STATUS[2]] },
  { color: SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[3]], label: SYSTEM_STACK_STATUS_TEXT[SYSTEM_STACK_STATUS[3]] },
]
export const SYSTEM_NODE_STATUS_LEGEND = [
  { color: SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[0]], label: SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[0]] },
  { color: SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[1]], label: SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[1]] },
  { color: SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[2]], label: SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[2]] },
  { color: SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[3]], label: SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[3]] },
  { color: SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[4]], label: SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[4]] },
  { color: SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[5]], label: SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[5]] },
]
