import * as React from "react"

interface IEECheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label?: string
  disabled?: boolean
}

const EECheckbox: React.FunctionComponent<IEECheckboxProps> = ({ checked, onChange, label, disabled }) => {
  const handleChange = () => {
    if (disabled) return
    onChange(!checked)
  }

  return (
    <label className={`custom-checkbox-container ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        className="custom-checkbox-input"
      />
      <span className="custom-checkbox-checkmark"></span>
      {label && <span className="custom-checkbox-label">{label}</span>}
    </label>
  )
}

export default EECheckbox
