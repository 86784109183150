import { useEffect, useRef } from "react"
import {
  CellCount,
  ContainerCount,
  NodeCount,
  NodeName,
  Period,
  SOX_DISPLAY_STATUS,
  SOX_DISPLAY_STATUS_TEXT,
  StackCount,
} from "../constant"
import { SelectOption } from "../type/common"
import sampleData from "../common/sampleData.json"
import { chartColors } from "./chartColors"
import _ from "lodash"
import { CookieName, removeCookie } from "../cookie"
import dayjs from "dayjs"

export const getScatterData = (data, yKey) => {
  const newData: { x: number | null; y: number | null; label: string }[] = []
  data.map((_i, index) => {
    return newData.push({
      x: _i,
      y: data[yKey][index] ? data[yKey][index] * 100 : data[yKey][index],
      label: `${yKey} ${_i} cycle ${(data[yKey][index] * 100).toFixed(2)}%`,
    })
  })
  return newData
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef(null)
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    const executeCallback = () => {
      savedCallback.current()
    }
    const timerId = setInterval(executeCallback, delay)
    return () => clearInterval(timerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const createContainerOption = () => {
  const containerOption: SelectOption[] = []
  for (let i = 0; i < ContainerCount; i++) {
    containerOption.push({ value: `container ${i + 1}`, label: `Container ${i + 1}` })
  }
  return containerOption
}

export const createStackOption = ({ level }) => {
  const stackOption: SelectOption[] = []
  if (level.stack.noneValue) {
    stackOption.push({ value: "-1", label: "All" })
  }

  for (let i = 0; i < StackCount; i++) {
    stackOption.push({ value: `${(i + 1).toString()}`, label: `Stack ${i + 1}` })
  }
  return stackOption
}
export const createNodeOption = ({ level }) => {
  const nodeOption: SelectOption[] = []
  if (level.node.noneValue) {
    nodeOption.push({ value: "-1", label: "All" })
  }

  for (let i = 0; i < NodeCount; i++) {
    nodeOption.push({ value: `${(i + 1).toString()}`, label: `Node ${i + 1}` })
    // nodeOption.push({ value: `${(i + 1).toString()}-R`, label: `Node ${i + 1}-R` })
  }
  return nodeOption
}
export const createCellOption = () => {
  const cellOption: SelectOption[] = []
  cellOption.push({ value: "-1", label: "All" })

  for (let i = 0; i < CellCount; i++) {
    cellOption.push({ value: `${i + 1}`, label: `Cell ${i + 1}` })
  }
  return cellOption
}

export const dataGeneration = () => {
  let newData: any = []
  sampleData.systemOperation.time.map((item, index) => {
    return newData.push({
      time: item,
      value: Math.floor(Math.random() * (50 - 1) + 1),
    })
  })
  return newData
}

export const createData = (type) => {
  const defualtData: any = []
  const count = type === "node" ? NodeName.length : type === "stack" ? StackCount : CellCount

  for (let i = 0; i < count; i++) {
    defualtData.push(dataGeneration())
  }
  const dataReady = defualtData.map((grpName, index) => {
    const name = type === "node" ? `Node ${index % 2 === 0 ? index + "L" : index + 1 + "R"}` : `${type} ${index}`
    return {
      id: index,
      name: name,
      color: chartColors[index],
      values: grpName,
    }
  })
  return dataReady
}
type DataPoint = { time: number; value: number }

export const getClosestPoint = (cursorPixelPosition: number, xScale, data) => {
  const x = xScale.invert(cursorPixelPosition)
  let minDistance = Infinity
  let closest: DataPoint | null = null

  for (const point of data[0].values) {
    const distance = Math.abs(point.time - x)
    if (distance < minDistance) {
      minDistance = distance
      closest = point
    }
  }

  return closest
}
export const createPerformanceData = () => {
  const data: any = []
  _.times(10, (item) => {
    const randomValue = Math.floor(Math.random() * (170 - 120) + 120)
    data.push({ cycle: item + 1, valueLeft: Math.floor((randomValue / 170) * 100), valueRight: randomValue })
  })
  return data
}
export const dataConvert = (input: any) => {
  if (input.time && input.value) {
    const count = input.time.length
    const convertedArray = _.times(count, (index) => {
      return { time: input.time[index], value: input.value[index] || null }
    })

    return convertedArray
  }
}
export const dataTimelineConvert = (input: any) => {
  if (input.time && input.value) {
    const count = input.time.length
    // console.log("input.value[0]", input.value[0])
    const InitialConvertedArray = _.times(count, (index) => {
      if (input.value) {
        return {
          time: input.time[index],
          value: input.value[index]?.value || null,
          start: input.value[index]?.startTime || null,
          end: input.value[index]?.endTime || null,
        }
      } else {
        return {
          time: input.time[index],
          value: null,
          start: null,
          end: null,
        }
      }
    })
    return InitialConvertedArray
    // const InitialConvertedArray.map()
    //     return convertedArray
  }
}
// const testData = [
//   { time: 1, value: "test" },
//   { time: 2, value: "test" },
//   { time: 3, value: "test" },
//   { time: 4, value: "test" },
//   { time: 5, value: "test" },
//   { time: 6, value: "lost" },
//   { time: 7, value: "lost" },
//   { time: 8, value: "lost" },
//   { time: 9, value: "nest" },
//   { time: 10, value: "nest" },
//   { time: 11, value: "test" },
//   { time: 12, value: "test" },
//   { time: 13, value: "test" },
// ]
// testData.map((item, index) => {
//   console.log("item index", item, index)
//   if (index === 0) {
//     ppp.push(index)
//   } else if (index !== 0 && testData[index - 1].value !== item.value) {
//     ppp.push(index)
//   }
// })
export const dataCycleConvert = (input: any) => {
  if (input.cycle && input.value) {
    const count = input.cycle.length
    const convertedArray = _.times(count, (index) => {
      return { cycle: input.cycle[index], value: input.value[index] || null }
    })

    return convertedArray
  }
}
export const dataPerformanceConvert = (input: any) => {
  if (input.cycle) {
    const count = input.cycle.length
    const convertedArray = _.times(count, (index) => {
      return {
        cycle: input.cycle[index],
        valueLeft: input.valueLeft[index] || null,
        valueRight: input.valueRight[index] || null,
      }
    })

    return convertedArray
  }
}
export const Logout = () => {
  removeCookie(CookieName.ACCESS_TOKEN)
}

export const getSoxStatusText = (status) => {
  let text = ""
  switch (status) {
    case SOX_DISPLAY_STATUS.autoRequest:
    case SOX_DISPLAY_STATUS.manualRequest:
      text = SOX_DISPLAY_STATUS_TEXT.requested
      break
    case SOX_DISPLAY_STATUS.autoRequestDone:
    case SOX_DISPLAY_STATUS.manualRequestDone:
    case SOX_DISPLAY_STATUS.rollbackRequestDone:
      text = SOX_DISPLAY_STATUS_TEXT.done
      break
    case SOX_DISPLAY_STATUS.autoRequestOperating:
    case SOX_DISPLAY_STATUS.manualRequestOperating:
      text = SOX_DISPLAY_STATUS_TEXT.operating
      break
    case SOX_DISPLAY_STATUS.open:
      break
    case SOX_DISPLAY_STATUS.rollbackReqeustOperating:
      text = SOX_DISPLAY_STATUS_TEXT.operatingRollback
      break
    case SOX_DISPLAY_STATUS.rollbackRequest:
      text = SOX_DISPLAY_STATUS_TEXT.rollbackRequest
      break
    case SOX_DISPLAY_STATUS.closed:
      text = SOX_DISPLAY_STATUS_TEXT.closed
  }
  return text
}
export const getRequestStatus = (status) => {
  let text = ""
  switch (status) {
    case SOX_DISPLAY_STATUS.open:
    case SOX_DISPLAY_STATUS.autoRequest:
    case SOX_DISPLAY_STATUS.manualRequest:
    case SOX_DISPLAY_STATUS.rollbackRequest:
      text = ""
      break
    case SOX_DISPLAY_STATUS.autoRequestOperating:
    case SOX_DISPLAY_STATUS.manualRequestOperating:
    case SOX_DISPLAY_STATUS.rollbackReqeustOperating:
      text = "operate"
      break
    case SOX_DISPLAY_STATUS.autoRequestDone:
    case SOX_DISPLAY_STATUS.manualRequestDone:
    case SOX_DISPLAY_STATUS.rollbackRequestDone:
      text = "done"
      break
    case SOX_DISPLAY_STATUS.closed:
      text = "closed"
  }
  return text
}

export const getStartTime = (data) => {
  let startTime = 0
  // const now = dayjs().format("YYYY-MM-DD")
  // console.log(new Date())
  switch (data) {
    case Period.thirtyMinutes:
      startTime = dayjs().subtract(30, "minute").valueOf()
      break
    case Period.oneHour:
      startTime = dayjs().subtract(1, "hour").valueOf()
      break
    case Period.oneDay:
      startTime = dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`)
        .subtract(1, "day")
        .valueOf()
      break
    case Period.sevenDays:
      startTime = dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`)
        .subtract(7, "day")
        .valueOf()
      break
    default:
      startTime = dayjs(`${dayjs().format("YYYY-MM-DD")} 00:00:00`)
        .subtract(1, "day")
        .valueOf()
      break
  }
  return startTime
}

export const getSessionData = (sessionSearch) => {
  const data = window.sessionStorage.getItem(sessionSearch)

  if (data) {
    const sessionData = JSON.parse(data)
    // console.log("data :>> ", data)
    // console.log("sessionData :>> ", sessionData[0])
    return sessionData
  }
  return null
}
export const setSessionData = (sessionSearch, data) => {
  window.sessionStorage.setItem(sessionSearch, JSON.stringify(data))
}
