import * as React from "react"
import { useEffect, useRef, useState } from "react"

interface IZoominDemoProps {
  height: number
  isScroll: boolean
  intervalTime?: number
  handleScroll: () => void
}

const ZoominDemo: React.FunctionComponent<IZoominDemoProps> = ({ height, isScroll, intervalTime, handleScroll }) => {
  const containerRef = useRef(null)
  const intervalRef = useRef(null)
  const [playCount, setPlayCount] = useState(0)
  const startInterval = () => {
    const container = containerRef.current
    const scrollStep = 1 // Adjust scroll step to control speed

    intervalRef.current = setInterval(() => {
      // Check if we've reached the bottom of the container
      if (container.scrollTop + container.clientHeight >= container.scrollHeight || isScroll === false) {
        handleScroll()
        clearInterval(intervalRef.current) // Stop scrolling when at the bottom
      } else {
        container.scrollTop += scrollStep // Scroll down by the step
      }
    }, intervalTime)
  }
  const stopInterval = () => {
    clearInterval(intervalRef.current)
  }
  useEffect(() => {
    setPlayCount(0)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])
  useEffect(() => {
    if (isScroll) {
      if (playCount === 0) {
        setPlayCount(1)
        setTimeout(() => {
          startInterval()
        }, 7000)
      } else {
        setPlayCount(1)
        startInterval()
      }
    }
    if (!isScroll) {
      setPlayCount(1)
      stopInterval()
    }
  }, [isScroll])
  return (
    <div style={{ width: "100%", height: height, overflowX: "hidden", position: "relative" }} ref={containerRef}>
      <iframe
        src="https://dev.clbcloud.com/d/cdfaewq8nx8g0b/1c-inertia-operations-stack?orgId=1&var-acid=12F0E007&var-stack=2&var-node=$%7Bnode%7D&var-data_source=PROD&from=now-30d&to=now&viewPanel=47"
        width="100%"
        height="1100px"
        style={{ border: "none" }}
      ></iframe>
      <iframe
        src="https://dev.clbcloud.com/d/cdfaewq8nx8g0b/1c-inertia-operations-stack?orgId=1&var-acid=12F0E007&var-stack=2&var-node=$%7Bnode%7D&var-data_source=PROD&from=now-30d&to=now&viewPanel=26"
        width="100%"
        height="1100px"
        style={{ border: "none" }}
      ></iframe>

      <iframe
        src="https://dev.clbcloud.com/d/cdfaewq8nx8g0b/1c-inertia-operations-stack?orgId=1&var-acid=12F0E007&var-stack=2&var-node=$%7Bnode%7D&var-data_source=PROD&from=now-30d&to=now&viewPanel=26"
        width="100%"
        height="1100px"
        style={{ border: "none" }}
      ></iframe>
    </div>
  )
}

export default ZoominDemo
