import * as React from "react"
import "./container2.scss"
import { Container } from "../assets/Container.js"
import { PLANT_AC_STATUS, PLANT_AC_STATUS_COLOR, PLANT_AC_STATUS_KEY } from "../constant"

interface IContainerProps {
  status: any
  onClick: (containerId: number) => void
  id: string
  isDarkMode: boolean
}

const Container2: React.FunctionComponent<IContainerProps> = ({ status, onClick, id, isDarkMode }) => {
  const isAlarm = status.alert > 0 || status.warning > 0 || status.critical > 0 ? true : false
  if (status.acStatus === PLANT_AC_STATUS_KEY.numContexts) {
    return (
      <div>
        <Container
          fill={PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[status.acStatus]]}
          status={status.stackStatus}
          title={status.displayName}
          isAlarm={isAlarm}
          isDarkMode={isDarkMode}
        />
      </div>
    )
  }
  return (
    <div onClick={() => onClick(status.container)} style={{ cursor: "pointer" }} id={id}>
      <Container
        fill={PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[status.acStatus]]}
        status={status.stackStatus}
        title={status.displayName}
        isAlarm={isAlarm}
        isDarkMode={isDarkMode}
      />
    </div>
  )
}

export default Container2
