export const DevOptions = (() => {
  console.log("process.env.REACT_APP_ENV_PROCESS", process.env.REACT_APP_ENV_PROCESS)
  console.log("process.env", process.env)
  switch (process.env.REACT_APP_ENV_PROCESS) {
    case "prod":
      return {
        simulateDelay: 0,
        serverAddress: "https://ip-web-api.clbcloud.com/",
      }
    case "staging":
      return {
        simulateDelay: 0,
        serverAddress: "https://ip-web-api-staging.clbcloud.com/",
      }
    case "dev":
      return {
        simulateDelay: 0,
        serverAddress: "https://ip-web-api-dev.clbcloud.com/",
      }
    case "local":
      return {
        simulateDelay: 0,
        serverAddress: "http://localhost:8080/",
      }
    default:
      return {
        simulateDelay: 0,
        serverAddress: "http://localhost:8080/",
      }
  }
})()
