import React from "react"
import { useAppRoutes } from "./routes/routes"
import { ApiContextProvider } from "./context/ApiContext"
import "./App.scss"

import { AppContextProvider } from "./context/AppContext"
import { AuthContextProvider } from "./context/AuthContext"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
function App() {
  const routes = useAppRoutes()
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ApiContextProvider>
        <AuthContextProvider>
          <AppContextProvider>{routes}</AppContextProvider>
        </AuthContextProvider>
      </ApiContextProvider>
    </QueryClientProvider>
  )
}

export default App
