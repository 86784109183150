import * as React from "react"
import "./loading.scss"

interface IEELoadingProps {
  size?: number
  thickness?: number
  color?: string
}

const EELoading: React.FunctionComponent<IEELoadingProps> = ({ size = 40, thickness = 4, color = "#1976d2" }) => {
  const circleStyle = {
    width: size,
    height: size,
    borderWidth: thickness,
    borderColor: `${color} transparent transparent transparent`,
  }

  return (
    <div className="ee-loading-wrapper">
      <div className="custom-circular-progress">
        <div className="custom-circular-progress-circle" style={circleStyle}></div>
      </div>
    </div>
  )
}

export default EELoading
