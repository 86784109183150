export const StackBar = ({
  height,
  date,
  fill,
  isMulti = false,
  height2 = 0,
  fill2 = "",
  onMouseOver = (e) => {},
  onMouseLeave = () => {},
  id,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="120" viewBox="0 0 30 120" fill="none">
      <rect
        x="1"
        width="16"
        height="101"
        rx="4"
        fill="#404145"
        onMouseMove={(e) => onMouseOver(e)}
        onMouseLeave={onMouseLeave}
      />
      {isMulti ? (
        <rect
          x="1"
          y={101 - height2 - height}
          width="16"
          height={height2 + height}
          rx="4"
          fill={fill2}
          onMouseMove={(e) => onMouseOver(e)}
          onMouseLeave={onMouseLeave}
        />
      ) : null}
      <rect
        x="1"
        y={101 - height}
        width="16"
        height={height}
        rx="4"
        fill={fill}
        onMouseMove={(e) => onMouseOver(e)}
        onMouseLeave={onMouseLeave}
        id={id}
      />
      <text fill="var(--page-section-title)" x="-5" y="120" fontSize="12">
        {date}
      </text>
    </svg>
  )
}
