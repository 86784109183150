import * as React from "react"
import MSLogo from "../../assets/images/SSOMSIcon.png"
import "./login.scss"
import URLs from "../../routes/urls"
import { useAuthContext } from "../../context/AuthContext"
import { useLocation, useNavigate } from "react-router-dom"
import ServerURLs from "../../routes/serverUrls"
import { baseURL } from "../../context/AxiosApi"
import EELoading from "../../component/Loading"
import { CookieName, getAccessToken } from "../../cookie"
import { useAppContext } from "../../context/AppContext"
import { USER_ROLE } from "../../constant"
import { EELogo } from "../../assets/images/ee-logo"
import { Button } from "../../component/Button"
import EETextField from "../../component/EETextField"
import { DarkModeIcon } from "../../assets/images/dark_mode"
import { LightModeIcon } from "../../assets/images/light_mode"
interface ILoginProps {}

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const height = window.innerHeight
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const [data, setData] = React.useState({ email: "", password: "" })
  const authContext = useAuthContext()
  const [isLoading, setIsLoading] = React.useState(false)
  const { emailLogin, ssoLogin, checkLogin } = authContext
  const navigate = useNavigate()
  const appContext = useAppContext()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [emailError, setEmailError] = React.useState<string>("")
  const [passwordError, setPasswordError] = React.useState<string>("")
  // function useQuery() {
  //   const { search } = useLocation()
  //   return React.useMemo(() => new URLSearchParams(search), [search])
  // }
  // access query parameters

  const { isDarkMode, updateIsDarkMode } = appContext
  const onChange = React.useCallback(() => {
    updateIsDarkMode()
  }, [updateIsDarkMode])
  //commenting temporarily to test sso login
  // React.useEffect(() => {
  //   setIsLoading(true)
  //   let token
  //   let count = 0
  //   let timerId = setInterval(() => {
  //     token = getAccessToken()
  //     console.log("token checkckc", token)
  //     if (token) {
  //       clearInterval(timerId)
  //       navigate(URLs.System)
  //       setIsLoading(false)
  //       return
  //     }
  //     if (count > 60) {
  //       clearInterval(timerId)
  //       setIsLoading(false)
  //       return
  //     }
  //     count++
  //   }, 1000)
  // }, [token])
  React.useEffect(() => {
    // const paramValue = queryParams.get(CookieName.ACCESS_TOKEN)
    const paramValue = query.get(CookieName.ACCESS_TOKEN)
    if (paramValue) {
      ssoLogin(paramValue)
      return
    }
    const token = getAccessToken()
    if (token) {
      checkLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const handleChange = React.useCallback((e, inputType) => {
    if (inputType === "email") {
      setData((prev) => {
        return {
          ...prev,
          email: e.target.value,
        }
      })
      setEmailError("")
    } else {
      setData((prev) => {
        return {
          ...prev,
          password: e.target.value,
        }
      })
      setPasswordError("")
    }
  }, [])
  const handleSSOLogin = () => {
    window.location.href = `${baseURL}/${ServerURLs.SSOAzureLogin}`
    // setIsLoading(true)
    // let token
    // let count = 0
    // let timerId = setInterval(() => {
    //   token = getAccessToken()
    //   if (token) {
    //     clearInterval(timerId)
    //     navigate(URLs.System)
    //     setIsLoading(false)
    //     return
    //   }
    //   if (count > 60) {
    //     clearInterval(timerId)
    //     setIsLoading(false)
    //     return
    //   }
    //   count++
    // }, 1000)
  }

  const handleLogin = React.useCallback(async () => {
    if (data.email === "") {
      setEmailError("Email is required")
      return
    }
    if (data.password === "") {
      setPasswordError("Password is required")
      return
    }
    setIsLoading(true)
    const result = await emailLogin(data.email, data.password)
    if (!result) {
      alert("login failed!!!")
      setIsLoading(false)
    } else {
      setIsLoading(false)
      if (result.roleId === USER_ROLE.admin) {
        navigate(URLs.AdminMain)
      } else {
        navigate(URLs.Plant)
      }
    }
  }, [emailLogin, navigate, data])
  if (isLoading) {
    return <EELoading />
  }
  return (
    <div className="login-page-wrapper" style={{ height: height }}>
      <div style={{ paddingTop: "12px", cursor: "pointer", color: "var(--chart-title)" }} onClick={onChange}>
        {!isDarkMode ? <DarkModeIcon /> : <LightModeIcon />}
      </div>
      <div className="logo-wrapper">
        {/* <img src={Logo} width="229px" /> */}
        <EELogo fill={"var(--ee-logo-color)"} width="229px" />
      </div>
      <div className="login-form-wrapper">
        <EETextField
          error={emailError !== "" ? false : true}
          value={data.email}
          placeholder="Email Address"
          onChange={(e) => handleChange(e, "email")}
          label="Email Address"
          helperText={emailError}
          style={{ width: "100%" }}
          id="login-email"
        />
        <EETextField
          error={passwordError !== "" ? false : true}
          value={data.password}
          placeholder="Password"
          onChange={(e) => handleChange(e, "password")}
          label="Password"
          helperText={passwordError}
          style={{ width: "100%" }}
          id="login-password"
          type="password"
        />

        <Button label={"Sign In"} onClick={handleLogin} fullwidth id="ee-login-button" />
        <Button
          label="Sign in with Microsoft"
          startIcon={<img src={MSLogo} alt="" />}
          onClick={handleSSOLogin}
          fullwidth
          style={{ backgroundColor: "var( --sso-login-button)", color: "var(--sso-login-button-text)" }}
          id="ee-sso-login-button"
        />
      </div>
    </div>
  )
}

export default Login
