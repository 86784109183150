import * as React from "react"
import "./eeMenu.scss"

interface IEEMenuProps {
  children: React.ReactNode
  isOpen: boolean
}

const EEMenu: React.FunctionComponent<IEEMenuProps> = ({ children, isOpen }) => {
  //   const [isOpen, setIsOpen] = React.useState(false)
  const menuRef = React.useRef<HTMLDivElement>(null)

  //   const handleToggle = () => {
  //     setIsOpen(!isOpen)
  //   }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      //   setIsOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="custom-menu-container" id="basic-menu" ref={menuRef}>
      {isOpen ? children : null}
    </div>
  )
}

export default EEMenu
