import React from "react"
import "./dotAnimation.scss" // CSS 파일로 애니메이션 스타일 추가

const DotAnimation = ({ play = true }) => {
  return (
    <div className={play ? "loading-dots" : "loading-dots-stop"}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  )
}

export default DotAnimation
