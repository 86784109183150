import * as React from "react"
import "./periodForm.scss"
import EEDateTimePicker from "./EEDateTImePicker"

interface IPeriodFormProps {
  toDate: string | null | undefined
  fromDate: string | null | undefined
  onToDateChange: (value) => void
  onFromDateChange: (value) => void
}

const PeriodForm: React.FunctionComponent<IPeriodFormProps> = ({
  toDate,
  fromDate,
  onToDateChange,
  onFromDateChange,
}) => {
  console.log("fromDate", fromDate)
  const handleToDateChange = (date, time) => {
    console.log("newValue", date, time)
    onToDateChange(`${date} ${time}`)
  }

  const handleFromDateChange = (date, time) => {
    // console.log("newValue", newValue)
    onFromDateChange(`${date} ${time}`)
  }
  React.useEffect(() => {
    console.log("fromDate", fromDate)
  }, [fromDate, toDate])
  return (
    <div className="form-wrapper">
      <div className="input-with-text">
        {/* <DateTimePicker
          value={fromDate}
          format="YYYY/MM/DD HH:mm:ss"
          sx={{ color: "var(--tooltip-text)" }}
          className="ee-date-picker"
          slotProps={{
            field: {
              readOnly: true,
            },
            inputAdornment: {
              position: "start",
            },
            textField: {
              variant: "standard",
              InputProps: { disableUnderline: true },
            },
          }}
          onAccept={handleFromDateChange}
        /> */}
        <EEDateTimePicker label="From" onChange={handleFromDateChange} value={fromDate} />
      </div>
      <div className="input-with-text">
        {/* <DateTimePicker
          value={toDate}
          format="YYYY/MM/DD HH:mm:ss"
          sx={{ color: "var(--tooltip-text)", border: "0px solid var(--ee-select-border) !important" }}
          className="ee-date-picker"
          slotProps={{
            field: {
              readOnly: true,
            },
            inputAdornment: {
              position: "start",
            },
            textField: {
              variant: "standard",
              InputProps: { disableUnderline: true },
            },
          }}
          onAccept={handleToDateChange}
        /> */}
        <EEDateTimePicker label="To" onChange={handleToDateChange} value={toDate} />
      </div>
    </div>
  )
}

export default PeriodForm
