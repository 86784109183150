import * as React from "react"
import { EyeSolid } from "../assets/images/eye-solid"
import { EyeSolidSlash } from "../assets/images/eye-solid-slash"
import "./eeTextField.scss"

interface IEETextFieldProps {
  error?: boolean
  label?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  helperText?: string
  id?: string
  style: React.CSSProperties
  fullWidth?: boolean
  type?: string
}

const EETextField: React.FunctionComponent<IEETextFieldProps> = ({
  error = true,
  label,
  value,
  onChange,
  placeholder,
  helperText,
  id,
  style,
  fullWidth = false,
  type = "text",
}) => {
  const [visible, setVisible] = React.useState(false)

  return (
    <div
      className={`ee-textfield-container`}
      style={{ width: fullWidth ? "100%" : "inherit", ...style, borderColor: !error ? "red" : "var(--chart-title)" }}
    >
      {label && <label className="ee-textfield-label">{label}</label>}
      <div className="ee-texfield-wrapper">
        <input
          className="ee-textfield-input"
          type={type === "password" && visible ? "text" : type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
        />
        {type === "password" ? (
          <span
            className="ee-visible-icon"
            onClick={() => {
              setVisible(!visible)
            }}
            id="ee-password-icon-button"
          >
            {visible ? (
              <EyeSolid width="20px" fill="var(--chart-title)" />
            ) : (
              <EyeSolidSlash width="20px" fill="var(--chart-title)" />
            )}
          </span>
        ) : null}
      </div>
      {helperText && (
        <span className="ee-textfield-helpertext" id={`${id}-helper-text`}>
          {helperText}
        </span>
      )}
    </div>
  )
}

export default EETextField
