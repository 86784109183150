import * as React from "react"
import URLs from "../routes/urls"
import { EELogo } from "../assets/images/ee-logo"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import "./leftMenu.scss"
import { Logout } from "../common/util"
import { useAppContext } from "../context/AppContext"
import { AccountCircleIcon } from "../assets/images/account_circle"
import EEMenu from "./EEMenu"
import { EEMenuItem } from "./EEMenuItem"
import { DarkModeIcon } from "../assets/images/dark_mode"
import { LightModeIcon } from "../assets/images/light_mode"
interface ILeftMenuProps {}

const LeftMenu: React.FunctionComponent<ILeftMenuProps> = (props) => {
  const appContext = useAppContext()
  const { isDarkMode, updateIsDarkMode } = appContext
  const [pathname, setPathname] = React.useState<string>()
  const location = useLocation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState(false)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const onChange = React.useCallback(() => {
    updateIsDarkMode()
  }, [updateIsDarkMode])
  useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname])

  // const handleClick = (type: string) => {
  //   if (type === "operation") {
  //     setOpenOperation(!openOperation)
  //     // setOpenPerformance(false)
  //   } else {
  //     setOpenPerformance(!openPerformance)
  //     // setOpenOperation(false)
  //   }
  // }
  const handleLogout = async () => {
    // await logout()
    Logout()
    navigate(URLs.Login)
  }
  return (
    <div className="left-menu-wrapper">
      <div className="logo-wrapper">
        <EELogo fill={"var(--ee-logo-color)"} width="155px" />
      </div>
      <ul className="custom-menu-list">
        <li
          onClick={() => {
            navigate(URLs.AdminMain)
          }}
          className={
            pathname === URLs.AdminMain ? "custom-menu-list-item left-menu-selected" : "custom-menu-list-item left-menu"
          }
        >
          <div>Dashboard</div>
        </li>

        <li
          className={
            pathname === URLs.AdminMembers
              ? "custom-menu-list-item left-menu-selected"
              : "custom-menu-list-item left-menu"
          }
          onClick={() => {
            navigate(URLs.AdminMembers)
          }}
        >
          <div>Members</div>
        </li>
      </ul>
      <div className="left-menu-footer">
        <div style={{ cursor: "pointer" }} onClick={onChange}>
          {!isDarkMode ? <DarkModeIcon fill="var(--tooltip-text)" /> : <LightModeIcon fill="var(--tooltip-text)" />}
        </div>
        {/* <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <Notifications />
              </Badge>
            </IconButton> */}
        <div onClick={(e) => handleClick(e)}>
          <AccountCircleIcon fill="var(--tooltip-text)" />
          <div>
            <EEMenu isOpen={open}>
              <EEMenuItem
                onClick={() => {
                  navigate(URLs.Profile)
                  handleClose()
                }}
                className={"dropdown-menu-item"}
              >
                <div>Setting</div>
              </EEMenuItem>
              <EEMenuItem onClick={handleLogout} className={"dropdown-menu-item"}>
                Logout
              </EEMenuItem>
            </EEMenu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftMenu
