import * as React from "react"
import { useAppContext } from "../../context/AppContext"
import { USER_ROLE, USER_ROLE_TEXT } from "../../constant"
import "./adminMembers.scss"
import { Button } from "../../component/Button"
import { EEDataGrid } from "../../component/EEDataGrid"
import EERadio from "../../component/EERadio"

interface IAdminMembersProps {}

const AdminMembers: React.FunctionComponent<IAdminMembersProps> = (props) => {
  const userColumns = [
    { field: "id", headerName: "ID" },
    { field: "firstName", headerName: "First name" },
    { field: "lastName", headerName: "Last name" },
    { field: "email", headerName: "Email" },
    {
      field: "roleId",
      headerName: "Role",
      valueGetter: (params: any) => USER_ROLE_TEXT[params.roleId],
    },
    { field: "lastLogin", headerName: "Last Login" },
    {
      field: "activated",
      headerName: "Activated",
      renderCell(params: any) {
        if (params.activated)
          return (
            <Button
              onClick={() => handleActivate(false)}
              label="DEACTIVATE"
              style={{ height: "35px", fontSize: "0.7rem !important", backgroundColor: "var(--alarm)" }}
            ></Button>
          )
        return (
          <Button
            label="APPROVE"
            onClick={() => handleActivate(true)}
            style={{ height: "35px", fontSize: "0.7rem !important" }}
          ></Button>
        )
      },
    },
  ]
  const [selectedUser, setSelectedUser] = React.useState<any>()
  const [roleIdRadio, setRoleIdRadio] = React.useState<number>()
  const appContext = useAppContext()
  const { getUserList, updateUserRole, updateUserActivate } = appContext
  const [userList, setUserList] = React.useState<any[]>([])
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getData = async () => {
    const userList = await getUserList()
    if (userList && userList.data) {
      setUserList(userList.data)
    }
  }

  const handleUpdateRole = async () => {
    if (selectedUser && roleIdRadio) {
      const response = await updateUserRole(selectedUser.id, roleIdRadio)
      if (response?.statusCode === 200) {
        getData()
      }
    }
  }
  const handleActivate = async (activate: boolean) => {
    if (selectedUser) {
      const response = await updateUserActivate(selectedUser.id, activate)
      if (response?.statusCode === 200) {
        getData()
      }
    }
  }

  return (
    <div className="admin-memebers-wrapper">
      {userList.length > 0 ? (
        <EEDataGrid
          columns={userColumns}
          rows={userList}
          onRowClick={(row) => {
            setSelectedUser(row)
            setRoleIdRadio(row.roleId)
          }}
        />
      ) : null}

      {selectedUser ? (
        <div className="admin-memebers-edit-wrapper">
          <span style={{ paddingTop: "24px", paddingLeft: "24px" }}>Role: {USER_ROLE_TEXT[selectedUser.roleId]}</span>
          <span style={{ padding: "24px" }}>Email: {selectedUser.email}</span>
          <div>
            <div
              style={{
                paddingLeft: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ color: "var(--page-section-title)" }}>Role Edit:</span>
              <div style={{ marginBottom: "12px" }}>
                <EERadio
                  value={USER_ROLE.operator}
                  checked={USER_ROLE.operator === roleIdRadio}
                  label="Operator"
                  onChange={(e) => setRoleIdRadio(USER_ROLE.operator)}
                  name="operatorSMSYes"
                />
              </div>
              <div style={{ marginBottom: "12px" }}>
                <EERadio
                  value={USER_ROLE.onmManager}
                  checked={USER_ROLE.operator === roleIdRadio}
                  label="ONM Manager"
                  onChange={(e) => setRoleIdRadio(USER_ROLE.onmManager)}
                  name="operatorSMSYes"
                />
              </div>
              <div style={{ marginBottom: "12px" }}>
                <EERadio
                  value={USER_ROLE.admin}
                  checked={USER_ROLE.admin === roleIdRadio}
                  label="Admin"
                  onChange={(e) => setRoleIdRadio(USER_ROLE.admin)}
                  name="operatorSMSYes"
                />
              </div>
            </div>

            <Button
              label="UPDATE"
              style={{ height: "35px", fontSize: "0.7rem !important" }}
              onClick={handleUpdateRole}
            ></Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AdminMembers
