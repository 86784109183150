import { StackStatusColor } from "../common/systemData"
import { PLANT_STACK_STATUS, PLANT_STACK_STATUS_COLOR } from "../constant"
export const Container = ({ fill, title, isAlarm, status, isDarkMode }) => {
  return (
    <svg width="110" height="68" viewBox="0 0 110 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_48_2059)">
        <rect
          x="4"
          y="2"
          width="102"
          height="58"
          rx="2"
          fill={isDarkMode ? "#1A1F26" : "#fff"}
          fillOpacity="0.8"
          shapeRendering="crispEdges"
          stroke={fill}
          strokeWidth="3"
          strokeLinejoin="round"
        />
      </g>

      <text fill={isDarkMode ? "white" : "#010101"} x="12" y="15" fontSize="10">
        {title}
      </text>
      <g filter="url(#filter1_i_48_2059)">
        <rect x="8" y="20" width="93" height="34" fill="#B5B5B5" fillOpacity="0.5" />
      </g>
      <g filter="url(#filter2_i_48_2059)">
        <rect x="9" y="21" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[0]]]} />
        <rect x="9" y="26.8" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[1]]]} />
        <rect x="9" y="32.6" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[2]]]} />
        <rect x="9" y="38.4" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[3]]]} />
        <rect x="9" y="44.2" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[4]]]} />
        <rect x="9" y="50" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[5]]]} />
      </g>
      <g filter="url(#filter3_i_48_2059)">
        <rect x="60" y="21" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[6]]]} />
        <rect x="60" y="26.8" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[7]]]} />
        <rect x="60" y="32.6" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[8]]]} />
        <rect x="60" y="38.4" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[9]]]} />
        <rect x="60" y="44.2" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[10]]]} />
        <rect x="60" y="50" width="40" height="4" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[11]]]} />
      </g>
      <defs>
        <filter
          id="filter0_d_48_2059"
          x="0"
          y="2"
          width="110"
          height="66"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_48_2059" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_48_2059" result="shape" />
        </filter>
        <filter
          id="filter1_i_48_2059"
          x="8"
          y="20"
          width="94"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_2059" />
        </filter>
        <filter
          id="filter2_i_48_2059"
          x="9"
          y="21"
          width="40"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_2059" />
        </filter>
        <filter
          id="filter3_i_48_2059"
          x="60"
          y="21"
          width="40"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_48_2059" />
        </filter>
      </defs>
    </svg>
  )
}
