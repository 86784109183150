import React from "react"
import "./eeTooltip.scss"
type EETooltipProps = {
  interactionData: any
  isTimeline?: boolean
  customTooltip?: boolean
  isMulti?: boolean
  id?: string
}

export const EETooltip = ({
  interactionData,
  isTimeline = false,
  customTooltip = false,
  isMulti = false,
  id,
}: EETooltipProps) => {
  if (!interactionData) {
    return null
  }
  // console.log("interactionData", interactionData)
  // const { xPos, yPos, name, color, x, y, status } = interactionData
  return (
    <div
      id={id}
      className="ee-tooltip"
      style={{
        left: isMulti ? interactionData[0].xPos : interactionData.xPos,
        top: isMulti ? interactionData[0].yPos : interactionData.yPos,
      }}
    >
      {isMulti ? (
        <div className="ee-tooltip-row">
          <b>{interactionData[0].x ? interactionData[0].x : ""}</b>
        </div>
      ) : (
        <div className="ee-tooltip-row">
          <b>{interactionData.x ? interactionData.x : ""}</b>
        </div>
      )}
      {isMulti ? (
        interactionData.map((item, index) => (
          <>
            <div key={index} className="ee-tooltip-topHalfContainer" style={{ borderColor: item.color }}>
              <div className="ee-tooltip-row" style={{ marginBottom: "12px", justifyContent: "flex-start" }}>
                <b className="ee-tooltip-title">{item.name}</b>
                {isTimeline ? <b>Status : {item.status}</b> : customTooltip ? <b>{item.y}</b> : <b>{item.y}</b>}
              </div>
            </div>
          </>
        ))
      ) : (
        <>
          <b className="ee-tooltip-title">{interactionData.name}</b>

          <div className="ee-tooltip-topHalfContainer" style={{ borderColor: interactionData.color }}>
            <div className="ee-tooltip-row">
              {/* <b>{interactionData.x ? dayjs(interactionData.x).format("YYYY/MM/DD hh:mm:ss") : ""}</b> */}
            </div>
            <div className="ee-tooltip-row">
              {isTimeline ? (
                <b>Status : {interactionData.status}</b>
              ) : customTooltip ? (
                <b>{interactionData.y}</b>
              ) : (
                <b>{interactionData.y}</b>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
