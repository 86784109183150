export const chartColors = [
  "#1CB454",
  "#E2A907",
  "#1E85FF",
  "#d52d2d",
  "#912af3",
  "#cea000",
  "#f9a8a8",
  "#255626",
  "#6a707e",
  "#58178c",
  "#50bcff",
  "#fff643",
  "#ea8c4d",
  "#b881ff",
  "#20298f",
  "#7b7b7b",
  "#6eba70",
  "#124f24",
  "#7b2121",
  "#FF79DA",
  "#9bdb9a",
  "#fffe87",
  "#d3b2ff",
  "#97b7ff",
  "#888888",
  "#274481",
  "#3e140c",
  "#0f51be",
  "#942020",
  "#c7ecc6",
  "#fccccc",
  "#7d1ad6",
]
