import _ from "lodash"
import * as React from "react"
import {
  createNodeOption,
  createStackOption,
  dataPerformanceConvert,
  getSessionData,
  setSessionData,
} from "../../common/util"
import EEPerformanceChart from "../../component/charts/EEPerformanceChart"
import { chartColors } from "../../common/chartColors"
import EELegend from "../../component/charts/EELegend"
import EESelect from "../../component/EESelect"
import { OPTION_NONE_SELECT_VALUE, PlotType, SESSION_STORAGE_KEY } from "../../constant"
import { useState } from "react"
import { PerformanceParam } from "../../type/chart"
import { useAppContext } from "../../context/AppContext"
import { useQuery } from "@tanstack/react-query"
import EELoading from "../../component/Loading"
import { getAccessToken } from "../../cookie"

interface IPerformanceChartPanelProps {
  selectOption: any
  chartId: number
  defaultSelect?: any
}

const PerformanceChartPanel: React.FunctionComponent<IPerformanceChartPanelProps> = ({
  selectOption,
  chartId,
  defaultSelect,
}) => {
  const [newLegend, setNewLegend] = useState<any>()
  const [updateData, setUpdateData] = useState()
  const [currentData, setCurrentData] = useState<any>()

  const [noData, setNodata] = useState<boolean>(false)

  const [stackOption, setStackOption] = useState<any>()
  const [nodeOption, setNodeOption] = useState<any>()
  const [selectedOption, setSelectedOption] = useState<any>(
    defaultSelect ? selectOption?.find((item) => item.id === defaultSelect.plotCategoryId) : null
  )
  const [leftPoint, setLeftPoint] = useState([0, 0])
  const [rightPoint, setRightPoint] = useState([0, 0])
  const appContext = useAppContext()
  const [unit, setUnit] = useState(["%", "Ah"])
  const [nodeDisabled, setNodeDisabled] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [containerOption, setContainerOption] = useState<any>()
  const { getPerformanceChartData, getContainerOptions } = appContext
  const [dataParam, setDataParam] = useState<PerformanceParam>({
    plotType: defaultSelect ? defaultSelect.plotCategoryId : PlotType.capacity,
    container: 0,
    node: defaultSelect ? defaultSelect.node : null,
    stack: defaultSelect ? defaultSelect.stack : null,
  })
  React.useEffect(() => {
    if (selectOption) {
      setSelectedOption(
        defaultSelect ? selectOption?.find((item) => item.id === defaultSelect.plotCategoryId) : selectOption[0]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOption])
  const { data, isPending } = useQuery({
    queryKey: ["performanceData", dataParam],
    queryFn: async () => {
      const data = await getPerformanceChartData(dataParam)
      if (data.statusCode === 500 || data.statusCode === 400) {
        setErrorMessage("Internal Server Error")
        setCurrentData(null)
        setUpdateData(null)
        setNewLegend(null)
        return null
      }
      return data
    },
  })
  React.useEffect(() => {
    if (dataParam.stack === null) {
      setNodeDisabled(true)
      // setDataParam((prev) => Object.assign({ ...prev, node: null }))
    } else if (dataParam.node === null) {
      setNodeDisabled(false)
    } else if (dataParam.stack !== null) {
      setNodeDisabled(false)
    } else {
      setNodeDisabled(false)
    }
    const sessionData = getSessionData(SESSION_STORAGE_KEY.performanceChart)
    if (sessionData) {
      const newSessionData = sessionData.map((item, index) => (index === chartId ? { ...item, ...dataParam } : item))
      setSessionData(SESSION_STORAGE_KEY.performanceChart, newSessionData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParam])

  // const containerOption = createContainerOption()
  const createContainerOption = async () => {
    const containerOptionsResponse = await getContainerOptions()
    if (containerOptionsResponse && containerOptionsResponse.statusCode === 200) {
      setContainerOption(containerOptionsResponse.data)
      setDataParam((prev) => Object.assign({ ...prev, container: containerOptionsResponse.data[0].value }))
    }
  }

  React.useEffect(() => {
    createContainerOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (selectedOption) {
      const sOption = !selectedOption.level.stack.activate ? null : createStackOption(selectedOption)
      const nOption = !selectedOption.level.node.activate ? null : createNodeOption(selectedOption)
      setStackOption(sOption)
      setNodeOption(nOption)
      if (nOption === null) {
        setDataParam((prev) => Object.assign({ ...prev, node: null }))
      }
      if (sOption === null) {
        setDataParam((prev) => Object.assign({ ...prev, stack: null }))
      }
      if (dataParam.plotType === PlotType.capacity) {
        setUnit(["%", "Ah"])
      } else {
        setUnit(["%", "kWh"])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  const createData = () => {
    setNodata(false)
    setLeftPoint([data.minPercentPoint, data.maxPercentPoint])
    setRightPoint([data.minValuePoint, data.maxValuePoint])
    setUnit(data.unit)

    const keyArray = Object.keys(data.plotData).filter(function (letter) {
      return letter.toLowerCase() !== "cycle"
    })

    if (data.plotData && keyArray.length > 0) {
      const timeValue = _.pickBy(data.plotData, function (v, k) {
        return _.includes(k.toLowerCase(), "cycle")
      })
      if (timeValue.length < 1) {
        setNodata(true)
        return
      }
      const newLeftValue = _.pickBy(data.plotData, function (v, k) {
        return _.includes(k.toLowerCase(), "percent")
      })
      const newRightValue = _.pickBy(data.plotData, function (v, k) {
        return !_.includes(k.toLowerCase(), "percent") && !_.includes(k.toLowerCase(), "cycle")
      })

      const leftValueKeys = Object.keys(newLeftValue)
      const rightValueKeys = Object.keys(newRightValue)
      const chartData: any = []
      _.times(leftValueKeys.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = rightValueKeys[item]
        dataNew.values = dataPerformanceConvert({
          cycle: timeValue.cycle,
          valueLeft: newLeftValue[leftValueKeys[item]] || [],
          valueRight: newRightValue[rightValueKeys[item]] || [],
        })

        chartData.push(dataNew)
      })
      setCurrentData(chartData)
      setUpdateData(chartData)
      setNodata(false)
    } else {
      setNodata(true)
    }
  }
  React.useEffect(() => {
    if (!getAccessToken()) {
      return
    }
    if (data && Object.keys(data).length > 0) {
      createData()
    } else {
      setNodata(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataParam, getAccessToken()])

  React.useEffect(() => {
    const legend: any = []
    if (currentData && currentData.length > 0) {
      const dataKeys = Object.keys(currentData)
      dataKeys.map((item, index) => {
        return legend.push({ title: currentData[index].name, color: currentData[index].color, checked: true })
      })
      setNewLegend(legend)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData])
  React.useEffect(() => {
    if (newLegend) {
      const nextData: any = []
      // eslint-disable-next-line   array-callback-return
      newLegend.map((item, index) => {
        if (item.checked) return nextData.push(currentData[index])
      })
      setUpdateData(nextData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLegend])

  const handleDisplayAll = () => {
    if (newLegend) {
      const nextLegend = newLegend.map((item) => {
        return { ...item, checked: true }
      })
      setNewLegend(nextLegend)
    }
  }
  const handleToggleChange = (toggleIndex) => {
    const nextLegend = newLegend.map((item, index) => {
      if (toggleIndex === index) {
        return { ...item, checked: !item.checked }
      }
      return item
    })

    setNewLegend(nextLegend)
  }

  if (isPending) {
    return (
      <div className="line-chart-wrapper">
        <EELoading />
      </div>
    )
  }
  return (
    <div className="line-chart-wrapper" key={chartId}>
      <div className="line-chart-title">
        {selectOption ? (
          <EESelect
            value={dataParam.plotType}
            onChange={(newValue) => {
              setDataParam((prev) => Object.assign({ ...prev, plotType: newValue.value }))
              setSelectedOption(selectOption.find((item) => item.id === newValue.value))
            }}
            options={selectOption}
          />
        ) : null}
        <div className="chart-form-wrapper">
          {selectOption ? (
            <>
              <EESelect
                // value="1"
                value={dataParam.container}
                onChange={(newValue) => {
                  setDataParam((prev) => Object.assign({ ...prev, container: Number(newValue.value) }))
                }}
                options={containerOption}
              />
            </>
          ) : null}
          {selectOption ? (
            <>
              <EESelect
                value={dataParam.stack === null ? OPTION_NONE_SELECT_VALUE : dataParam.stack.toString()}
                onChange={(newValue) => {
                  const stackId = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
                  setDataParam((prev) => Object.assign({ ...prev, stack: stackId === null ? null : Number(stackId) }))
                }}
                options={stackOption}
              />
            </>
          ) : null}

          {nodeOption ? (
            <EESelect
              disabled={nodeDisabled}
              value={dataParam.node === null ? OPTION_NONE_SELECT_VALUE : dataParam.node}
              onChange={(newValue) => {
                const nodeId =
                  newValue.value === OPTION_NONE_SELECT_VALUE || dataParam.stack === null ? null : newValue.value
                setDataParam((prev) => Object.assign({ ...prev, node: nodeId }))
              }}
              options={nodeOption}
            />
          ) : null}
        </div>
      </div>
      <div className="chart-legend-wrapper">
        <div style={{ width: "100%" }}>
          {updateData && updateData.length > 0 ? (
            <EEPerformanceChart
              data={updateData}
              handleBrushed={function (timeRange: any): boolean {
                console.log("brushed")
                return true
              }}
              leftYAxisScale={leftPoint}
              rightYAxisScale={rightPoint}
              isBrush={false}
              divId={chartId}
              unit={unit}
            />
          ) : !noData ? (
            <EELoading />
          ) : errorMessage ? (
            <div className="chart-no-data">{errorMessage}</div>
          ) : (
            <div className="chart-no-data">No Data</div>
          )}
        </div>
        <div className="ee-legend-container">
          {updateData && updateData.length > 0 ? (
            <div className="ee-legend-button" onClick={handleDisplayAll}>
              Display All
            </div>
          ) : null}

          {newLegend &&
            newLegend.map((item, index) => (
              <EELegend
                title={item.title}
                color={item.color}
                isToggle
                checked={item.checked}
                onToggleChange={() => handleToggleChange(index)}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default PerformanceChartPanel
