import * as React from "react"
import Header from "../component/Header"
import "./privateLayout.scss"
import { useEffect } from "react"
import { useAuthContext } from "../context/AuthContext"

type Props = {
  children: React.ReactNode
}

const PrivateLayout: React.FC<Props> = ({ children }) => {
  const authContext = useAuthContext()
  const { userInfo, checkLogin } = authContext
  useEffect(() => {
    // if (!userInfo) {
    checkLogin()
    // }
  }, [children, checkLogin])
  if (!userInfo) return
  return (
    <div className="private-page-layout-wrapper">
      <Header />
      <div className="private-page-child-wrapper"> {children}</div>
    </div>
  )
}

export default PrivateLayout
