import * as React from "react"
import { useState } from "react"
import "./eeDateTimePicker.scss"
import dayjs from "dayjs"
import { CalendarIcon } from "../assets/images/calendar_month"

interface IEEDateTimePickerProps {
  label?: string
  onChange?: (date: string, time: string) => void
  value?: any
}

const EEDateTimePicker: React.FunctionComponent<IEEDateTimePickerProps> = ({ label, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [date, setDate] = useState(dayjs(value).format("YYYY-MM-DD"))
  const [time, setTime] = useState(dayjs(value).format("HH:mm:ss"))

  const handleDateChange = (e) => {
    setDate(e.target.value)
    // onChange && onChange(e.target.value, time)
  }

  const handleTimeChange = (e) => {
    setTime(e.target.value)
    // onChange && onChange(date, e.target.value)
  }

  const togglePopover = () => {
    setIsOpen(!isOpen)
  }

  const handleConfirm = () => {
    setIsOpen(false)
    onChange && onChange(date, time)
  }
  React.useEffect(() => {
    console.log("value", dayjs(value).format("HH:mm:ss"))
    setDate(dayjs(value).format("YYYY-MM-DD"))
    setTime(dayjs(value).format("HH:mm:ss"))
  }, [value])

  return (
    <div className="custom-datetime-picker">
      <div className="custom-datetime-input" onClick={togglePopover}>
        {label && <label className="custom-datetime-label">{label}</label>}
        <CalendarIcon />
        {value ? `${value}` : "Select Date & Time"}
      </div>
      {isOpen && (
        <div className="custom-datetime-popover">
          <div className="custom-datetime-popover-content">
            <input type="date" value={date} onChange={handleDateChange} className="custom-datetime-popover-input" />
            <input type="time" value={time} onChange={handleTimeChange} className="custom-datetime-popover-input" />
            <button className="custom-datetime-confirm-btn" onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EEDateTimePicker
