import * as React from "react"
import "./alertLocation.scss"
import _ from "lodash"
import {
  CellCount,
  SOX_CATEGORY_TEXT,
  SOX_DISPLAY_STATUS,
  SOX_DISPLAY_STATUS_TEXT,
  SOX_LEVEL,
  SOX_TYPE,
  SOX_TYPE_TEXT,
} from "../constant"
import StackCard from "./StackCard"
import { getSoxStatusText } from "../common/util"
import { Button } from "./Button"
import { EEDataGrid } from "./EEDataGrid"

interface IAlertLocationProps {
  data: any
  isDialog: boolean
  request: (param) => any
}
const AlertLocation: React.FunctionComponent<IAlertLocationProps> = ({ data, isDialog, request }) => {
  const alertColumnSample: any[] = [
    {
      field: "cycle",
      headerName: "Cycle",
      headerClassName: "datagrid-header",
      width: 70,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "level",
      headerName: "Level",
      headerAlign: "center",
      headerClassName: "datagrid-header",
      renderCell(params: any) {
        return (
          <div
            style={{
              color: params.level === SOX_TYPE.critical || params.level === SOX_TYPE.alert ? "red" : "orange",
            }}
          >
            {SOX_TYPE_TEXT[params.level]}
          </div>
        )
      },
      align: "center",
      // width: 200,
    },
    {
      field: "type",
      headerName: "Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "datagrid-header",

      // width: 200,
      valueGetter: (params: any) => `${SOX_CATEGORY_TEXT[params.type] || ""} `,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      headerClassName: "datagrid-header",
      // width: 200,
      valueGetter: (params: any) => getSoxStatusText(params.displayStatus) || "",
    },
  ]
  const {
    selected: { id, stack, node, displayStatus, details, level, cycle, container, actionName },
    history,
  } = data
  // const handleRollbackRequest = async () => {
  //   const paramData = { roleBack: true, alarmID: id }
  //   const response = await createRequest(paramData)
  //   if(response.statusCode===200){

  //   }
  //   rollbackRequest()
  // }

  // const handleRequest = async () => {
  //   const paramData = { roleBack: false, alarmID: id }
  //   const response = await createRequest(paramData)
  // }
  const isLeft = node.split("-")[1] === "L" ? true : false
  const createMessage = () => {
    let message = ""
    if (displayStatus === SOX_DISPLAY_STATUS.closed) {
      return (message = "Alarm Closed")
    }
    if (displayStatus === SOX_DISPLAY_STATUS.open) {
      if (level === SOX_LEVEL.alert) return (message = `${actionName} will be assigned to Operator within 48hours`)
      if (level === SOX_LEVEL.warning) return (message = `Choose Request button to assign ${actionName} to Operator `)
    }
    const statusText = getSoxStatusText(displayStatus)

    switch (statusText) {
      case SOX_DISPLAY_STATUS_TEXT.requested:
        message = `${actionName} has been assigned to Operator`
        break
      case SOX_DISPLAY_STATUS_TEXT.done:
        message = `${actionName} done`
        break
      case SOX_DISPLAY_STATUS_TEXT.operating:
        message = `${actionName} is being operating`
        break
      case SOX_DISPLAY_STATUS_TEXT.operatingRollback:
        message = `Rollback for ${actionName} is being operating`
        break
      case SOX_DISPLAY_STATUS_TEXT.rollbackRequest:
        message = `Rollback for ${actionName} has been assigned to Operator`
        break
    }
    return message
  }

  const createButton = () => {
    const rollbackButton = <Button label="CANCEL JOB" onClick={() => request({ rollback: true, alarmId: id })}></Button>

    const requestButton = <Button label="REQUEST" onClick={() => request({ alarmId: id })}></Button>
    const closeButton = <Button label="  CLOSE" onClick={() => request({ alarmId: id, close: true })}></Button>
    if (displayStatus === SOX_DISPLAY_STATUS.open) {
      if (level === SOX_LEVEL.alert) {
        return closeButton
      }
      if (level === SOX_LEVEL.warning) {
        return requestButton
      }
    }
    const statusText = getSoxStatusText(displayStatus)
    if (statusText === SOX_DISPLAY_STATUS_TEXT.requested || statusText === SOX_DISPLAY_STATUS_TEXT.operating) {
      return rollbackButton
    } else {
      return null
    }
  }
  return (
    <div className={isDialog ? "alert-location-dialog-wrapper" : "alert-location-wrapper"}>
      {isDialog ? null : (
        <div className="alert-info-wrapper">
          <div className="alert-category-wrapper">
            <div className="alert-category-text">{SOX_CATEGORY_TEXT[level]}</div>
            <div className="alert-cycle-text">Cycle {cycle}</div>
          </div>
          <div className="alert-type-text">{SOX_TYPE_TEXT[level]}</div>
          <div className="alert-message-text">{createMessage()}</div>
          {createButton()}
        </div>
      )}
      <div className="alert-location-container">
        <div className="alert-location-right-wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "240px",
              gap: "48px",
            }}
          >
            <div style={{ color: "var(--chart-title)" }}>{container}</div>
            <StackCard
              stackLabel={`Stack ${stack}`}
              stackId={stack - 1}
              stackStatus={0}
              onClick={function (): void {
                throw new Error("Function not implemented.")
              }}
              nodeId={Number(node.split("-")[0])}
              hover={false}
              style={{ backgroundColor: "var(--alert-location-stack-card)" }}
            />
          </div>
          <div className="alert-location-stack-node-connect-line"></div>
          <NodeGroup cell={details.cell} isNodeLeft={isLeft} nodeId={Number(node.split("-")[0])} cycle={cycle} />
        </div>
      </div>
      {!isDialog && history ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              backgroundColor: "var(--alert-location-history-header)",
              height: "65px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              border: "solid 1px #e5e7eb",
              color: "var(--chart-title)",
            }}
          >
            Alarm History
          </div>
          <EEDataGrid rows={history.data} columns={alertColumnSample} />
          {/* <DataGrid
            sx={{
              boxShadow: 0.5,
              border: 0.5,
              borderColor: "var(--data-grid-border)",
              color: "var(--main-page-title)",
              borderRadius: 0,
            }}
            disableColumnFilter
            disableColumnMenu
            getRowId={(row) => row.id}
            rows={history.data}
            columns={alertColumnSample}
            hideFooter
            rowHeight={47}
          /> */}
        </div>
      ) : null}
    </div>
  )
}

export default AlertLocation

const CellGroup: React.FunctionComponent<{ cell: [] }> = ({ cell }) => {
  return (
    <div className="cell-group-wrapper">
      {_.times(CellCount, (item) => {
        if (cell.indexOf(item + 1) > -1) {
          return (
            <div
              className="alert-location-cell"
              style={{ backgroundColor: "#E63535", cursor: "pointer" }}
              onClick={() => {
                window.open("https://ip-web-dev.clbcloud.com/testData/stack-card-sample.html")
              }}
              key={item}
            >
              {cell.indexOf(item + 1) > -1 ? "" : item + 1}
            </div>
          )
        }
        return (
          <div className="alert-location-cell" style={{ backgroundColor: "#d9d9d9" }} key={item}>
            {cell.indexOf(item + 1) > -1 ? "" : item + 1}
          </div>
        )
      })}
    </div>
  )
}

const NodeGroup: React.FunctionComponent<{ nodeId: number; isNodeLeft: boolean; cell: []; cycle: number }> = ({
  nodeId,
  cell,
}) => {
  const packetNumber = Math.ceil(nodeId / 8)
  return (
    <div className="node-group-wrapper">
      {_.times(8, (item) => {
        const position = (packetNumber - 1) * 8 + (item + 1)

        if (position === nodeId) {
          return (
            <div className="alert-node-wrapper">
              <div className="alert-node-text">Node {nodeId}</div>
              <CellGroup cell={cell} />
            </div>
          )
        }
        return <div className="alert-location-node">Node {position}</div>
      })}
    </div>
  )
}
