import React from "react"
import "./header.scss"

export const EEMenuItem = ({ children, onClick, className }) => {
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
}
