import * as React from "react"
import "./eeSelect.scss"

interface IEESellectProps {
  value: string | number
  // onSelectChange: (newValue) => void
  // label?: string
  // options: any
  border?: boolean
  // width?: string
  disabled?: boolean
  // isDarkmode?: boolean
  placeholder?: string
  options: any
  onChange?: (value) => void
}

const EESelect: React.FunctionComponent<IEESellectProps> = ({
  options,
  placeholder = "Select an option",
  onChange,
  border = true,
  disabled = false,
  value,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(value)
  const selectRef = React.useRef<HTMLDivElement>(null)

  // React.useEffect(() => {
  //   if (options && options.length > 0) {
  //     console.log("options", options)
  //     setSelectedValue(options[0])
  //   }
  // }, [])

  const handleToggle = () => {
    if (disabled) return
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (value) => {
    console.log("value", value)
    console.log(
      "options.find((item) => item.value === selectedValue)",
      options.find((item) => item.value === selectedValue)
    )

    setSelectedValue(value)
    setIsOpen(false)
    if (onChange) {
      onChange(value)
    }
  }

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="custom-select-container" ref={selectRef} id="ee-select">
      <div
        className="custom-select-trigger"
        onClick={handleToggle}
        style={{
          border: border ? "0.5px solid #ccc" : "none",
          borderBottom: "0.5px solid #ccc",
          backgroundColor: disabled ? "rgb(235,235,228,0.2)" : "",
          color: disabled ? "var(--select-disabled-text)" : "var(--main-page-title)",
        }}
      >
        {options ? options.find((item) => item.value === value)?.label : placeholder}
      </div>
      {isOpen && (
        <div className="custom-select-menu">
          {options.map((option, index) => (
            <div
              key={index}
              className="custom-select-option"
              onClick={() => handleOptionClick(option)}
              id={`ee-option-${index}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default EESelect
