import * as React from "react"
import { useEffect, useRef, useState } from "react"

interface ITempDemoProps {
  height: number
  isScroll: boolean
  intervalTime?: number
  handleScroll: () => void
}

const TempDemo: React.FunctionComponent<ITempDemoProps> = ({ height, isScroll, intervalTime, handleScroll }) => {
  const tempRef = useRef(null)
  const intervalRef = useRef(null)
  const [playCount, setPlayCount] = useState(0)
  const startInterval = () => {
    const container = tempRef.current
    const scrollStep = 1 // Adjust scroll step to control speed

    intervalRef.current = setInterval(() => {
      // Check if we've reached the bottom of the container
      if (container.scrollTop + container.clientHeight >= container.scrollHeight || isScroll === false) {
        handleScroll()
        clearInterval(intervalRef.current) // Stop scrolling when at the bottom
      } else {
        container.scrollTop += scrollStep // Scroll down by the step
      }
    }, intervalTime)
  }
  const stopInterval = () => {
    clearInterval(intervalRef.current)
  }
  useEffect(() => {
    setPlayCount(0)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])
  useEffect(() => {
    if (isScroll) {
      if (playCount === 0) {
        setPlayCount(1)
        setTimeout(() => {
          startInterval()
        }, 7000)
      } else {
        setPlayCount(1)
        startInterval()
      }
    }
    if (!isScroll) {
      setPlayCount(1)
      stopInterval()
    }
  }, [isScroll])
  useEffect(() => {
    if (isScroll) {
      if (playCount === 0) {
        setPlayCount(1)
        setTimeout(() => {
          startInterval()
        }, 7000)
      } else {
        setPlayCount(1)
        startInterval()
      }
    }
    if (!isScroll) {
      setPlayCount(1)
      stopInterval()
    }
  }, [intervalTime])
  return (
    <div style={{ width: "100%", height: height, overflowX: "hidden", position: "relative" }} ref={tempRef}>
      <iframe
        src="https://dev.clbcloud.com/d/Temperature-1717143530991/temperature?orgId=1&var-acid=12F0E002&var-stack=5&var-node=1&var-data_source=PROD&from=now-7d&to=now"
        width="100%"
        height="3350px"
        style={{ border: "none" }}
      ></iframe>
    </div>
  )
}

export default TempDemo
