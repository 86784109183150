import React from "react"

const InverterIcon = ({ title }) => {
  return (
    <svg width="79" height="44" viewBox="0 0 79 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="79" height="44" rx="2" fill="#1A1F26" fillOpacity="0.5" />
      <path
        d="M9.81982 27.7322V36C9.81982 37.1046 10.7153 38 11.8198 38H67.1805C68.2851 38 69.1805 37.1046 69.1805 36V27.7322"
        stroke="white"
        strokeOpacity="0.6"
      />
      <path
        d="M69.1802 17.2678L69.1802 8.99997C69.1802 7.8954 68.2847 6.99997 67.1802 6.99997L11.8195 6.99997C10.7149 6.99997 9.81952 7.8954 9.81952 8.99997L9.81952 17.2678"
        stroke="white"
        strokeOpacity="0.6"
      />
      <path d="M9.63916 20L6.17506 14L13.1033 14L9.63916 20Z" fill="white" fillOpacity="0.5" />
      <path d="M69.3608 25L72.8249 31L65.8967 31L69.3608 25Z" fill="white" fillOpacity="0.5" />
      <line x1="4" y1="22.5" x2="15" y2="22.5" stroke="white" strokeOpacity="0.6" />
      <line x1="4" y1="25.5" x2="15" y2="25.5" stroke="white" strokeOpacity="0.6" />
      <path
        d="M76 22C74.5778 24.6667 73.1556 24.6667 71.7333 22C70.3111 19.3333 68.8889 19.3333 67.4667 22C66.0444 24.6667 64.6222 24.6667 63.2 22"
        stroke="white"
        strokeOpacity="0.6"
      />
      <text x="23" y="27" fill="white" fontSize="12">
        {title}
      </text>
    </svg>
  )
}
export default InverterIcon
