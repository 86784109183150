import * as React from "react"
import "./toggleButton.scss"

interface IToogleButtonProps {
  onClick: (value) => void
  isOn: boolean
  toggleData?: any[]
  selectedToggle?: number
}

const ToogleButton: React.FunctionComponent<IToogleButtonProps> = ({
  onClick,
  isOn,
  toggleData = [],
  selectedToggle = 0,
}) => {
  if (toggleData.length > 0) {
    return (
      <div
        className="ee-toggle-button-wrapper "
        id="ee-tab"
        style={{ width: "", marginBottom: "14px", marginTop: "8px" }}
      >
        {toggleData.map((item, index) => (
          <div
            className={selectedToggle === item.value ? "toggle-left-selected" : "toggle-left-not-selected"}
            id="ee-charge"
            onClick={() => onClick(item.value)}
            style={{
              borderRadius: index === 0 ? "5px 0 0 5px" : index === toggleData.length - 1 ? "0 5px 5px 0" : "0 0 0 0",
              fontSize: "14px",
            }}
          >
            {item.label}
          </div>
        ))}
      </div>
    )
  }
  return (
    <div className="ee-toggle-button-wrapper " id="ee-tab">
      <div
        className={isOn ? "toggle-left-selected" : "toggle-left-not-selected"}
        id="ee-charge"
        onClick={() => onClick(true)}
      >
        Charge
      </div>
      <div
        className={!isOn ? "toggle-right-selected" : "toggle-right-not-selected"}
        id="ee-discharge"
        onClick={() => onClick(false)}
      >
        Discharge
      </div>
    </div>
  )
}

export default ToogleButton
