// LineChart.js
import React, { useRef, useEffect, useState } from "react"
import * as d3 from "d3"
import { EETooltip } from "./EETooltip"
import dayjs from "dayjs"
type EEPerformanceChartProps = {
  data: any
  handleBrushed: (timeRange) => boolean
  leftYAxisScale: number[]
  rightYAxisScale: number[]
  isBrush: boolean
  unit: string[]
  divId: string | number
  isLinear?: boolean
  customHeight?: number
}
const EEPerformanceChart: React.FunctionComponent<EEPerformanceChartProps> = ({
  data,
  handleBrushed,
  leftYAxisScale,
  rightYAxisScale,
  isBrush = false,
  unit,
  divId,
  isLinear,
  customHeight,
}) => {
  const chartMiniRef = useRef(null)
  const tooltipRef = useRef(null)

  const margin = { top: 20, right: 30, bottom: 30, left: 40 }
  const [width, setWidth] = useState(customHeight ? 1000 : window.innerWidth - margin.left - margin.right - 200)
  const height = customHeight ? customHeight - margin.top - margin.bottom : 500 - margin.top - margin.bottom
  const boundsWidth = width - margin.right - margin.left
  const boundsHeight = height - margin.top - margin.bottom
  const [interactionData, setInteractionData] = useState<any>()

  const handleResize = () => {
    setWidth(customHeight ? 1000 : window.innerWidth - margin.left - margin.right - 200)
  }

  const xAxisTransform = customHeight ? 300 : 400
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    // Define scales
    if (data && data[0]) {
      // Define the line
      const xScale = d3
        .scaleLinear()
        .domain([0, data[0].values[data[0].values.length - 1].cycle])
        .range([40, boundsWidth - 60])

      // .nice()
      const yScaleLeft = d3.scaleLinear().domain([leftYAxisScale[0], leftYAxisScale[1]]).range([boundsHeight, 10])
      const yScaleRight = d3.scaleLinear().domain([rightYAxisScale[0], rightYAxisScale[1]]).range([boundsHeight, 10])

      const svg = d3.select(chartMiniRef.current)

      const xAxisGenerator = d3.axisBottom(xScale)
      if (data[0].values[data[0].values.length - 1].cycle < 10) {
        xAxisGenerator.ticks(data[0].values[data[0].values.length - 1].cycle)
      }

      const yAxisGeneratorLeft = d3.axisLeft(yScaleLeft).tickFormat((d) => `${d}${unit[0]}`)
      const yAxisGeneratorRight = d3.axisRight(yScaleRight).tickFormat((d) => `${d}${unit[1]}`)

      // const xAxisGrid = d3
      //   .axisBottom(xScale)
      //   .tickSize(-boundsHeight + 20)
      //   .tickFormat("")
      const yAxisGrid = d3
        .axisLeft(yScaleLeft)
        .tickSize(-boundsWidth + 100)
        .tickFormat("")
      svg.selectAll("*").remove()
      const getClosestPoint = (cursorPixelPosition: number) => {
        const x = xScale.invert(cursorPixelPosition[0] + 10)
        // const y = yScaleLeft.invert(cursorPixelPosition[1])
        let closest: any = {}
        const resultArray: any = []
        // const line = d3
        //   .line()
        //   .x((d) => {
        //     return xScale(d.cycle)
        //   })
        //   .y((d) => {
        //     return yScaleLeft(d.valueLeft)
        //   })
        // .context(context)
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          for (const point of item.values) {
            if (point.cycle === Math.floor(x)) {
              closest = { value: point, color: item.color, name: item.name }
            }
          }
          if (Object.keys(closest).length > 0) {
            resultArray.push(closest)
          }
        })

        return resultArray
      }
      svg
        .attr("width", boundsWidth)
        .attr("height", height + margin.top + margin.bottom)
        .attr("transform", `translate(${[margin.left, margin.top].join(",")})`)

      const brushended = (event) => {
        const { selection } = event
        // alert("brush end?")
        if (!selection) return
        handleBrushed({ start: dayjs(xScale.invert(selection[0])), end: dayjs(xScale.invert(selection[1])) })
      }
      if (isBrush) {
        const brush = d3
          .brushX()
          .extent([
            [0, 0],
            [boundsWidth, height - margin.bottom + 0.5],
          ])
          // .on("brush", brushed)
          .on("end", brushended)
        svg.append("g").attr("class", "brush").call(brush)
      }

      svg
        .append("g")
        .attr("class", "_x_axis")
        .attr("transform", "translate(0," + xAxisTransform + ")")
        .attr("opacity", 0.3)
        .call(xAxisGenerator)
      svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", boundsWidth / 2 + 10)
        .attr("y", boundsHeight + 40)
        .style("fill", "var(--chart-axis-line)")
        .text("Cycle")

      const yAxisLeft = svg.append("g").attr("transform", `translate(${40},0)`).call(yAxisGeneratorLeft)
      const yAxisRight = svg
        .append("g")
        .attr("transform", `translate(${width - 130},0)`)
        .call(yAxisGeneratorRight)

      yAxisLeft.selectAll("path,line").remove()
      yAxisRight.selectAll("path,line").remove()
      svg
        .append("g")
        .attr("transform", `translate(${40},0)`)
        .attr("class", "y-axis-grid")
        .attr("stroke-width", 0.5)
        .attr("opacity", 0.3)
        .call(yAxisGrid)
      const contextTooltip = d3
        .select(tooltipRef.current)
        .attr("width", boundsWidth)
        .attr("height", height + margin.top + margin.bottom)
        .attr("transform", `translate(${[margin.left, margin.top].join(",")})`)
        .style("margin-left", 0 + "px")
        .style("margin-top", margin.top + "px")
        .node()
        .getContext("2d")
      const svgGroup = svg.append("g")
      // Draw line
      var transpRect = svgGroup
        .append("rect")
        .attr("width", boundsWidth)
        .attr("height", boundsHeight)
        .attr("opacity", 0)

      var verticalLine = svgGroup
        .append("line")
        .attr("opacity", 0)
        .attr("y1", 10)
        .attr("y2", boundsHeight)
        .attr("stroke", "var(--chart-axis-line)")
        .attr("stroke-width", 0.5)
        .attr("pointer-events", "none")

      var horizontalLine = svgGroup
        .append("line")
        .attr("opacity", 0)
        .attr("x1", 20)
        .attr("x2", boundsWidth - 40)
        .attr("stroke", "var(--chart-axis-line)")
        .attr("stroke-width", 0.5)
        .attr("pointer-events", "none")

      transpRect
        .on("mousemove", function (e) {
          const mouse = d3.pointer(e)
          verticalLine.attr("x1", mouse[0]).attr("x2", mouse[0]).attr("opacity", 1)
          horizontalLine.attr("y1", mouse[1]).attr("y2", mouse[1]).attr("opacity", 1)
          setInteractionData(null)
          const result: any = getClosestPoint(mouse)
          contextTooltip.reset()

          if (result && result.length > 0) {
            const arrayTooltip: any = []
            result.map((d) => {
              arrayTooltip.push({
                xPos: mouse[0] + 50,
                yPos: mouse[1] + 20,
                x: `Cycle ${d.value.cycle}`,
                color: d.color,
                y: `${d.value.valueLeft}% \n ${d.value.valueRight}Ah`,
                name: d.name,
              })
              contextTooltip.beginPath()
              contextTooltip.arc(xScale(d.value.cycle), yScaleLeft(d.value.value), 5, 0, 2 * Math.PI)
              contextTooltip.fillStyle = d.color
              contextTooltip.fill()
              return null
            })
            setInteractionData(arrayTooltip)
          }
        })
        .on("mouseleave", function () {
          verticalLine.attr("opacity", 0)
          horizontalLine.attr("opacity", 0)
          setInteractionData(null)
          contextTooltip.reset()
        })
      const line = d3
        .line()
        .x((d) => xScale(d.cycle))
        .y((d) => yScaleLeft(d.valueLeft))
      data.map((item, index) => {
        if (isLinear) {
          svgGroup
            .append("path")
            .datum(item.values)
            .attr("class", "my_line")
            .attr("d", line)
            .attr("fill", "none")
            .attr("stroke", item.color)
            .attr("stroke-width", 0.5)
        } else {
          var points = svgGroup.append("g").attr("clip-path", "url(#clip)")

          points
            .selectAll("dot")
            .data(item.values)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
              if (d.valueLeft === null) {
                return
              }

              return xScale(d.cycle)
            })
            .attr("cy", function (d) {
              if (d.valueLeft === null) {
                return
              }
              return yScaleLeft(d.valueLeft)
            })
            .attr("r", function (d) {
              if (d.valueLeft === null) {
                return 0
              }
              return 3
            })
            .style("fill", item.color)
        }
        return null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width])

  return (
    <div style={{ position: "relative" }} key={divId}>
      <EETooltip interactionData={interactionData} isMulti id={`performance-chart-tooltip-${divId}`} />
      <canvas ref={tooltipRef} style={{ position: "absolute", top: 0, left: 0 }}></canvas>
      <svg
        id={`performance-chart-${divId}`}
        ref={chartMiniRef}
        style={{ position: "absolute", top: 0, left: 0, color: "var(--chart-axis-line)", maxWidth: "fit-content" }}
      ></svg>
    </div>
  )
}

export default EEPerformanceChart
