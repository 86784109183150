import axios from "axios"
import { DevOptions } from "../DevOptions"
// import { DevOptions } from '../DevOptions'

// const baseURL = "https://prod.clbcloud.com/api/"
// const baseURL = "https://downloader.clbcloud.com/"
export const baseURL = DevOptions.serverAddress
// export const baseURL = "https://ip-web-api-dev.clbcloud.com"

// AxiosApi.defaults.headers.common[
//   CookieName.GRAFANA_COOKIE
// ] = `grafana_session=${sessionInfo}; grafana_session_expiry=${sessionExpiry}`
// const sessionInfo = 'e95faa08aa492790364e39906b4707fd'
// const sessionExpiry = '1698210875'
// const headers = {
//   'Content-Type': 'application/json;charset=UTF-8',
//   Cookie: `grafana_session=${sessionInfo}; grafana_session_expiry=${sessionExpiry}`,
// }
export default axios.create({
  baseURL: baseURL,
})
