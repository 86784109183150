import * as React from "react"
import "./profile.scss"
import { useAuthContext } from "../../context/AuthContext"
import {
  OPTION_NONE_SELECT_VALUE,
  PeriodOptions,
  PeriodOptionsText,
  PlotTypeText,
  USER_ROLE_TEXT,
} from "../../constant"
import EELoading from "../../component/Loading"
import EESelect from "../../component/EESelect"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "../../context/AppContext"
import { createNodeOption, createStackOption } from "../../common/util"
import { Button } from "../../component/Button"
import EETextField from "../../component/EETextField"

interface IProfileProps {}

const Profile: React.FunctionComponent<IProfileProps> = (props) => {
  const authContext = useAuthContext()
  const { userInfo, checkLogin } = authContext
  const [opsPlotType, setOpsPlotType] = React.useState<any>()
  const [opsContainer, setOpsContainer] = React.useState<any>("1")
  const [opsStack, setOpsStack] = React.useState<any>(null)
  const [opsNode, setOpsNode] = React.useState<any>("1")
  const [opsStackOption, setOpsStackOption] = React.useState<any>()
  const [perfStackOption, setPerfStackOption] = React.useState<any>()
  const [opsNodeOption, setNodeOption] = React.useState<any>()
  const [perNodeOption, setPerfNodeOption] = React.useState<any>()
  const [opsDataParam, setOpsataParam] = React.useState<any>([])
  const [perforPlotType, setPerformancePlotType] = React.useState<any>("")
  const [perforContainer, setPerformanceContainer] = React.useState<any>("1")
  const [periodSelect, setPeriodSelect] = React.useState("1h")
  const [perfDataParam, setPerfDataParam] = React.useState<any>([])
  const [mobile, setMobile] = React.useState<any>()
  const [authCode, setAuthCode] = React.useState<any>()
  const [perforStack, setPerformanceStack] = React.useState<any>(1)
  const [perforNode, setPerformanceNode] = React.useState<any>("1-L")
  const [codeSent, setCodeSent] = React.useState<boolean>(false)
  const [mobileInputError, setMobileInputError] = React.useState<string>("")
  const [authCodeInputError, setAuthCodeInputError] = React.useState<string>("")
  const [containerOpsOption, setContainerOpsOption] = React.useState([])
  const [containerPerfOption, setContainerPerfOption] = React.useState([])

  const appContext = useAppContext()
  const {
    getOperationSelectOption,
    getChartSetting,
    updateChartSetting,
    deleteChartSetting,
    getPerformanceSelectOption,
    getContainerOptions,
  } = appContext
  const [opsSelectOption, setOpsSelectOption] = React.useState<any>()
  const [perfSelectOption, setPerfSelectOption] = React.useState<any>()
  const createContainerOption = async () => {
    const containerOptionsResponse = await getContainerOptions()
    if (containerOptionsResponse.statusCode === 200) {
      setContainerPerfOption(containerOptionsResponse.data)
      setContainerOpsOption(containerOptionsResponse.data)
      setOpsContainer(containerOptionsResponse.data[0].value)
      setPerformanceContainer(containerOptionsResponse.data[0].value)
    }
  }
  React.useEffect(() => {
    createContainerOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data } = useQuery({
    queryKey: ["operationSelect"],
    queryFn: async () => {
      const data = await getOperationSelectOption()
      return data
    },
  })
  const { data: performanceSelectData } = useQuery({
    queryKey: ["performanceSelect"],
    queryFn: async () => {
      const data = await getPerformanceSelectOption()
      return data
    },
  })
  const getChartSettingData = async () => {
    const data = await getChartSetting()
    if (data) {
      setPerfDataParam(data.data.performance ? data.data.performance : [])
      setOpsataParam(data.data.operation ? data.data.operation : [])
    }
  }
  const { data: chartSettingData, refetch } = useQuery({
    queryKey: ["chartSetting"],
    queryFn: getChartSettingData,
    enabled: false,
    refetchOnWindowFocus: false,
  })
  React.useEffect(() => {
    if (data) {
      const newOption = data.data.map((item) => {
        return {
          value: item.id,
          ...item,
        }
      })
      // console.log("new operation option checkckckckc", newOption)
      setOpsSelectOption(newOption)
      setOpsPlotType(newOption[0])
    }
  }, [data])
  React.useEffect(() => {
    if (performanceSelectData) {
      const newOption = performanceSelectData.data.map((item) => {
        return {
          value: item.id,
          ...item,
        }
      })
      setPerfSelectOption(newOption)
      setPerformancePlotType(newOption[0].value)
    }
  }, [performanceSelectData])
  React.useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (chartSettingData) {
      setOpsataParam(chartSettingData.data.operation)
      setPerfDataParam(chartSettingData.data.performance)
    }
  }, [chartSettingData])
  React.useEffect(() => {
    if (opsPlotType) {
      // console.log(opsPlotType)
      const typeO = opsSelectOption.find((item) => item.value === opsPlotType.value)

      const sOption = !typeO.level.stack.activate ? null : createStackOption(typeO)
      const nOption = !typeO.level.node.activate ? null : createNodeOption(typeO)
      setOpsStackOption(sOption)
      setNodeOption(nOption)
      setOpsStack(sOption ? sOption[0].value : null)
      setOpsNode(nOption ? nOption[0].value : null)
      // if (!opsPlotType.level.stack.activate) {
      //   setOpsStack(null)
      // }
      // if (!opsPlotType.level.node.activate) {
      //   setOpsNode(null)
      // }
      // if (sOption) {
      //   setOpsStack(sOption[0].value)
      // }
      // if (nOption) {
      //   setOpsNode(nOption[0].value)
      // }
    }
  }, [opsPlotType])
  // useEffect(() => {
  //   if (selectedOption) {
  //     const sOption = !selectedOption.level.stack.activate ? null : createStackOption(selectedOption)
  //     const nOption = !selectedOption.level.node.activate ? null : createNodeOption(selectedOption)
  //     setStackOption(sOption)
  //     setNodeOption(nOption)
  //     if (nOption === null) {
  //       setDataParam((prev) => Object.assign({ ...prev, node: null }))
  //     }
  //     if (sOption === null) {
  //       setDataParam((prev) => Object.assign({ ...prev, stack: null }))
  //     }
  //   }
  // }, [selectedOption])
  React.useEffect(() => {
    if (perforPlotType) {
      const typeP = perfSelectOption.find((item) => item.value === perforPlotType)

      const sOption = !typeP.level.stack.activate ? null : createStackOption(typeP)
      const nOption = !typeP.level.node.activate ? null : createNodeOption(typeP)
      setPerfStackOption(sOption)
      setPerfNodeOption(nOption)
      if (!typeP.level.stack.activate) {
        setPerformanceStack(null)
      }
      if (!typeP.level.node.activate) {
        setPerformanceNode(null)
      }
      if (nOption) {
        setPerformanceNode(nOption[0].value)
      }
      if (sOption) {
        setPerformanceStack(sOption[0].value)
      }
    }
  }, [perforPlotType])
  const handleOpsAdd = async () => {
    const typeO = opsSelectOption.find((item) => item.value === opsPlotType.value)

    const newParam = {
      plotCategoryId: typeO.id,
      container: opsContainer,
      stack: opsStack === OPTION_NONE_SELECT_VALUE ? null : Number(opsStack),
      node: opsNode === OPTION_NONE_SELECT_VALUE ? null : opsNode,
      timeRange: periodSelect,
    }
    setOpsataParam([...opsDataParam, newParam])
    const response = await updateChartSetting(newParam)
    if (response) {
      refetch()
    }
  }

  const handlePerfAdd = async () => {
    const typeP = perfSelectOption.find((item) => item.value === perforPlotType)

    const newParam = {
      plotCategoryId: typeP.id,
      container: perforContainer,
      stack: perforStack === OPTION_NONE_SELECT_VALUE ? null : Number(perforStack),
      node: perforNode === OPTION_NONE_SELECT_VALUE ? null : perforNode,
    }
    setPerfDataParam([...perfDataParam, newParam])
    const response = await updateChartSetting(newParam)
    if (response) {
      refetch()
    }
  }
  const handlePerfRemove = async (id) => {
    setPerfDataParam(opsDataParam.slice(0, opsDataParam.length - 1))
    const response = await deleteChartSetting(id)
    if (response) {
      refetch()
    }
  }
  const handleAuthCodeRequest = async () => {
    setMobileInputError("")
    const response = await authContext.requestAuthCode(mobile)
    if (response && response.statusCode === 200) {
      setCodeSent(true)
    } else if (response && response.statusCode === 500) {
      setMobileInputError("Internal Server Error occured")
      setCodeSent(false)
    } else {
      setMobileInputError(response.data.msg)
      setCodeSent(false)
    }
  }
  const handleAuthCodeVerify = async () => {
    setAuthCodeInputError("")
    const response = await authContext.verifyAuthCode(mobile, Number(authCode))
    if (response && response.statusCode === 200) {
      setCodeSent(false)
      checkLogin()
    } else if (response && response.statusCode === 500) {
      setAuthCodeInputError("Internal Server Error occured")
    } else {
      setAuthCodeInputError(response.data.msg)
    }
  }
  React.useEffect(() => {
    // checkLogin()
  }, [userInfo])
  if (!userInfo) {
    return <EELoading />
  }
  return (
    <div className="profile-container">
      <div className="profile-section">
        <div className="profile-avatar">
          {userInfo?.firstName.charAt(0) + userInfo?.firstName.charAt(userInfo?.firstName.length - 1)}
        </div>
        <div className="profile-section-right">
          <div>Email : {userInfo.email}</div>
          <div className="profile-mobile">
            {userInfo.mobile ? (
              <div>
                Mobile : <span style={{ color: "var(--login-input-label)" }}>{userInfo.mobile}</span>
              </div>
            ) : (
              <>
                <div
                  style={{
                    // backgroundColor: "var(--mui-dialog-paper)",
                    color: "#fff",
                  }}
                >
                  <EETextField
                    id="my-input"
                    onChange={(e) => setMobile(e.target.value)}
                    style={{
                      borderBottom: "0.5px solid var(  --login-input-border)",
                    }}
                    error={mobileInputError !== "" ? false : true}
                    value={mobile}
                    placeholder="Mobile Number"
                    label="Mobile Number"
                    helperText={mobileInputError}
                  />
                </div>
                {codeSent ? (
                  <Button
                    onClick={handleAuthCodeRequest}
                    style={{ height: "50px", width: "130px" }}
                    label="Request Code again"
                  ></Button>
                ) : (
                  <Button
                    onClick={handleAuthCodeRequest}
                    label="Request code"
                    style={{ height: "50px", width: "130px" }}
                  ></Button>
                )}
              </>
            )}
            {codeSent ? (
              <>
                <div
                  style={{
                    // backgroundColor: "var(--mui-dialog-paper)",
                    color: "#fff",
                    height: "80px",
                  }}
                >
                  <EETextField
                    id="my-input"
                    onChange={(e) => setAuthCode(e.target.value)}
                    style={{
                      borderBottom: "0.5px solid var(  --login-input-border)",
                    }}
                    error={authCodeInputError !== "" ? false : true}
                    value={authCode}
                    placeholder="6 digit Auth Code"
                    label="Auth Code"
                    helperText={authCodeInputError}
                  />
                </div>
                <Button
                  onClick={handleAuthCodeVerify}
                  label="Verify Code"
                  style={{ height: "50px", width: "130px" }}
                ></Button>
              </>
            ) : null}
          </div>
          <div>Role : {USER_ROLE_TEXT[userInfo.roleId]}</div>
        </div>
      </div>
      <div className="profile-chart-setting-section">
        <div>Operation Chart Setting</div>
        <div className="profile-chart-form">
          <div className="profile-setting-table-cell">
            {opsSelectOption ? (
              <EESelect
                value={opsPlotType.value}
                onChange={function (newValue: any): void {
                  setOpsPlotType(opsSelectOption.find((item) => item.value === newValue.value))
                }}
                options={opsSelectOption}
              />
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            {containerOpsOption ? (
              <>
                <EESelect
                  // value="1"
                  value={opsContainer}
                  onChange={(newValue) => {
                    setOpsContainer(newValue.value)
                  }}
                  options={containerOpsOption}
                />
              </>
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            {opsStackOption ? (
              <EESelect
                value={opsStack === null ? OPTION_NONE_SELECT_VALUE : opsStack}
                onChange={function (newValue: any): void {
                  const stackId = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
                  setOpsStack(stackId)
                }}
                options={opsStackOption}
              />
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            {
              opsNodeOption ? (
                <EESelect
                  value={opsNode === null ? OPTION_NONE_SELECT_VALUE : opsNode}
                  onChange={function (newValue: any): void {
                    const nodeId =
                      newValue.value === OPTION_NONE_SELECT_VALUE || opsStack === null ? null : newValue.value
                    setOpsNode(nodeId)
                  }}
                  disabled={opsStack === null}
                  options={opsNodeOption}
                />
              ) : null
              // <EESelect
              //   value={opsNode}
              //   onSelectChange={function (newValue: any): void {
              //     setOpsNode(newValue)
              //   }}
              //   options={[{ label: "Node", value: "1-L" }]}
              //   disabled={opsStack === null}
              // />
            }
          </div>
          <div className="profile-setting-table-cell">
            <EESelect
              value={periodSelect}
              onChange={(newValue) => setPeriodSelect(newValue.value)}
              options={PeriodOptions}
            />
          </div>
          <div className="profile-setting-table-cell">
            <Button
              onClick={handleOpsAdd}
              style={{ width: "120px", fontSize: "0.9rem !important" }}
              label="ADD"
            ></Button>
          </div>
        </div>
        <div className="profile-setting-table">
          <div className="profile-setting-table-row">
            <div className="profile-setting-table-cell">Plot Type</div>
            <div className="profile-setting-table-cell">Container</div>
            <div className="profile-setting-table-cell">Stack</div>
            <div className="profile-setting-table-cell">Node</div>
            <div className="profile-setting-table-cell">Period</div>
            <div className="profile-setting-table-cell">Remove</div>
          </div>
          {opsDataParam &&
            opsDataParam.map((item: any, index: number) => {
              return (
                <div className="profile-setting-table-row">
                  <div className="profile-setting-table-cell">{PlotTypeText[item.plotCategoryId]}</div>
                  <div className="profile-setting-table-cell">{item.container}</div>
                  <div className="profile-setting-table-cell">{item.stack}</div>
                  <div className="profile-setting-table-cell">{item.node}</div>
                  <div className="profile-setting-table-cell">{PeriodOptionsText[item.timeRange]}</div>
                  <div className="profile-setting-table-cell">
                    <Button
                      onClick={() => handlePerfRemove(item.id)}
                      label="REMOVE"
                      style={{ height: "35px", fontSize: "0.7rem !important", backgroundColor: "var(--error-color)" }}
                    ></Button>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      {/* <div className="profile-chart-setting-section">
        <div>Performance Chart Setting</div>
        <div className="profile-chart-form">
          <div className="profile-setting-table-cell">
            {perfSelectOption ? (
              <EESelect
                value={perforPlotType}
                onChange={function (newValue: any): void {
                  setPerformancePlotType(perfSelectOption.find((item) => item.id === newValue.value))
                }}
                options={perfSelectOption}
              />
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            {containerPerfOption ? (
              <EESelect
                value={perforContainer}
                onChange={function (newValue: any): void {
                  setPerformanceContainer(newValue.value)
                }}
                options={containerPerfOption}
              />
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            {perfStackOption ? (
              <EESelect
                value={perforStack === null ? OPTION_NONE_SELECT_VALUE : perforStack}
                onChange={function (newValue: any): void {
                  const stackId = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
                  setPerformanceStack(stackId)
                }}
                options={perfStackOption}
              />
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            {perNodeOption ? (
              <EESelect
                value={perforNode === null ? OPTION_NONE_SELECT_VALUE : perforNode}
                onChange={function (newValue: any): void {
                  const nodeId =
                    newValue.value === OPTION_NONE_SELECT_VALUE || perforStack === null ? null : newValue.value
                  setPerformanceNode(nodeId)
                }}
                disabled={perforStack === null}
                options={perNodeOption}
              />
            ) : null}
          </div>
          <div className="profile-setting-table-cell">
            <Button
              onClick={handlePerfAdd}
              label="ADD"
              style={{ width: "120px", fontSize: "0.9rem !important" }}
            ></Button>
          </div>
        </div>
        <div className="profile-setting-table">
          <div className="profile-setting-table-row">
            <div className="profile-setting-table-cell">Plot Type</div>
            <div className="profile-setting-table-cell">Container</div>
            <div className="profile-setting-table-cell">Stack</div>
            <div className="profile-setting-table-cell">Node</div>
            <div className="profile-setting-table-cell">Remove</div>
          </div>
          {perfDataParam &&
            perfDataParam.map((item: any, index: number) => {
              return (
                <div className="profile-setting-table-row">
                  <div className="profile-setting-table-cell">{PlotTypeText[item.plotCategoryId]}</div>
                  <div className="profile-setting-table-cell">{item.container}</div>
                  <div className="profile-setting-table-cell">{item.stack}</div>
                  <div className="profile-setting-table-cell">{item.node}</div>
                  <div className="profile-setting-table-cell">
                    <Button
                      onClick={() => handlePerfRemove(item.id)}
                      label="REMOVE"
                      style={{ height: "35px", fontSize: "0.7rem !important", backgroundColor: "var(--error-color)" }}
                    ></Button>
                  </div>
                </div>
              )
            })}
        </div>
      </div> */}
    </div>
  )
}

export default Profile
