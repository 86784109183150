import axios from "axios"
import * as React from "react"
import ServerURLs from "../routes/serverUrls"
import dayjs from "dayjs"
import { INERTIA_LOCATION } from "../constant"
import EELoading from "./Loading"
import "./weatherSection.scss"
import { useEffect, useState } from "react"
import { HumidityIcon } from "../assets/HumidityIcon"
import { WindIcon } from "../assets/WindIcon"

interface IWeatherSectionProps {}
// "currentobservation":{
//     "id":"KABI",
//     "name":"Abilene, Abilene Regional Airport",
//     "elev":"1788",
//     "latitude":"32.41",
//     "longitude":"-99.68",
//     "Date":"29 May 00:52 am CDT",
//     "Temp":"70",     //temperature
//     "Dewp":"60",    //dew point
//     "Relh":"71", //humidity
//     "Winds":"22", //wind speed
//     "Windd":"90", //wind direction
//     "Gust":"41", //wind gust
//     "Weather":"Fair and Breezy", //weather
//     "Weatherimage":"https://forecast.weather.gov/newimages/medium/nwind_sct.png",
//     "Visibility":"10.00", //visibility
//     "Altimeter":"1015.5",
//     "SLP":"30.06",
//     "timezone":"CDT",
//     "state":"TX",
//     "WindChill":"NA"
// }
const WeatherSection: React.FunctionComponent<IWeatherSectionProps> = (props) => {
  const weatherImage = "https://forecast.weather.gov/newimages/medium/"
  const [weatherData, setWeatherData] = React.useState<any>()
  const fetchData = async () => {
    // fetch data here
    const response = await axios.get(ServerURLs.WeatherApi)
    if (response.status === 200) {
      setWeatherData(response.data.currentobservation)
    }
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  const [time, setTime] = useState(dayjs().format("hh:mm"))

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined

    interval = setInterval(() => {
      setTime(dayjs().format("hh:mm"))
    }, 60000)

    return () => clearInterval(interval)
  }, [time])
  if (!weatherData) {
    return <EELoading />
  }
  return (
    <div className="plant-weather-section-wrapper">
      <div className="plant-weather-location-section">
        {time}
        <span>{INERTIA_LOCATION}</span>
      </div>
      <div className="plant-weather-wrapper">
        <div className="plant-weather-temperature-wrapper">
          <div className="plant-weather-temperature">
            <div className="plant-weather-image">
              <img src={`${weatherImage}${weatherData.Weatherimage}`} alt="" />
            </div>
            <div className="plant-weather-temperature-value ">{weatherData.Temp}°F</div>
          </div>
          <div className="plant-weather-description">{weatherData.Weather}</div>
        </div>
        <div className="vertical-divider"></div>
        <div className="plant-weather-right-section-wrapper">
          <div className="plant-weather-right-section">
            <div className="plant-weather-right-section-title-wrapper">
              <HumidityIcon width="20" bgFill={"var(--text-default-color)"} textFill={"var(--tooltip-background)"} />
              <span>Humidity</span>
            </div>
            <div className="plant-weather-right-section-value">{weatherData.Relh}%</div>
          </div>
          <div className="horizontal-divider"></div>
          <div className="plant-weather-right-section">
            <div className="plant-weather-right-section-title-wrapper">
              <WindIcon width="20" bgFill={"var(--text-default-color)"} />
              <span>Wind Speed</span>
            </div>
            <div className="plant-weather-right-section-value">{`${weatherData.Winds}  ${
              weatherData.Gust !== "NA" ? " G " + weatherData.Gust : ""
            } mph`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeatherSection
