import _ from "lodash"
export const Battery = ({ fill, stokeColor, width, isWarning = false, cell = [], type = "", isDark = false }) => {
  return (
    <svg width="145" height="50" viewBox="0 0 145 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="140"
        height="45"
        rx="5"
        fill={isDark ? "#121212" : "white"}
        stroke={stokeColor}
        strokeWidth="2"
      />
      {type ? (
        <text x="6" y="16" fill={type === "Warning" ? "#E2A907" : "#E63535"} style={{ fontSize: "0.8rem" }}>
          {type}
        </text>
      ) : null}
      {isWarning ? (
        <>
          {_.times(10, (item) => {
            return (
              <rect
                x={7 + item * 10}
                y="20"
                width="8"
                height="21"
                fill={cell.indexOf(item + 1) > -1 ? (type === "Warning" ? "#E2A907" : "#E63535") : "#D9D9D9"}
                id="battery"
              />
            )
          })}
        </>
      ) : (
        <rect x="7" y="20" width={width} height="21" fill={fill} id="battery" />
      )}
    </svg>
  )
}
