import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Button } from "../component/Button"
import DotAnimation from "./\bDotAnimation"
import EEFloatingButton from "../component/EEFloatingButton"
import { PlayIcon } from "../assets/images/play_arrow"
import OperationDemo from "./OperationDemo"
import { FastRewindIcon } from "../assets/images/fast_rewind"
import { FastForwardIcon } from "../assets/images/fast_forward"
import { PauseIcon } from "../assets/images/pauseIcon"
import { PauseNoCircleIcon } from "../assets/images/pause"
import ZoominDemo from "./ZoomInDemo"
import OnedayDemo from "./OnedayDemo"
import TempDemo from "./TempDemo"
import SosDemo from "./SosDemo"

interface ITestScreenProps {}

const TestScreen: React.FunctionComponent<ITestScreenProps> = (props) => {
  const containerRef = useRef(null)
  const tempRef = useRef(null)
  const zoomRef = useRef(null)
  const sosRef = useRef(null)
  const [landingOpen, setLandingOpen] = useState(true)
  const [systemControlOpen, setSystemControlOpen] = useState(false)

  const [scVoltage, setScVoltage] = useState(false)
  const [scSoc, setScSoc] = useState(false)
  const [tempOpen, setTempOpen] = useState(false)
  const [sosOpen, setSosOpen] = useState(false)
  const [zoomOpen, setZoomOpen] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)
  const [intervalTime, setIntervalTime] = useState(15)
  const height = window.innerHeight - 120
  useEffect(() => {
    setAutoScroll(true)
  }, [tempOpen, sosOpen, zoomOpen, landingOpen, systemControlOpen])

  return (
    <div style={{ display: "block", width: "100%" }}>
      <div
        style={{
          display: "flex",
          gap: "24px",
          flexDirection: "row",
          justifyContent: "center",
          padding: "24px",
        }}
      >
        <Button
          label="Operation History"
          onClick={() => {
            if (!landingOpen) {
              setSystemControlOpen(false)
              setTempOpen(false)
              setZoomOpen(false)
              setSosOpen(false)
            }
            setLandingOpen(!landingOpen)
          }}
          style={{ backgroundColor: !landingOpen ? "grey" : "blue" }}
        />
        {/* <DotAnimation play={false} /> */}
        <Button
          label={`Zoomed-in\nCurrent Voltage SoC`}
          onClick={() => {
            if (!systemControlOpen) {
              setLandingOpen(false)
              setTempOpen(false)
              setZoomOpen(false)
              setSosOpen(false)
            }
            setSystemControlOpen(!systemControlOpen)
          }}
          style={{ backgroundColor: !systemControlOpen ? "grey" : "blue", whiteSpace: "pre" }}
        />
        {/* <DotAnimation play={false} /> */}
        <Button
          label="One Day Operation"
          onClick={() => {
            if (!zoomOpen) {
              setLandingOpen(false)
              setSystemControlOpen(false)
              setTempOpen(false)
              setSosOpen(false)
            }
            setZoomOpen(!zoomOpen)
          }}
          style={{ backgroundColor: !zoomOpen ? "grey" : "blue" }}
        />
        <Button
          label="SoS"
          onClick={() => {
            if (!sosOpen) {
              setLandingOpen(false)
              setSystemControlOpen(false)
              setZoomOpen(false)
              setTempOpen(false)
            }
            setSosOpen(!sosOpen)
          }}
          style={{ backgroundColor: !sosOpen ? "grey" : "blue" }}
        />
        <Button
          label="Temperature"
          onClick={() => {
            if (!tempOpen) {
              setLandingOpen(false)
              setSystemControlOpen(false)
              setZoomOpen(false)
              setSosOpen(false)
            }
            setTempOpen(!tempOpen)
          }}
          style={{ backgroundColor: !tempOpen ? "grey" : "blue" }}
        />
        {/* <Button
          startIcon={<FastRewindIcon />}
          label=""
          style={{ width: "50px", borderRadius: "50%", backgroundColor: "white" }}
          onClick={() => {
            if (intervalTime > 5) {
              setIntervalTime(intervalTime - 5)
            }
            return
          }}
        /> */}
        <Button
          startIcon={autoScroll ? <PauseNoCircleIcon /> : <PlayIcon />}
          label=""
          style={{ width: "50px", borderRadius: "50%", backgroundColor: "white" }}
          onClick={() => {
            setAutoScroll(!autoScroll)
          }}
        />
        {/* <Button
          startIcon={<FastForwardIcon />}
          label=""
          style={{ width: "50px", borderRadius: "50%", backgroundColor: "white" }}
          onClick={() => {
            if (intervalTime < 25) {
              setIntervalTime(intervalTime + 5)
            }
            return
          }}
        /> */}
      </div>
      {landingOpen ? (
        <OperationDemo
          height={height}
          isScroll={autoScroll}
          intervalTime={intervalTime}
          handleScroll={() => setAutoScroll(false)}
        />
      ) : null}

      {systemControlOpen ? (
        <ZoominDemo
          height={height}
          isScroll={autoScroll}
          intervalTime={intervalTime}
          handleScroll={() => setAutoScroll(false)}
        />
      ) : null}
      {zoomOpen ? (
        <OnedayDemo
          height={height}
          isScroll={autoScroll}
          intervalTime={intervalTime}
          handleScroll={() => setAutoScroll(false)}
        />
      ) : null}
      {tempOpen ? (
        <TempDemo
          height={height}
          isScroll={autoScroll}
          intervalTime={intervalTime}
          handleScroll={() => setAutoScroll(false)}
        />
      ) : null}
      {sosOpen ? (
        <SosDemo
          height={height}
          isScroll={autoScroll}
          intervalTime={intervalTime}
          handleScroll={() => setAutoScroll(false)}
        />
      ) : null}
    </div>
  )
}

export default TestScreen
