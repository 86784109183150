import * as React from "react"
import "./sox.scss"
import { useNavigate } from "react-router-dom"
import URLs from "../../routes/urls"
import { PlotType, SOX_CATEGORY_TEXT, SOX_TYPE, SOX_TYPE_TEXT } from "../../constant"
import { useState } from "react"
import { useAppContext } from "../../context/AppContext"
import dayjs from "dayjs"
import ChartWithSelect from "../Chart/ChartWithSelect"
import { getSoxStatusText } from "../../common/util"
import { EEDataGrid } from "../../component/EEDataGrid"
import EEBadge from "../../component/EEBadge"

const SOX: React.FunctionComponent = () => {
  const alertColumnSample: any[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "level",
      headerName: "Level",
      width: 150,
      renderCell(params) {
        if (!params.viewedByUserAt) {
          return (
            <div
              style={{
                color: params.level === SOX_TYPE.alert || params.level === SOX_TYPE.critical ? "red" : "orange",
              }}
            >
              <EEBadge /> {SOX_TYPE_TEXT[params.level]}
            </div>
          )
        }
        return (
          <div
            style={{
              color: params.level === SOX_TYPE.alert || params.level === SOX_TYPE.critical ? "red" : "orange",
            }}
          >
            {SOX_TYPE_TEXT[params.level]}
          </div>
        )
      },
    },
    {
      field: "Type",
      headerName: "Type",
      width: 150,
      valueGetter: (params) => `${SOX_CATEGORY_TEXT[params.type] || ""} `,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 300,
      valueGetter: (params) => `${dayjs(params.alarmCreate).format("YYYY/MM/DD hh:mm:ss") || ""} `,
    },
    {
      field: "container",
      headerName: "Container",
      width: 150,
    },
    {
      field: "stack/node",
      headerName: "Stack/Node Id",
      width: 150,
      valueGetter: (params) => `${params.stack || ""} / ${params.node || ""}`,
    },
    {
      field: "cell",
      headerName: "Cell Id",
      width: 150,
      renderCell(params) {
        return <div>{params.details.cell.toString()}</div>
      },
    },
    {
      field: "cycle",
      headerName: "Cycle",
      width: 150,
    },

    {
      field: "displayStatus",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => getSoxStatusText(params.displayStatus),
    },
  ]
  const navigate = useNavigate()

  const [listAlarmData, setListAlarmData] = useState([])

  const appContext = useAppContext()
  const { getAlarmList } = appContext

  const getListData = async () => {
    const response = await getAlarmList()
    // console.log("response.data", response.data)
    if (response && response.data) {
      setListAlarmData(response.data)
    }
  }
  React.useEffect(() => {
    getListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sosSelectOption = [
    {
      id: 4,
      label: "SoS",
      value: 4,
      level: { node: { activate: true, noneValue: false }, stack: { activate: true, noneValue: false } },
      node: { activate: false, noneValue: false },
      stack: { activate: true, noneValue: true },
    },
  ]
  const sodSelectOption = [
    {
      id: 17,
      value: 17,
      label: "LLI",
      level: { node: { activate: true, noneValue: false }, stack: { activate: true, noneValue: false } },
      node: { activate: false, noneValue: false },
      stack: { activate: true, noneValue: true },
    },
    {
      id: 18,
      value: 18,
      label: "LAMP",
      level: { node: { activate: true, noneValue: false }, stack: { activate: true, noneValue: false } },
      node: { activate: false, noneValue: false },
      stack: { activate: true, noneValue: true },
    },
    {
      id: 19,
      value: 19,
      label: "LAMN",
      level: { node: { activate: true, noneValue: false }, stack: { activate: true, noneValue: false } },
      node: { activate: false, noneValue: false },
      stack: { activate: true, noneValue: true },
    },
  ]
  return (
    <div className="page-section-content-wrapper">
      <div className="line-chart-wrapper" style={{ height: "450px" }}>
        <div className="page-section-title-wrapper">
          <div className="page-section-title">Alarm List</div>
        </div>
        <div style={{ height: "300px", paddingLeft: "24px", paddingRight: "24px" }}>
          {listAlarmData && listAlarmData.length === 0 ? (
            <div style={{ color: "var(--main-page-title)", fontSize: "20px", textAlign: "center" }}>No data</div>
          ) : (
            <EEDataGrid
              rows={listAlarmData}
              columns={alertColumnSample}
              height="270px"
              onRowClick={(row) => {
                console.log("row checkckckck")
                navigate(URLs.ReportDetail, { state: { ...row } })
              }}
            />
          )}
        </div>
      </div>
      <ChartWithSelect isTable selectOption={sosSelectOption} type={PlotType.sosDefault} />
      <ChartWithSelect selectOption={sodSelectOption} type={PlotType.lli} />
      {/* <MultiChartPanel
        dataParam={{ container: 0, node: "1-L", stack: 1 }}
        style={{ marginLeft: "0px !important", marginRight: "0px" }}
        select
        isSox
      /> */}
    </div>
  )
}

export default SOX
