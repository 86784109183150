import { FormControl, FormControlLabel, FormGroup, OutlinedInput, Radio, RadioGroup } from "@mui/material"
import * as React from "react"
import { useAppContext } from "../../context/AppContext"
import { MainState } from "../../type/system"
import dayjs from "dayjs"
import "./adminMain.scss"
import EESelect from "../../component/EESelect"
import { SOX_ALARM_LEVEL, USER_ROLE } from "../../constant"
import { Button } from "../../component/Button"
import EERadio from "../../component/EERadio"
import EECheckbox from "../../component/EECheckbox"
import EETextField from "../../component/EETextField"

interface IAdminMainProps {}

const AdminMain: React.FunctionComponent<IAdminMainProps> = (props) => {
  const appContext = useAppContext()
  const { getContainerOptions, getPlotCategory, updatePlotCategoryState, getAlarmSettingList, updateAlarmSetting } =
    appContext
  const [state, setState] = React.useState<MainState>()
  const [alarmList, setAlarmList] = React.useState<any>()
  const [selectedContainer, setSelectedContainer] = React.useState<any>("1")
  const [containerOption, setContainerOption] = React.useState<any>([])
  const [renamedContainer, setRenamedContainer] = React.useState<any>()
  const [operatorSetting, setOperatorSetting] = React.useState<any>({
    level: SOX_ALARM_LEVEL.all,
    email: true,
    sms: true,
  })
  const [onmSetting, setOnmSetting] = React.useState<any>({
    sendLevel: SOX_ALARM_LEVEL.all,
    issendemail: "true",
    issendsms: "true",
  })
  const [operatorChart, setOperatorChart] = React.useState<any>([])
  const [performanceChart, setPerformanceChart] = React.useState<any>([])
  const [soxChart, setSoxChart] = React.useState<any>([])
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    const containerOptionResponse = await getContainerOptions()
    const categoryOperationResponse = await getPlotCategory({ plotType: 1, orgId: 1 })
    const categoryPerformanceResponse = await getPlotCategory({ plotType: 2, orgId: 1 })
    const alarmSettingResponse = await getAlarmSettingList({ orgId: 1, projectId: 1 })
    const categorySoxResponse = await getPlotCategory({ plotType: 3, orgId: 1 })

    if (categoryOperationResponse && categoryOperationResponse.length > 0) {
      const newArray: { id: number; isopen: boolean; name: string }[] = [] // Define the type of newArray
      categoryOperationResponse.map((item) => {
        return newArray.push({ id: item.id, isopen: item.isopen, name: item.name })
      })
      setOperatorChart(newArray)
    }
    if (categoryPerformanceResponse) {
      const newArray: { id: number; isopen: boolean; name: string }[] = [] // Define the type of newArray
      categoryPerformanceResponse.map((item) => {
        return newArray.push({ id: item.id, isopen: item.isopen, name: item.name })
      })
      setPerformanceChart(newArray)
    }
    if (categorySoxResponse) {
      const newArray: { id: number; isopen: boolean; name: string }[] = [] // Define the type of newArray
      categorySoxResponse.map((item) => {
        return newArray.push({ id: item.id, isopen: item.isopen, name: item.name })
      })
      setSoxChart(newArray)
    }

    if (alarmSettingResponse && alarmSettingResponse.length > 0) {
      setOnmSetting(alarmSettingResponse.find((item) => item.roleLevel === USER_ROLE.onmManager))
      setOperatorSetting(alarmSettingResponse.find((item) => item.roleLevel === USER_ROLE.operator))
    }
    if (containerOptionResponse && containerOptionResponse.data) {
      setContainerOption(containerOptionResponse.data)
    }
  }

  const handleOperationChartSetting = (value) => {
    const newChart = operatorChart.map((item) => {
      if (item.id === value) {
        return { ...item, isopen: !item.isopen }
      } else {
        return item
      }
    })
    setOperatorChart(newChart)
  }
  const handleContainerNameUpdate = async () => {
    // console.log("save clicked???", renamedContainer)
    const response = await appContext.updateContainerName(renamedContainer.label, Number(selectedContainer))
    // console.log("response", response)
    if (response) {
      getData()
    }
  }
  const handlePerformanceChartSetting = (value) => {
    const newChart = performanceChart.map((item) => {
      if (item.id === value) {
        return { ...item, isopen: !item.isopen }
      } else {
        return item
      }
    })
    setPerformanceChart(newChart)
  }
  const handleSoxChartSetting = (value) => {
    const newChart = soxChart.map((item) => {
      if (item.id === value) {
        return { ...item, isopen: !item.isopen }
      } else {
        return item
      }
    })
    setSoxChart(newChart)
  }
  const saveChartSetting = async () => {
    const param: { id: number; isopen: boolean }[] = []
    operatorChart.map((item) => {
      return param.push({ id: item.id, isopen: item.isopen })
    })
    performanceChart.map((item) => {
      return param.push({ id: item.id, isopen: item.isopen })
    })
    soxChart.map((item) => {
      return param.push({ id: item.id, isopen: item.isopen })
    })
    const response = await updatePlotCategoryState(param)
    if (response.statusCode === 200) {
      alert("Successfully saved")
    } else {
      alert("Failed to save")
    }
  }

  const saveAlarmSetting = async (param) => {
    const response = await updateAlarmSetting(param)
    console.log("response", response)
    if (response.statusCode === 200) {
      alert("Successfully saved")
    } else {
      alert("Failed to save")
    }
  }

  return (
    <div className="admin-main-wrapper">
      <div className="admin-main-status-wrapper">
        <div className="main-page-title">
          <b>Operation Status Last Updated :</b> {state ? dayjs(state.lastUpdate).format("YYYY/MM/DD hh:mm:ss") : ""}
        </div>
        <div className="admin-main-page-status-card-wrapper">
          <div className="admin-main-page-status">
            <div className="main-page-status-title">{state ? state.containerCount : "0"}</div>
            <div className="main-page-status-unit">CONTAINERS</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title">{state ? state.cycles : 0}</div>
            <div className="main-page-status-unit">CYCLES</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title">{state ? state.runDate : 0}</div>
            <div className="main-page-status-unit">DAYS</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title">
              {state ? state.totalEnergy : 0}
              <span>kWH</span>
            </div>
            <div className="main-page-status-unit">R&D SYSTEM</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title" style={{ color: "#E63535" }}>
              {alarmList ? alarmList.length : 0}
            </div>
            <div className="main-page-status-unit">SOX ALARM</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title" style={{ color: "#E63535" }}>
              {state ? state.runDate : 0}
            </div>
            <div className="main-page-status-unit">SOX REQUEST</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title" style={{ color: "#1E85FF" }}>
              {state ? state.runDate : 0}
            </div>
            <div className="main-page-status-unit">MEMEBERS</div>
          </div>
          <div className="admin-main-page-status">
            <div className="main-page-status-title" style={{ color: "#1E85FF" }}>
              {state ? state.runDate : 0}
            </div>
            <div className="main-page-status-unit">MEMBER APPROVAL</div>
          </div>
        </div>
      </div>
      <div className="admin-main-container-setting-wrapper">
        <span style={{ padding: "24px" }}>Container Setting</span>
        {containerOption ? (
          <EESelect
            value={selectedContainer}
            onChange={function (newValue: any): void {
              setRenamedContainer(containerOption.find((item) => item.value === newValue.value))

              setSelectedContainer(newValue.value)
            }}
            options={containerOption}
          />
        ) : null}

        <EETextField
          id="my-input"
          onChange={(e) => setRenamedContainer({ ...renamedContainer, label: e.target.value })}
          style={{
            borderBottom: "0.5px solid var(  --login-input-border)",
            width: "200px",
          }}
          value={renamedContainer?.label}
          placeholder=""
          label=""
        />

        <Button label="SAVE" onClick={handleContainerNameUpdate} style={{ width: "150px" }}></Button>
      </div>
      <div className="admin-main-alarmn-setting-wrapper">
        <span style={{ padding: "24px" }}>OnM Alarm Setting</span>
        <div className="admin-main-setting">
          <div
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ padding: "24px" }}>LEVEL</span>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={SOX_ALARM_LEVEL.all.toString()}
                label="All Level"
                checked={SOX_ALARM_LEVEL.all === onmSetting.sendLevel}
                onChange={(e) =>
                  setOnmSetting((prev) => {
                    return { ...prev, sendLevel: SOX_ALARM_LEVEL.all }
                  })
                }
                name="levelAll"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={SOX_ALARM_LEVEL.critical.toString()}
                label="Critical Only"
                checked={SOX_ALARM_LEVEL.critical === onmSetting.sendLevel}
                onChange={(e) =>
                  setOnmSetting((prev) => {
                    return { ...prev, sendLevel: SOX_ALARM_LEVEL.critical }
                  })
                }
                name="levelCritical"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={SOX_ALARM_LEVEL.criticalAndAlart.toString()}
                label="Critical & Alert"
                checked={SOX_ALARM_LEVEL.criticalAndAlart === onmSetting.sendLevel}
                onChange={(e) =>
                  setOnmSetting((prev) => {
                    return { ...prev, sendLevel: SOX_ALARM_LEVEL.criticalAndAlart }
                  })
                }
                name="levelCriticalAndAlert"
              />
            </div>
          </div>
          <div className="vertical-line" style={{ height: "100%" }}></div>
          <div
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ padding: "24px" }}>EMAIL</span>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"1"}
                label="Yes"
                checked={true === onmSetting.issendemail}
                onChange={(e) => {
                  setOnmSetting((prev) => {
                    return { ...prev, issendemail: true }
                  })
                }}
                name="emailYes"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"2"}
                label="No"
                checked={false === onmSetting.issendemail}
                onChange={(e) => {
                  setOnmSetting((prev) => {
                    return { ...prev, issendemail: false }
                  })
                }}
                name="emailNo"
              />
            </div>
          </div>
          <div className="vertical-line" style={{ height: "100%" }}></div>

          <div
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ padding: "24px" }}>SMS</span>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"1"}
                onChange={(e) =>
                  setOnmSetting((prev) => {
                    return { ...prev, issendsms: true }
                  })
                }
                checked={true === onmSetting.issendsms}
                label="Yes"
                name="SMSYes"
              />
            </div>{" "}
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"2"}
                checked={false === onmSetting.issendsms}
                label="No"
                onChange={(e) =>
                  setOnmSetting((prev) => {
                    return { ...prev, issendsms: false }
                  })
                }
                name="SMSNo"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            marginBottom: "16px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button label="SAVE" style={{ width: "150px" }} onClick={() => saveAlarmSetting(onmSetting)}></Button>
        </div>
      </div>
      <div className="admin-main-alarmn-setting-wrapper">
        <span style={{ padding: "24px" }}>Operator Alarm Setting</span>
        <div className="admin-main-setting">
          <div
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ padding: "24px" }}>LEVEL</span>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={SOX_ALARM_LEVEL.all.toString()}
                checked={SOX_ALARM_LEVEL.all === operatorSetting.sendLevel}
                label="All Level"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, sendLevel: SOX_ALARM_LEVEL.all }
                  })
                }
                name="operatorLevelAll"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={SOX_ALARM_LEVEL.critical.toString()}
                checked={SOX_ALARM_LEVEL.critical === operatorSetting.sendLevel}
                label="Critical Only"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, sendLevel: SOX_ALARM_LEVEL.critical }
                  })
                }
                name="operatorLevelCritical"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={SOX_ALARM_LEVEL.criticalAndAlart.toString()}
                checked={SOX_ALARM_LEVEL.criticalAndAlart === operatorSetting.sendLevel}
                label="Critical & Alert"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, sendLevel: SOX_ALARM_LEVEL.criticalAndAlart }
                  })
                }
                name="operatorLevelCriticalAndAlert"
              />
            </div>
          </div>
          <div className="vertical-line" style={{ height: "100%" }}></div>
          <div
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ padding: "24px" }}>EMAIL</span>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"1"}
                checked={true === operatorSetting.issendemail}
                label="Yes"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, issendemail: true }
                  })
                }
                name="operatorEmailYes"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"2"}
                checked={false === operatorSetting.issendemail}
                label="No"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, issendemail: false }
                  })
                }
                name="operatorEmailNo"
              />
            </div>
          </div>

          <div className="vertical-line" style={{ height: "100%" }}></div>

          <div
            style={{
              paddingLeft: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ padding: "24px" }}>SMS</span>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"1"}
                checked={true === operatorSetting.issendsms}
                label="Yes"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, issendsms: true }
                  })
                }
                name="operatorSMSYes"
              />
            </div>
            <div style={{ marginBottom: "12px" }}>
              <EERadio
                value={"2"}
                checked={false === operatorSetting.issendsms}
                label="No"
                onChange={(e) =>
                  setOperatorSetting((prev) => {
                    return { ...prev, issendsms: false }
                  })
                }
                name="operatorSMSNo"
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "95%",
            marginBottom: "16px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button label="SAVE" style={{ width: "150px" }} onClick={() => saveAlarmSetting(operatorSetting)}></Button>
        </div>
      </div>
      <div className="admin-main-chart-setting-wrapper">
        <span style={{ padding: "24px" }}>Chart Setting</span>
        <div className="admin-main-setting">
          {operatorChart && operatorChart.length > 0 ? (
            <div
              style={{
                paddingLeft: "24px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ padding: "24px" }}>OPERATION</span>
              {operatorChart.map((item) => {
                return (
                  <div style={{ marginBottom: "12px" }}>
                    <EECheckbox
                      checked={item.isopen}
                      onChange={(e) => handleOperationChartSetting(item.id)}
                      label={item.name}
                    />
                  </div>
                )
              })}
            </div>
          ) : null}
          {performanceChart && performanceChart.length > 0 ? (
            <>
              <div className="vertical-line" style={{ height: "400px" }}></div>
              <div
                style={{
                  paddingLeft: "24px",
                  display: "flex",
                  flexDirection: "column",
                }}
                onChange={(e) => handlePerformanceChartSetting(parseInt(e.target.value))}
              >
                <span style={{ padding: "24px" }}>PERFORMANCE</span>
                {performanceChart.map((item) => {
                  return (
                    <div style={{ marginBottom: "12px" }}>
                      <EECheckbox
                        checked={item.isopen}
                        onChange={(e) => handlePerformanceChartSetting(item.id)}
                        label={item.name}
                      />
                    </div>
                  )
                })}
              </div>
            </>
          ) : null}
          {soxChart && soxChart.length > 0 ? (
            <>
              <div className="vertical-line" style={{ height: "400px" }}></div>
              <div
                style={{
                  paddingLeft: "24px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{ padding: "24px" }}>SOX</span>
                {soxChart.map((item) => {
                  return (
                    <div style={{ marginBottom: "12px" }}>
                      <EECheckbox
                        checked={item.isopen}
                        onChange={(e) => handleSoxChartSetting(item.id)}
                        label={item.name}
                      />
                    </div>
                  )
                })}
              </div>
            </>
          ) : null}
        </div>
        <div
          style={{
            width: "95%",
            marginBottom: "16px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button label="SAVE" style={{ width: "150px" }} onClick={saveChartSetting}></Button>
        </div>
      </div>
    </div>
  )
}

export default AdminMain
