import * as React from "react"
import Container from "./Container"
import "./plantDialogContent.scss"
import "../container/OperateAndManager/plant.scss"
import InverterIcon from "../assets/inverterIcon"
import { PLANT_AC_STATUS_LEGEND, PLANT_STACK_STATUS_LEGEND } from "../constant"
import { useAppContext } from "../context/AppContext"

interface IPlantDialogContentProps {
  data: any
}

const PlantDialogContent: React.FunctionComponent<IPlantDialogContentProps> = ({ data }) => {
  const appContext = useAppContext()
  const { isDarkMode } = appContext
  return (
    <div className="plant-dialog-content-wrapper" id="plant-image-zoom-in-dialog">
      <div className="plant-dialog-top-wrapper">
        <div className="plant-dialog-legend-wrapper">
          {/* <div className="plant-container-legend-wrapper">
            <span className="plant-legend-title">Container</span>
            {PLANT_AC_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <span
                  style={{
                    color: item.color,
                    border: `1px solid ${item.color}`,
                    padding: "4px",
                    fontSize: "10px",
                    marginRight: "4px",
                  }}
                >
                  {item.label}
                </span>
              </div>
            ))}
          </div>
          <div className="plant-container-legend-wrapper">
            <span className="plant-legend-title">Stack</span>
            {PLANT_STACK_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div> */}
          <div className="plant-container-legend-wrapper">
            <span className="plant-legend-title">Container</span>
            {PLANT_AC_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <div
                  style={{
                    color: item.color,
                    border: `2px solid ${item.color}`,
                    padding: "4px",
                    fontSize: "11px",
                    // marginRight: "4px",
                    backgroundColor: "#ffffff30",
                  }}
                ></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
          <div className="plant-container-legend-wrapper">
            <span className="plant-legend-title">Stack</span>
            {PLANT_STACK_STATUS_LEGEND.map((item, index) => (
              <div className="system-legend" key={index}>
                <div style={{ backgroundColor: item.color, width: "8px", height: "4px" }}></div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="plant-dialog-wrapper">
        <div className="plant-dialog-section">
          <div className="plant-dialog-section-title">
            <InverterIcon title="Inv 01" />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 01-A</span>
              </div>
              {data.map((item, index) => {
                if (index < 3) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 01-B</span>
              </div>
              {data.map((item, index) => {
                if (index > 2 && index < 6) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
          </div>
        </div>
        <div className="plant-dialog-section">
          <div className="plant-dialog-section-title">
            <InverterIcon title="Inv 02" />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper ">
                <span>Inv 02-A</span>
              </div>
              {data.map((item, index) => {
                if (index > 5 && index < 9) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 02-B</span>
              </div>
              {data.map((item, index) => {
                if (index > 8 && index < 12) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
          </div>
        </div>
        <div className="plant-dialog-section">
          <div className="plant-dialog-section-title">
            <InverterIcon title="Inv 03" />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 03-A</span>
              </div>
              {data.map((item, index) => {
                if (index > 11 && index < 15) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 03-B</span>
              </div>
              {data.map((item, index) => {
                if (index > 14 && index < 18) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
          </div>
        </div>
        <div className="plant-dialog-section">
          <div className="plant-dialog-section-title">
            <InverterIcon title="Inv 04" />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 04-A</span>
              </div>
              <div className="plant-dialog-inverter-containers-wrapper">
                {data.map((item, index) => {
                  if (index > 17 && index < 21) {
                    return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                  } else {
                    return null
                  }
                })}
              </div>
            </div>
            <div
              style={{ width: "inherit", display: "flex", flexDirection: "column", alignItems: "center", gap: "12px" }}
            >
              <div className="plant-dialog-inverter-wrapper">
                <span>Inv 04-B</span>
              </div>
              {data.map((item, index) => {
                if (index > 20) {
                  return <Container key={index} status={item} id={`container-${index}`} isDarkMode={isDarkMode} />
                } else {
                  return null
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlantDialogContent
