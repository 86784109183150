import * as React from "react"
import "./operationSystemPage.scss"
import { useEffect, useState } from "react"

import ChartWithPeriod from "../Chart/ChartWithPeriod"
import _ from "lodash"
import { useAppContext } from "../../context/AppContext"
import URLs from "../../routes/urls"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getSessionData, setSessionData } from "../../common/util"
import { SESSION_STORAGE_KEY } from "../../constant"
import { Button } from "../../component/Button"
import { SettingsIcon } from "../../assets/images/settingsIcon"
import { RemoveIcon } from "../../assets/images/removeIcon"
import { AddIcon } from "../../assets/images/addIcon"

interface IOpertaionSystemPageProps {}

const OpertaionSystemPage: React.FunctionComponent<IOpertaionSystemPageProps> = (props) => {
  const navigate = useNavigate()

  const [chartCount, setChartCount] = useState<number>(0)
  const [selectOption, setSelectOption] = useState<any>()
  const appContext = useAppContext()
  const { getOperationSelectOption, getChartSetting } = appContext

  const [defaultSelect, setDefaultSelect] = useState<
    {
      container: string
      id: number
      node: null
      plotCategoryId: number
      plotType: number
      stack: null
      state: null
      timeRange: string
    }[]
  >([])
  const sessionSearch = getSessionData(SESSION_STORAGE_KEY.operationChart)

  useEffect(() => {
    console.log("sessionSearch", sessionSearch)
    if (sessionSearch) setDefaultSelect(sessionSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // search 값이 바뀔 때마다 sessionStorage의 값도 변경하기
  useEffect(() => {
    if (defaultSelect && defaultSelect.length > 0) {
      console.log("defaultSelect", defaultSelect)
      setSessionData(SESSION_STORAGE_KEY.operationChart, defaultSelect)
      setChartCount(defaultSelect.length)
    }
  }, [defaultSelect])
  // const handleOperationSelect = async () => {
  //   const option = await getOperationSelectOption()
  //   setSelectOption(option.data)
  // }
  const { data } = useQuery({
    queryKey: ["operationSelect"],
    queryFn: async () => {
      const data = await getOperationSelectOption()
      return data
    },
  })
  const { data: defaultChart } = useQuery({
    queryKey: ["defaultChart"],
    queryFn: async () => {
      const data = await getChartSetting()
      return data
    },
  })
  useEffect(() => {
    if (data) {
      const newOption = data.data.map((item) => {
        return { ...item, value: item.id }
      })
      setSelectOption(newOption)
    }
  }, [data])
  useEffect(() => {
    if (defaultChart && !sessionSearch) {
      if (defaultChart.data.operation) {
        console.log("defaultChart.data.operation", defaultChart.data.operation)
        setDefaultSelect(defaultChart.data.operation)
      } else {
        setDefaultSelect([
          {
            container: "1",
            id: 29,
            node: null,
            plotCategoryId: 8,
            plotType: 8,
            stack: null,
            state: null,
            timeRange: "1h",
          },
        ])
      }
      // setDefaultSelect(defaultChart.data.operation)
    }

    // } else if (!defaultChart && !sessionSearch) {
    //   setDefaultSelect([
    //     { container: "1", id: 29, node: null, plotCategoryId: 8, stack: null, state: null, timeRange: "1h" },
    //   ])
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChart])
  return (
    <div>
      {/* <div className="page-section-title-wrapper">
        <div className="page-section-title">System Level Operation</div>
      </div> */}
      <div className="page-section-content-wrapper">
        <div className="operation-page-button-wrapper">
          <Button
            onClick={() => {
              setChartCount(chartCount + 1)
              setDefaultSelect([...defaultSelect, defaultSelect[0]])
            }}
            label="Add Chart"
            startIcon={<AddIcon fill="rgb(25, 118, 210)" />}
            style={{
              width: "145px",
              backgroundColor: "transparent",
              border: "1px solid rgba(25, 118, 210, 0.5)",
              color: "rgb(25, 118, 210)",
              boxShadow: "none",
            }}
          ></Button>
          <Button
            disabled={chartCount < 2}
            onClick={() => {
              if (chartCount > 1) {
                setChartCount(chartCount - 1)

                setDefaultSelect((prev) => prev.slice(0, defaultSelect.length - 1))
              }
            }}
            label="Remove Chart"
            startIcon={<RemoveIcon fill="rgb(25, 118, 210)" />}
            style={{
              width: "165px",
              backgroundColor: "transparent",
              border: "1px solid rgba(25, 118, 210, 0.5)",
              color: "rgb(25, 118, 210)",
              boxShadow: "none",
            }}
          ></Button>
          <Button
            onClick={() => navigate(URLs.Profile)}
            label=""
            startIcon={<SettingsIcon fill="rgb(25, 118, 210)" />}
            style={{
              width: "117px",
              backgroundColor: "transparent",
              border: "0px",
              color: "rgb(25, 118, 210)",
              boxShadow: "none",
            }}
          ></Button>
        </div>
        <div className="page-section-content-wrapper">
          {defaultSelect.length > 0 && chartCount > 0
            ? _.times(chartCount, (item) => {
                return (
                  <ChartWithPeriod
                    selectOption={selectOption}
                    chartId={item.toString()}
                    defaultSelect={item < chartCount && defaultSelect ? defaultSelect[item] : null}
                  />
                )
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default OpertaionSystemPage
