import * as React from "react"
import "./chartWithPeriod.scss"

import { useEffect, useState } from "react"
import EELegend from "../../component/charts/EELegend"
import EESelect from "../../component/EESelect"
import { AI_PLOT_TYPE_TITLE, AI_STATE_OPTION, OPTION_NONE_SELECT_VALUE, PlotType } from "../../constant"
import { createNodeOption, createStackOption, dataCycleConvert } from "../../common/util"
import EELoading from "../../component/Loading"
import { chartColors } from "../../common/chartColors"
import EEOperationChart from "../../component/charts/EEOperationChart"
import { useAppContext } from "../../context/AppContext"
import _ from "lodash"
import { useQuery } from "@tanstack/react-query"
import { SOXParam } from "../../type/chart"
import { FlaggedCycle } from "../../type/sox"
import { EEDataGrid } from "../../component/EEDataGrid"

interface IChartWithPeriodProps {
  selectOption?: any[]
  isTable?: boolean
  type: number
  style?: any
  key?: any
}

const ChartWithSelect: React.FunctionComponent<IChartWithPeriodProps> = ({
  style = {},
  isTable = false,
  selectOption = [],
  type,
  key,
}) => {
  const flaggedCountColumn: any[] = [
    {
      field: "id",
      headerName: "ID",
      filterable: false,
    },
    { field: "container", headerName: "Container" },
    { field: "stack", headerName: "Stack" },
    { field: "node", headerName: "Node" },
    { field: "cell", headerName: "Cell" },
    { field: "cycle", headerName: "Cycle" },
    { field: "score", headerName: "Score" },
  ]
  const [currentData, setCurrentData] = useState<any>([])
  const [updateData, setUpdateData] = useState<any>([])

  const [dataParam, setDataParam] = useState<SOXParam>({
    plotType: type,
    container: 0,
    node: "1",
    stack: 1,
    state: 2,
  })

  const [noData, setNodata] = useState<boolean>(false)
  const [stackOption, setStackOption] = useState<any>()
  const [nodeOption, setNodeOption] = useState<any>()
  const [newLegend, setNewLegend] = useState<any>()
  const [nodeDisabled, setNodeDisabled] = useState<boolean>(false)
  const appContext = useAppContext()
  const { getSosChartData, getFlaggedSoS, getContainerOptions } = appContext
  const [maxPoint, setMaxPoint] = useState(0)
  const [minPoint, setMinPoint] = useState(0)
  const [selectedOption, setSelectedOption] = useState<any>()
  const [errorMessage, setErrorMessage] = useState("")
  const [unit, setUnit] = useState()
  const [flaggedCycle, setFlaggedCycle] = useState<FlaggedCycle[]>([])
  const [containerOption, setContainerOption] = useState<any>()
  useEffect(() => {
    if (selectOption) {
      setSelectedOption(selectOption[0])
    }
  }, [selectOption])
  const handleToggleChange = (toggleIndex) => {
    const nextLegend = newLegend.map((item, index) => {
      if (toggleIndex === index) {
        return { ...item, checked: !item.checked }
      }
      return item
    })

    setNewLegend(nextLegend)
  }

  const { data, isPending } = useQuery({
    queryKey: [
      AI_PLOT_TYPE_TITLE[type],
      {
        plotType: dataParam.plotType,
        container: dataParam.container,
        node: dataParam.node,
        stack: dataParam.stack,
        state: dataParam.state,
      },
    ],
    queryFn: async () => {
      // if (type === PlotType.sosDefault) {
      const data = await getSosChartData({
        plotType: dataParam.plotType,
        container: dataParam.container,
        node: dataParam.node,
        stack: dataParam.stack,
        state: dataParam.state,
      })
      // console.log("data data data chekckckckckckc", data)
      if (data.statusCode === 500 || data.statusCode === 400) {
        setErrorMessage("Internal Server Error")
        setCurrentData(null)
        setUpdateData(null)
        setNewLegend(null)
        setNodata(false)
        return null
      }
      return data
      // } else {
      //   const data = await getSodChartData({
      //     plotType: dataParam.plotType,
      //     container: dataParam.container,
      //     node: dataParam.node,
      //     stack: dataParam.stack,
      //     state: dataParam.state,
      //   })
      //   if (data.statusCode === 500 || data.statusCode === 400) {
      //     setErrorMessage("Internal Server Error")
      //     setCurrentData(null)
      //     setUpdateData(null)
      //     setNewLegend(null)
      //     setNodata(false)
      //     return null
      //   }
      //   return data
      // }
    },
  })
  // const containerOption = createContainerOption()
  const createContainerOption = async () => {
    const containerOptionsResponse = await getContainerOptions()
    if (containerOptionsResponse && containerOptionsResponse.statusCode === 200) {
      setContainerOption(containerOptionsResponse.data)
      setDataParam((prev) => Object.assign({ ...prev, container: containerOptionsResponse.data[0].value }))
    }
  }

  useEffect(() => {
    createContainerOption()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const createData = () => {
    if (data) setNodata(false)

    setErrorMessage("")
    setMaxPoint(data.maxValuePoint)
    setMinPoint(data.minValuePoint)
    setUnit(data.unit)
    const keyArray = Object.keys(data.plotData).filter(function (letter) {
      return letter !== "cycle"
    })
    if (data.plotData && keyArray.length > 0) {
      const timeValue = data.plotData.cycle
      if (timeValue.length < 1) {
        setNodata(true)
        return
      }
      const newDataValue = _.pick(data.plotData, keyArray)
      const chartData: any = []
      _.times(keyArray.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = keyArray[item]
        dataNew.values = dataCycleConvert({ cycle: timeValue, value: newDataValue[keyArray[item]] || [] })
        chartData.push(dataNew)
      })

      setCurrentData(chartData)
      setUpdateData(chartData)
      setNodata(false)
    } else {
      setNodata(true)
    }
  }

  useEffect(() => {
    // if (!getAccessToken()) {
    //   return
    // }
    if (isTable) {
      getFlaggedCount()
    }
    if (data && Object.keys(data).length > 0) {
      createData()
    } else {
      setNodata(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataParam])

  const getFlaggedCount = async () => {
    const response: FlaggedCycle[] = await getFlaggedSoS(dataParam)
    // console.log("response getFlaggedCount checkckckck", response)
    // const result = response.map((item, index) => {
    //   return Object.assign({ ...item, id: index })
    // })
    // console.log("result checkckckc", result)
    if (response) {
      setFlaggedCycle(response)
    } else setFlaggedCycle([])
  }
  useEffect(() => {
    const legend: any = []
    if (currentData && currentData.length > 0) {
      const dataKeys = Object.keys(currentData)
      dataKeys.map((item, index) => {
        return legend.push({ title: currentData[index].name, color: currentData[index].color, checked: true })
      })
      setNewLegend(legend)
    }
  }, [currentData])

  useEffect(() => {
    if (newLegend) {
      const nextData: any = []
      // eslint-disable-next-line array-callback-return
      newLegend.map((item, index) => {
        if (item.checked) nextData.push(currentData[index])
      })
      setUpdateData(nextData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLegend])

  const handleDisplayAll = () => {
    if (newLegend) {
      const nextLegend = newLegend.map((item) => {
        return { ...item, checked: true }
      })
      setNewLegend(nextLegend)
    }
  }
  useEffect(() => {
    if (selectedOption) {
      const sOption = !selectedOption.level.stack.activate ? null : createStackOption(selectedOption)
      const nOption = !selectedOption.level.node.activate ? null : createNodeOption(selectedOption)
      setStackOption(sOption)
      setNodeOption(nOption)
      if (nOption === null) {
        setDataParam((prev) => Object.assign({ ...prev, node: null }))
      }
      if (sOption === null) {
        setDataParam((prev) => Object.assign({ ...prev, stack: null }))
      }
    }
  }, [selectedOption])

  useEffect(() => {
    if (dataParam.stack === null) {
      setNodeDisabled(true)
      setDataParam((prev) => Object.assign({ ...prev, node: null }))
    } else if (dataParam.node === null) {
      setNodeDisabled(false)
    } else if (dataParam.stack !== null) {
      setNodeDisabled(false)
    } else {
      setNodeDisabled(false)
    }
  }, [dataParam])

  if (isPending) {
    return (
      <div className="line-chart-wrapper">
        <EELoading />
      </div>
    )
  }
  return (
    <div className="line-chart-wrapper" style={{ height: "600px", ...style }} key={key}>
      <div className="line-chart-title">
        {selectOption && AI_PLOT_TYPE_TITLE[type] === AI_PLOT_TYPE_TITLE[PlotType.sosDefault] ? (
          <span>SoS</span>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectOption &&
            AI_PLOT_TYPE_TITLE[type] !== AI_PLOT_TYPE_TITLE[PlotType.rsl] &&
            AI_PLOT_TYPE_TITLE[type] !== AI_PLOT_TYPE_TITLE[PlotType.rul] ? (
              <span>{AI_PLOT_TYPE_TITLE[dataParam.plotType]}</span>
            ) : null}
            <EESelect
              value={dataParam.plotType}
              onChange={(newValue) => {
                setDataParam((prev) => Object.assign({ ...prev, plotType: Number(newValue.id) }))

                setSelectedOption(selectOption.find((item) => item.id === newValue.id))
              }}
              options={selectOption}
            />
          </div>
        )}

        <div className="chart-form-wrapper">
          <EESelect
            value={dataParam.state}
            onChange={function (newValue: any): void {
              setDataParam((prev) => Object.assign({ ...prev, state: Number(newValue.value) }))
            }}
            options={AI_STATE_OPTION}
          />
          {selectOption ? (
            <>
              <EESelect
                // value="1"
                value={dataParam.container}
                onChange={(newValue) => {
                  console.log("value", newValue)
                  setDataParam((prev) => Object.assign({ ...prev, container: Number(newValue.value) }))
                }}
                options={containerOption}
              />
            </>
          ) : null}
          {stackOption ? (
            <EESelect
              value={dataParam.stack === null ? OPTION_NONE_SELECT_VALUE : dataParam.stack.toString()}
              onChange={(newValue) => {
                const stackId = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
                setDataParam((prev) => Object.assign({ ...prev, stack: stackId === null ? null : Number(stackId) }))
              }}
              options={stackOption}
            />
          ) : null}
          {nodeOption ? (
            <EESelect
              disabled={nodeDisabled}
              value={dataParam.node === null ? OPTION_NONE_SELECT_VALUE : dataParam.node}
              onChange={(newValue) => {
                const nodeId =
                  newValue.value === OPTION_NONE_SELECT_VALUE || dataParam.stack === null ? null : newValue.value
                setDataParam((prev) => Object.assign({ ...prev, node: nodeId }))
              }}
              options={nodeOption}
            />
          ) : null}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="chart-legend-wrapper" style={{ width: isTable ? "60%" : "100%" }}>
          <div style={{ width: "100%" }}>
            {updateData && updateData.length > 0 ? (
              <>
                <EEOperationChart
                  data={updateData}
                  maxPoint={maxPoint}
                  minPoint={minPoint}
                  scale={unit}
                  type="cycle"
                  brush={false}
                  multi={isTable}
                  divId={""}
                />
                {/* <EELineChart data={updateData} /> */}
              </>
            ) : !noData && !errorMessage ? (
              <EELoading />
            ) : errorMessage ? (
              <div className="chart-no-data">{errorMessage}</div>
            ) : (
              <div className="chart-no-data">No Data</div>
            )}
          </div>
          <div className="ee-legend-container">
            {updateData && updateData.length > 0 ? (
              <div className="ee-legend-button" onClick={handleDisplayAll}>
                Display All
              </div>
            ) : null}
            {newLegend &&
              newLegend.map((item, index) => (
                <EELegend
                  title={item.title}
                  color={item.color}
                  isToggle
                  checked={item.checked}
                  onToggleChange={() => handleToggleChange(index)}
                  key={index}
                />
              ))}
          </div>
        </div>
        {isTable ? (
          <div style={{ height: "400px", padding: "24px", width: "50%" }}>
            <EEDataGrid rows={flaggedCycle} columns={flaggedCountColumn} height="380px" />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ChartWithSelect
