import Cookies from "js-cookie"

export enum CookieName {
  ACCESS_TOKEN = "authorization",
  // ACCESS_USER_ID = 'user-id',
  GRAFANA_SESSION = "grafana_session",
  GRAFANA_SESSION_EXPIRY = "grafana_session_expiry",
  GRAFANA_COOKIE = "Cookie",
}
export function getAccessToken() {
  return getCookie(CookieName.ACCESS_TOKEN)
}

export const getCookie = (name: CookieName): string | undefined => Cookies.get(name)

export const setCookie = (name: CookieName, value: string): string | undefined => Cookies.set(name, value)

export const removeCookie = (name: CookieName): void => Cookies.remove(name)

export const getCookieList = (): any => Cookies.get()
