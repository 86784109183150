import * as React from "react"
import * as d3 from "d3"
import { useEffect, useRef, useState } from "react"
import { EETooltip } from "./EETooltip"
import { chartColors } from "../../common/chartColors"

interface IScatterChartProps {
  data: any
  type: string
  range: [number, number]
}

const ScatterChart: React.FunctionComponent<IScatterChartProps> = ({ data, type, range }) => {
  const chartRef = useRef(null)
  const margin = { top: 20, right: 30, bottom: 30, left: 40 }
  const [width, setWidth] = useState(window.innerWidth * 0.4)
  const height = 500 - margin.top - margin.bottom

  const boundsWidth = width - margin.right - margin.left
  const boundsHeight = height - margin.top - margin.bottom
  const [interactionData, setInteractionData] = useState<any>()
  const handleResize = () => {
    setWidth(window.innerWidth - margin.left - margin.right - 200)
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (data && data.length > 0) {
      const xScale = d3
        .scaleLinear()
        .domain([1, 11])
        .range([30, boundsWidth - 10])
      const yScale = d3.scaleLinear().domain(range).range([boundsHeight, 30])
      const svg = d3.select(chartRef.current)
      const xAxisGenerator = d3.axisBottom(xScale)
      const yAxisGenerator = d3.axisLeft(yScale)
      const xAxisGrid = d3
        .axisBottom(xScale)
        .tickSize(-boundsHeight + 30)
        .tickFormat("")
      const yAxisGrid = d3.axisLeft(yScale).tickSize(-boundsWidth).tickFormat("")
      svg.selectAll("*").remove()
      svg
        .attr("width", boundsWidth)
        .attr("height", height + margin.top + margin.bottom)
        .attr("transform", `translate(${[margin.left, margin.top].join(",")})`)

      svg
        .append("g")
        .attr("class", "_x_axis")
        .attr("transform", "translate(0," + boundsHeight + ")")
        .call(xAxisGenerator)
      svg
        .append("g")
        .attr("class", "x-axis-grid")
        .attr("transform", "translate(0," + boundsHeight + ")")
        .attr("stroke-width", 0.5)
        .attr("opacity", 0.3)
        .call(xAxisGrid)
      svg.append("g").attr("transform", `translate(${30},0)`).call(yAxisGenerator)
      svg
        .append("g")
        .attr("transform", `translate(${30},0)`)
        .attr("class", "y-axis-grid")
        .attr("stroke-width", 0.5)
        .attr("opacity", 0.3)
        .call(yAxisGrid)
      // Add X axis label:
      svg
        .append("text")
        .attr("text-anchor", "end")
        .attr("x", boundsWidth / 2 + 30)
        .attr("y", boundsHeight + 40)
        .text("cycle")

      data.map((item, index) => {
        svg
          .append("g")
          .selectAll("dot")
          .data(item.values)
          .enter()
          .append("circle")
          .attr("cx", function (d) {
            if (d.value === null) {
              return
            }
            return xScale(d.cycle)
          })
          .attr("cy", function (d) {
            if (d.value === null) {
              return
            }
            return yScale(d.value)
          })
          .attr("r", function (d) {
            if (d.value === null) {
              return 0
            }
            return 3
          })
          .style("fill", chartColors[index])
          .on("mousemove", function (e, d) {
            return setInteractionData({
              xPos: e.pageX,
              yPos: e.pageY,
              color: chartColors[index],
              x: "",
              y: `${d.value}`,
              name: `${d.cycle} cycle`,
            })
          })
          .on("mouseleave", () => setInteractionData(undefined))
        return null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width])
  return (
    <div>
      <EETooltip interactionData={interactionData} customTooltip />
      <svg ref={chartRef}></svg>
    </div>
  )
}

export default ScatterChart
