import * as React from "react"
import "./eeFloatingButton.scss"
import DotAnimation from "../container/\bDotAnimation"

interface IEEFloatingButtonProps {
  option: any[]
  dotAnimation?: boolean
  position?: any
}

const EEFloatingButton: React.FunctionComponent<IEEFloatingButtonProps> = ({ option, position }) => {
  return (
    <div className="floating-button-wrapper" style={{ top: position.top }}>
      {option?.map((child, index) => {
        if (index === option.length - 1) {
          return (
            <button onClick={child.onClick} className={child.isActive ? "floating-button-active" : "floating-button"}>
              {child.icon}
            </button>
          )
        }
        return (
          <>
            <button onClick={child.onClick} className={child.isActive ? "floating-button-active" : "floating-button"}>
              {child.icon}
            </button>
            <DotAnimation play={child.isActive} />
          </>
        )
      })}
    </div>
  )
}

export default EEFloatingButton
