import { StackStatusColor } from "../common/systemData"
import { PLANT_STACK_STATUS, PLANT_STACK_STATUS_COLOR } from "../constant"

export const ContainerSVG = ({ fill, title, isAlarm, status, isDarkMode }) => {
  return (
    <svg width="166" height="117" viewBox="0 0 166 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_157_7394)">
        <rect
          x="4"
          width="158"
          height="112"
          rx="2"
          fill={isDarkMode ? "#272727" : "#fff"}
          stroke={fill}
          strokeWidth="4"
          strokeLinejoin="round"
        />
      </g>
      <text x="30" y="15" fill="white" fontSize="12">
        {title}
      </text>
      <g filter="url(#filter1_i_157_7394)">
        <rect x="8" y="23" width="149" height="80" fill="#B5B5B5" fillOpacity="0.5" />
      </g>
      <g filter="url(#filter2_i_157_7394)">
        <rect x="9" y="23" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[0]]]} />
        <rect x="9" y="37" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[1]]]} />
        <rect x="9" y="51" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[2]]]} />
        <rect x="9" y="65" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[3]]]} />
        <rect x="9" y="79" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[4]]]} />
        <rect x="9" y="93" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[5]]]} />
      </g>
      <g filter="url(#filter3_i_157_7394)">
        <rect x="86" y="23" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[6]]]} />
        <rect x="86" y="37" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[7]]]} />
        <rect x="86" y="51" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[8]]]} />
        <rect x="86" y="65" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[9]]]} />
        <rect x="86" y="79" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[10]]]} />
        <rect x="86" y="93" width="70" height="10" fill={PLANT_STACK_STATUS_COLOR[PLANT_STACK_STATUS[status[11]]]} />
      </g>
      <defs>
        <filter
          id="filter0_d_157_7394"
          x="0"
          y="0"
          width="166"
          height="117"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_157_7394" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_157_7394" result="shape" />
        </filter>
        <filter
          id="filter1_i_157_7394"
          x="8"
          y="23"
          width="150"
          height="81"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_157_7394" />
        </filter>
        <filter
          id="filter2_i_157_7394"
          x="9"
          y="23"
          width="70"
          height="84"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilterss="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_157_7394" />
        </filter>
        <filter
          id="filter3_i_157_7394"
          x="86"
          y="23"
          width="70"
          height="84"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_157_7394" />
        </filter>
      </defs>
    </svg>
  )
}
