import * as React from "react"
import _ from "lodash"
import "./stackDialogContent.scss"
import { MainImage } from "../type/system"
import EELoading from "./Loading"
import { SYSTEM_NODE_STATUS, SYSTEM_NODE_STATUS_COLOR, SYSTEM_NODE_STATUS_TEXT } from "../constant"

import { useAppContext } from "../context/AppContext"
import { Battery } from "../assets/images/battery.js"
import { StackStatus } from "../common/systemData.js"
interface IStackDialogContentProps {
  stackInfo: MainImage[]
  stackId: number
}

const StackDialogContent: React.FunctionComponent<IStackDialogContentProps> = ({ stackInfo, stackId }) => {
  const appContext = useAppContext()
  const { isDarkMode } = appContext
  const [newStackInfo, setNewStackInfo] = React.useState<MainImage[]>([])
  React.useEffect(() => {
    if (stackInfo !== null) {
      console.log("stackInfo", stackInfo)
      const stackInfoArray: any = []
      _.times(32, (index) => {
        if (stackInfo[index]) {
          stackInfoArray.push(stackInfo[index])
        } else {
          stackInfoArray.push({
            nodeStatus: StackStatus.nodata,
            soc: 0,
            node: index + 1,
            alert: 0,
            warning: 0,
            critical: 0,
          })
        }
      })
      setNewStackInfo(stackInfoArray)
    }
  }, [stackInfo])

  // if (!stackInfo) {
  //   return (
  //     <div className="stack-dialog-content-wrapper">
  //       <EELoading />
  //     </div>
  //   )
  // }
  if (stackInfo === null) {
    return (
      <div className="stack-dialog-content-wrapper">
        <div
          style={{
            color: "var(--tooltip-text",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          no data
        </div>
      </div>
    )
  }
  return (
    <div className="stack-dialog-content-wrapper">
      <div className="stack-dialog-node-wrapper">
        {newStackInfo?.map((item, index) => {
          if (index < 8) {
            if (item.nodeStatus === StackStatus.nodata) {
              return <div className="battery" key={index}></div>
            }
            return (
              <div className="battery" key={index}>
                <div className="battery-text">{item.soc ? `${item.soc}%` : ""}</div>
                <div className="battery-label">
                  {" "}
                  <span
                    style={{ marginLeft: "8px", fontSize: "12px", marginTop: "-2px", display: "block", float: "left" }}
                  >
                    {SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[item.nodeStatus]] ||
                      SYSTEM_NODE_STATUS_TEXT.NUM_CONTEXTS}
                  </span>
                  {item.node}
                </div>
                <Battery
                  fill={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                  stokeColor={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                  width={item.soc}
                  isDark={isDarkMode}
                />
              </div>
            )
          }
          return ""
        })}
      </div>
      <div className="stack-dialog-node-wrapper">
        {newStackInfo.map((item, index) => {
          if (index > 7 && index < 16) {
            if (item.nodeStatus === StackStatus.nodata) {
              return <div className="battery" key={index}></div>
            }
            return (
              <div className="battery" key={index}>
                <div className="battery-text">{item.soc ? `${item.soc}%` : ""}</div>
                <div className="battery-label">
                  <span
                    style={{ marginLeft: "8px", fontSize: "12px", marginTop: "-2px", display: "block", float: "left" }}
                  >
                    {SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                  </span>
                  {item.node}
                </div>
                <Battery
                  fill={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                  stokeColor={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                  width={item.soc}
                  isDark={isDarkMode}
                />
              </div>
            )
          }
          return ""
        })}
      </div>
      <div className="stack-dialog-node-wrapper">
        {newStackInfo &&
          newStackInfo.map((item, index) => {
            const isAlarm = item.alert || item.warning || item.critical
            if (index > 15 && index < 24) {
              if (item.nodeStatus === StackStatus.nodata) {
                return <div className="battery" key={index}></div>
              }
              return (
                <div className="battery" key={index}>
                  <div className="battery-text">{item.soc ? `${item.soc}%` : ""}</div>

                  <div className="battery-label">
                    {" "}
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "12px",
                        marginTop: "-2px",
                        display: "block",
                        float: "left",
                      }}
                    >
                      {SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                    </span>
                    {item.node}
                  </div>
                  <Battery
                    fill={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                    stokeColor={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                    width={item.soc}
                    isDark={isDarkMode}
                  />
                </div>
              )
            }
            return ""
          })}
      </div>
      <div className="stack-dialog-node-wrapper">
        {newStackInfo &&
          newStackInfo.map((item, index) => {
            if (index > 23) {
              if (item.nodeStatus === StackStatus.nodata) {
                return <div className="battery" key={index}></div>
              }
              return (
                <div className="battery" key={index}>
                  <div className="battery-text">{item.soc ? `${item.soc}%` : ""}</div>
                  <div className="battery-label">
                    {" "}
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "12px",
                        marginTop: "-2px",
                        display: "block",
                        float: "left",
                      }}
                    >
                      {SYSTEM_NODE_STATUS_TEXT[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                    </span>
                    {item.node}
                  </div>
                  <Battery
                    fill={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                    stokeColor={SYSTEM_NODE_STATUS_COLOR[SYSTEM_NODE_STATUS[item.nodeStatus]]}
                    width={item.soc}
                    isDark={isDarkMode}
                  />
                </div>
              )
            }
            return ""
          })}
      </div>
    </div>
  )
}

export default StackDialogContent
