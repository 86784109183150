import { Box } from "@mui/material"
import * as React from "react"
import LeftMenu from "../component/LeftMenu"
import "./adminLayout.scss"

interface IAdminLayoutProps {
  children: React.ReactNode
}

const AdminLayout: React.FunctionComponent<IAdminLayoutProps> = ({ children }) => {
  return (
    <div className="admin-page-layout-wrapper">
      <LeftMenu />
      <div className="admin-page-child-wrapper"> {children}</div>
    </div>
  )
}

export default AdminLayout
