import React from "react"
import URLs from "./urls"
import { Outlet, useRoutes } from "react-router-dom"
import PrivateLayout from "../layout/PrivateLayout"
import AdminLayout from "../layout/AdminLayout"
import Login from "../container/Auth/Login"
import ForgetPassword from "../container/Auth/ForgetPassword"
import Plant from "../container/OperateAndManager/Plant"
import SystemPage from "../container/OperateAndManager/SystemPage"
import EEPerformance from "../container/OperateAndManager/Performance"
import SOX from "../container/OperateAndManager/SOX"
import SOXDetail from "../container/OperateAndManager/SOXDetail"
import RXL from "../container/OperateAndManager/RXL"
// import EERequestList from "../container/Operator/EERequestList"
import Profile from "../container/Auth/Profile"
import AdminMembers from "../container/Admin/AdminMembers"
import AdminMain from "../container/Admin/AdminMain"
import OpertaionSystemPage from "../container/OperateAndManager/OperationSystemPage"
import TestScreen from "../container/TestScreen"

export function useAppRoutes() {
  return useRoutes([
    {
      element: <Outlet />,
      children: [
        { path: "/testscreen", element: <TestScreen /> },
        { path: URLs.Login, element: <Login /> },
        { path: URLs.ForgetPassword, element: <ForgetPassword /> },
      ],
    },
    {
      element: (
        <PrivateLayout>
          <Outlet />
        </PrivateLayout>
      ),
      children: [
        { path: URLs.Plant, element: <Plant /> },
        { path: URLs.Home, element: <Plant /> },
        { path: URLs.System, element: <SystemPage /> },
        { path: URLs.Report, element: <SOX /> },
        { path: URLs.OperationSystem, element: <OpertaionSystemPage /> },
        { path: URLs.ReportDetail, element: <SOXDetail /> },
        { path: URLs.Performance, element: <EEPerformance /> },
        // { path: URLs.OperatorRequest, element: <EERequestList /> },
        { path: URLs.Profile, element: <Profile /> },
        { path: URLs.RXL, element: <RXL /> },
        // {
        //   path: "*",
        //   element: <Navigate to={URLs.System} />,
        // },
      ],
    },
    {
      element: (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        { path: URLs.AdminMain, element: <AdminMain /> },
        { path: URLs.AdminMembers, element: <AdminMembers /> },
      ],
    },
  ])
}
