import * as React from "react"
import "./container.scss"
import { StackStatusColor } from "../common/systemData"
import { ContainerSVG } from "../assets/ContainerSVG.js"
import { PLANT_AC_STATUS, PLANT_AC_STATUS_COLOR } from "../constant"

interface IContainerProps {
  status: any
  id: string
  isDarkMode: boolean
}

const Container: React.FunctionComponent<IContainerProps> = ({ status, id, isDarkMode }) => {
  const isAlarm = status.alert > 0 || status.warning > 0 || status.critical > 0 ? true : false

  const [isMouseover, setIsMouseover] = React.useState(false)
  return (
    <div className="container-container-wrapper">
      <div
        className="container-container-element-wrapper"
        id={id}
        // onMouseOver={() => {
        //   setIsMouseover(true)
        // }}
        // onMouseLeave={() => {
        //   setIsMouseover(false)
        // }}
      >
        <div className="container-info-hover" id={`${id}-info`} style={{ display: isMouseover ? "flex" : "none" }}>
          <span>
            <strong>SoC</strong> : {Math.round(status.soc * 10000) / 100}%
          </span>
          <span>
            <strong>Critical</strong> : {status.critical}
          </span>
          <span>
            <strong>Alerts</strong> : {status.alert}
          </span>
          <span>
            <strong>Warning</strong> : {status.warning}
          </span>
        </div>
        <ContainerSVG
          status={status.stackStatus}
          fill={PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[status.stackStatus[0]]]}
          title={status.displayName}
          isAlarm={isAlarm}
          isDarkMode={isDarkMode}
        />
      </div>
      {/* <div className="container-element" sx={{ background: StackStatusColor[status.status] }}></div>
      <div className="container-element" sx={{ background: StackStatusColor[status.status] }}></div>
      <div className="container-element" sx={{ background: StackStatusColor[status.status] }}></div>
      <div className="container-element" sx={{ background: StackStatusColor[status.status] }}></div>
      <div className="container-element" sx={{ background: StackStatusColor[status.status] }}></div>
      <div className="container-top"></div> */}
    </div>
  )
}

export default Container
