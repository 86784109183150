const Home = "/"
const System = "/system"
const Plant = "/plant"
const OperationSystem = "/operation/system"
const Performance = "/performance"
const Report = "/sox"
const ReportDetail = "/sox/detail"
const Login = "/login"
const ForgetPassword = "/forgetpassword"
const Regist = "/regist"
const OperatorRequest = "/operatorRequest"
const AdminMain = "/admin"
const AdminMembers = "/admin/members"
const Profile = "/profile"
const RXL = "/rxl"

const URLs = {
  Home,
  System,
  Report,
  ReportDetail,
  Login,
  ForgetPassword,
  Regist,
  Performance,
  OperatorRequest,
  AdminMain,
  AdminMembers,
  Profile,
  RXL,
  Plant,
  OperationSystem,
}
export default URLs
