import * as React from "react"
import EEFloatingButton from "../component/EEFloatingButton"
import { useEffect, useRef, useState } from "react"
import { interval } from "d3"
import _ from "lodash"

interface IOperationDemoProps {
  height: any
  isScroll?: boolean
  intervalTime?: number
  handleScroll: () => void
}

const OperationDemo: React.FunctionComponent<IOperationDemoProps> = ({
  height,
  isScroll,
  intervalTime,
  handleScroll,
}) => {
  const landingRef = useRef(null)
  const [activeEle, setActiveEle] = useState("")
  const intervalRef = useRef(null)
  const [playCount, setPlayCount] = useState(0)

  // function buildThresholdList() {
  //   let thresholds = []
  //   // let numSteps = 20

  //   for (let i = 1.0; i <= intervalTime; i++) {
  //     let ratio = i / intervalTime
  //     thresholds.push(ratio)
  //   }

  //   thresholds.push(0)
  //   return thresholds
  // }
  useEffect(() => {
    const sections = ["landing", "system", "container", "stack", "node"].map((id) => document.getElementById(id))

    console.log("t", sections)
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0, // Trigger when 50% of the section is in view
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log("entry.", entry)
        console.log("entry.intersectionRatio", entry.intersectionRatio)
        // let ratio = entry.intersectionRatio

        // if (ratio >= 1) {
        //   setActiveEle(entry.target.id)
        // }
        // if (ratio <= 0) {
        //   const sectionList = ["landing", "system", "container", "stack", "node"]
        //   const sectionIndex = sectionList.findIndex((section) => {
        //     return section === entry.target.id
        //   })
        //   console.log("sectionIndex", sectionIndex)
        //   return setActiveEle(sectionList[sectionIndex + 1])
        // }
        if (entry.isIntersecting) {
          setActiveEle(entry.target.id)
        }
      })
    }, observerOptions)

    sections.forEach((sectionElement) => {
      observer.observe(sectionElement)
    })

    // Cleanup observer on component unmount
    return () => {
      sections.forEach((sectionElement) => {
        observer.unobserve(sectionElement)
      })
    }
  }, [])
  const startInterval = () => {
    const container = landingRef.current
    const scrollStep = 1 // Adjust scroll step to control speed
    console.log(intervalTime)

    intervalRef.current = setInterval(() => {
      // Check if we've reached the bottom of the container
      if (container.scrollTop + container.clientHeight >= container.scrollHeight || isScroll === false) {
        handleScroll()
        clearInterval(intervalRef.current) // Stop scrolling when at the bottom
      } else {
        container.scrollTop += scrollStep // Scroll down by the step
      }
    }, intervalTime)
  }
  const stopInterval = () => {
    clearInterval(intervalRef.current)
  }
  useEffect(() => {
    setPlayCount(0)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])
  useEffect(() => {
    if (isScroll) {
      if (playCount === 0) {
        setPlayCount(1)
        setTimeout(() => {
          startInterval()
        }, 7000)
      } else {
        setPlayCount(1)
        startInterval()
      }
    }
    if (!isScroll) {
      setPlayCount(1)
      stopInterval()
    }
  }, [isScroll])

  return (
    <div style={{ width: "100%", height: height, overflowX: "hidden", position: "relative" }} ref={landingRef}>
      <EEFloatingButton
        position={{ top: "120px", left: "33%" }}
        dotAnimation={true}
        option={[
          {
            onClick: () => {
              const section = document.getElementById("landing")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              }
            },
            icon: <span>Plant</span>,
            isActive: activeEle === "landing",
          },
          {
            onClick: () => {
              const section = document.getElementById("system")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              }
            },
            icon: <span>System</span>,
            isActive: activeEle === "system",
          },
          {
            onClick: () => {
              const section = document.getElementById("container")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              }
            },
            icon: <span>Container</span>,
            isActive: activeEle === "container",
          },
          {
            onClick: () => {
              const section = document.getElementById("stack")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              }
            },
            icon: <span>Stack</span>,
            isActive: activeEle === "stack",
          },
          {
            onClick: () => {
              const section = document.getElementById("node")
              if (section) {
                section.scrollIntoView({ behavior: "smooth" })
              }
            },
            icon: <span>Node</span>,
            isActive: activeEle === "node",
          },
        ]}
      />
      <div id="landing" style={{ marginBottom: "24px" }}>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            width: "100%",
            height: "200px",
            backgroundColor: "#92f7ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          Plant
        </div>
        <iframe
          src="https://dev.clbcloud.com/d/ddfaf51mpavpcb/inertia-landing?orgId=1"
          width="100%"
          height="2130px"
          style={{ border: "none", marginTop: "-200px", position: "relative" }}
        ></iframe>
      </div>

      <div id="system" style={{ marginBottom: "24px" }}>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            width: "100%",
            height: "250px",
            backgroundColor: "#92f7ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          System
        </div>
        <iframe
          src="https://dev.clbcloud.com/d/adfaeu7jln9c0a/1a-inertia-operations-system?orgId=1&var-acid=12F0E002&var-stack=5&var-node=$%7Bnode%7D&var-data_source=PROD&from=now-7d&to=now"
          width="100%"
          height="6300px"
          // style={{ border: "none", position: "relative", zIndex: -10, top: "-350px" }}
          style={{ border: "none", position: "relative", marginTop: "-250px" }}
        ></iframe>
      </div>

      <div id="container" style={{ marginBottom: "24px" }}>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            width: "100%",
            height: "250px",
            backgroundColor: "#92f7ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          Container
        </div>
        <iframe
          src="https://dev.clbcloud.com/d/edfaevfww7ta8b/1b-inertia-operations-container?orgId=1&var-acid=12F0E009&var-stack=5&var-node=$%7Bnode%7D&var-data_source=PROD&from=now-7d&to=now"
          width="100%"
          height="3380px"
          style={{ border: "none", position: "relative", marginTop: "-250px" }}
        ></iframe>
      </div>

      <div id="stack" style={{ marginBottom: "24px" }}>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            width: "100%",
            height: "270px",
            backgroundColor: "#92f7ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          Stack
        </div>
        <iframe
          src="https://dev.clbcloud.com/d/cdfaewq8nx8g0b/1c-inertia-operations-stack?orgId=1&var-acid=12F0E018&var-stack=8&var-node=$%7Bnode%7D&var-data_source=PROD&from=now-7d&to=now"
          width="100%"
          height="4780px"
          style={{ border: "none", position: "relative", marginTop: "-270px" }}
        ></iframe>
      </div>

      <div id="node" style={{ marginBottom: "24px" }}>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            width: "100%",
            height: "270px",
            backgroundColor: "#92f7ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2.5rem",
            fontWeight: "bold",
          }}
        >
          Node
        </div>
        <iframe
          src="https://dev.clbcloud.com/d/bdfaexm0sey2od/1d-inertia-operations-node?orgId=1&var-acid=12F0E018&var-stack=8&var-node=4&var-data_source=PROD&from=now-7d&to=now"
          width="100%"
          height="2240px"
          style={{ border: "none", position: "relative", marginTop: "-270px" }}
        ></iframe>
      </div>
    </div>
  )
}

export default OperationDemo
