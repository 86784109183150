import * as React from "react"
import { useEffect, useRef, useState } from "react"
import * as d3 from "d3"
import dayjs from "dayjs"
import _ from "lodash"
import {
  chartLevel,
  operationStatus,
  PLANT_AC_STATUS,
  PLANT_AC_STATUS_COLOR,
  PLANT_AC_STATUS_KEY,
  PLANT_AC_STATUS_LEGEND,
  PLANT_AC_STATUS_TEXT,
  statusColor,
  SYSTEM_NODE_STATUS,
  SYSTEM_NODE_STATUS_COLOR,
  SYSTEM_NODE_STATUS_LEGEND,
  SYSTEM_NODE_STATUS_TEXT,
  SYSTEM_STACK_STATUS,
  SYSTEM_STACK_STATUS_COLOR,
  SYSTEM_STACK_STATUS_LEGEND,
  SYSTEM_STACK_STATUS_TEXT,
} from "../../constant"
import { EETooltip } from "./EETooltip"

interface IEETimelineChartProps {
  data: any
  yDomain: []
  level: number
  period?: number
}

const EETimelineChart: React.FunctionComponent<IEETimelineChartProps> = ({ data, yDomain, level, period }) => {
  const chartRef = useRef(null)
  const margin = { top: 20, right: 30, bottom: 30, left: 40 }
  const [width, setWidth] = useState(level === 0 ? 900 : window.innerWidth - margin.left - margin.right - 200)
  const height = level === chartLevel.stack ? 800 - margin.top - margin.bottom : 500 - margin.top - margin.bottom
  const boundsHeight = height - margin.top - margin.bottom
  const [interactionData, setInteractionData] = useState<any>()
  const parentElementDIV = useRef(null)

  const handleResize = () => {
    const { offsetWidth } = parentElementDIV.current
    if (level === 0) {
      return setWidth(1000)
    }
    setWidth(offsetWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      const yScaleRange =
        level === chartLevel.stack
          ? [boundsHeight - 20, 30]
          : level === 0
          ? [boundsHeight - 10, 15]
          : [boundsHeight - 20, 45]
      const xAccessor = (d) => dayjs(d["time"])
      const startDate = dayjs(data[0].values[0].start)
      const endDate = dayjs(data[0].values[data[0].values.length - 1].end)
      const xScale = d3.scaleTime().domain([startDate, endDate]).range([42, width])
      const xAxisGenerator = d3
        .axisBottom(xScale)
        .tickFormat(d3.timeFormat(level === 0 ? (period === 1 ? "%m/%d %H:%M" : "%Y/%m/%d") : "%Y/%m/%d %H:%M"))

      const yScale = d3
        .scaleBand()
        .domain(yDomain)
        .range(yScaleRange)
        // .paddingOuter(1)
        .paddingInner(1)
      const yAxisGenerator = d3.axisLeft(yScale)
      const xAxisGrid = d3
        .axisBottom(xScale)
        .tickSize(-boundsHeight - 0)
        .tickFormat("")
      const wrapper = d3.select(chartRef.current)

      wrapper.selectAll("*").remove()
      wrapper
        .attr("width", width)
        .attr("height", height)
        .attr("transform", `translate(${[margin.left, margin.top].join(",")})`)
      const bounds = wrapper.append("g")

      const productionAreaGenerator = d3
        .area()
        .x((d) => xScale(xAccessor(d)))
        .y0(0)
        .y1(level === chartLevel.stack ? 15 : level === 0 ? 12 : 22)

      data.map((d, index) => {
        // console.log("d checkckckckck", d)

        const newGroup = d.values.reduce((acc, obj) => {
          // console.log("acc", acc)
          if (!acc[obj.start]) {
            acc[obj.start] = []
          }
          acc[obj.start].push(obj)
          return acc
        }, {})
        const newGroupArray = Object.keys(newGroup)
        // console.log("newGroupcheck", newGroup)
        const bound = wrapper
          .append("g")
          .attr("transform", `translate(${[10, d.translate]})`)
          .join(",")
        const generateTimeLine = () => {
          if (level === 0) {
            newGroupArray.map((item, idx) => {
              bound
                .append("path")
                .attr("d", productionAreaGenerator(newGroup[item]))
                .attr(
                  "fill",
                  newGroup[item][0].value === null
                    ? PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[PLANT_AC_STATUS_KEY.INVALID]]
                    : PLANT_AC_STATUS_COLOR[newGroup[item][0].value]
                )
                .on("mouseover", function (e) {
                  const mouse = d3.pointer(e)
                  const bisect = d3.bisector(function (d) {
                    return d.time
                  }).right

                  const closestIndex = bisect(d.values, xScale.invert(mouse[0]))

                  setInteractionData({
                    xPos: mouse[0] - 100,
                    yPos: mouse[1] + 50,
                    color:
                      newGroup[item][0].value === null
                        ? PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[PLANT_AC_STATUS_KEY.INVALID]]
                        : PLANT_AC_STATUS_COLOR[newGroup[item][0].value],
                    x: null,
                    y: newGroup[item][0].value === null ? "Invalid" : newGroup[item][0].value,
                    name: (
                      <div>
                        {d.name}:<br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {dayjs(d.values[closestIndex].start).format("YYYY-MM-DD HH:mm:ss")}
                        </span>
                        <br />~<span>{dayjs(d.values[closestIndex].end).format("YYYY-MM-DD HH:mm:ss")} </span>
                      </div>
                    ),
                    status: PLANT_AC_STATUS_TEXT[newGroup[item][0].value],
                  })
                })
                .on("mouseleave", () => {
                  setInteractionData(null)
                })
              return "done"
            })
          } else if (level === 2) {
            newGroupArray.map((item, index) => {
              bound
                .append("path")
                .attr("d", productionAreaGenerator(newGroup[item]))
                .attr(
                  "fill",
                  newGroup[item][0].value === null
                    ? PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[PLANT_AC_STATUS_KEY.INVALID]]
                    : SYSTEM_STACK_STATUS_COLOR[newGroup[item][0].value]
                )
                .on("mouseover", function (e) {
                  const mouse = d3.pointer(e)
                  const bisect = d3.bisector(function (d) {
                    return d.time
                  }).right
                  //  const xPos = cursorPixelPosition[0]
                  const closestIndex = bisect(data[0].values, xScale.invert(mouse[0]))
                  // const filteredData = _.filter(d.values, { value: item })
                  setInteractionData({
                    xPos: mouse[0] + 50,
                    yPos: mouse[1] + 20,
                    color:
                      newGroup[item][0].value === null
                        ? PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[PLANT_AC_STATUS_KEY.INVALID]]
                        : SYSTEM_STACK_STATUS_COLOR[newGroup[item][0].value],
                    x: null,
                    y: newGroup[item][0].value === null ? "Invalid" : SYSTEM_STACK_STATUS_TEXT[newGroup[item][0].value],
                    name: (
                      <div>
                        {d.name}:<br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {dayjs(d.values[closestIndex].start).format("YYYY-MM-DD hh:mm:ss")}
                        </span>
                        <br />~<span>{dayjs(d.values[closestIndex].end).format("YYYY-MM-DD hh:mm:ss")} </span>
                      </div>
                    ),
                    status: SYSTEM_STACK_STATUS_TEXT[newGroup[item][0].value],
                  })
                })
                .on("mouseleave", () => {
                  setInteractionData(null)
                })
              return "done"
            })
          } else {
            newGroupArray.map((item, index) => {
              bound
                .append("path")
                .attr("d", productionAreaGenerator(newGroup[item]))
                .attr(
                  "fill",
                  newGroup[item][0].value === null
                    ? PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[PLANT_AC_STATUS_KEY.INVALID]]
                    : SYSTEM_NODE_STATUS_COLOR[newGroup[item][0].value]
                )
                .on("mouseover", function (e) {
                  const mouse = d3.pointer(e)
                  const bisect = d3.bisector(function (d) {
                    return d.time
                  }).right
                  //  const xPos = cursorPixelPosition[0]
                  const closestIndex = bisect(data[0].values, xScale.invert(mouse[0]))
                  setInteractionData({
                    xPos: mouse[0] + 50,
                    yPos: mouse[1] + 20,
                    color:
                      newGroup[item][0].value === null
                        ? PLANT_AC_STATUS_COLOR[PLANT_AC_STATUS[PLANT_AC_STATUS_KEY.INVALID]]
                        : SYSTEM_NODE_STATUS_COLOR[newGroup[item][0].value],
                    x: null,
                    y: newGroup[item][0].value === null ? "Invalid" : SYSTEM_NODE_STATUS_TEXT[newGroup[item][0].value],
                    name: (
                      <div>
                        {d.name}:<br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {dayjs(d.values[closestIndex].start).format("YYYY-MM-DD hh:mm:ss")}
                        </span>
                        <br />~<span>{dayjs(d.values[closestIndex].end).format("YYYY-MM-DD hh:mm:ss")} </span>
                      </div>
                    ),
                    status: item,
                  })
                })
                .on("mouseleave", () => {
                  setInteractionData(null)
                })
              return "done"
            })
          }
        }
        generateTimeLine()
        return null
      })
      bounds
        .append("g")
        .attr("class", "_x_axis")
        .attr("transform", "translate(10," + boundsHeight + ")")
        .call(xAxisGenerator)
        .selectAll("text")

      bounds.append("g").attr("transform", `translate(${50},0)`).call(yAxisGenerator)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width])

  return (
    <div>
      <div style={{ position: "relative" }} ref={parentElementDIV}>
        <EETooltip interactionData={interactionData} />

        <svg
          ref={chartRef}
          style={{
            color: "var(--chart-axis-line)",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        ></svg>
        {/* </div> */}
      </div>
    </div>
  )
}

export default EETimelineChart
