// LineChart.js
import React, { useRef, useEffect, useState } from "react"
import * as d3 from "d3"
import { EETooltip } from "./EETooltip"
import dayjs from "dayjs"
type EEOperationChartProps = {
  data: any
  handleBrushed?: (timeRange) => boolean
  minPoint: number
  maxPoint: number
  divId: string
  multiAxis?: boolean
  scale?: []
  type: string
  range?: [number, number]
  multi?: boolean
  brush?: boolean
}
const EEOperationChart: React.FunctionComponent<EEOperationChartProps> = ({
  data,
  handleBrushed,
  minPoint,
  maxPoint,
  divId,
  multiAxis = false,
  scale = ["V", "%"],
  type,
  multi = false,
  brush = true,
}) => {
  const chartRef = useRef(null)
  const parentElementDIV = useRef(null)
  const tooltipRef = useRef(null)
  const chartMiniRef = useRef(null)
  const brushRef = useRef(null)
  const axisSvg = useRef(null)
  const margin = { top: 20, right: 30, bottom: 30, left: 40 }
  const [width, setWidth] = useState(
    multi ? window.innerWidth / 2 - 200 : window.innerWidth - margin.left - margin.right - 200
  )
  const height = 500 - margin.top - margin.bottom
  // const boundsWidth = width - margin.right - margin.left
  const boundsHeight = height - margin.top - margin.bottom
  const [interactionData, setInteractionData] = useState<any>()

  const handleResize = () => {
    const { offsetWidth } = parentElementDIV.current

    setWidth(multi ? window.innerWidth / 2 - 150 : offsetWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Define scales
    if (data && data[0]) {
      const xScale = type === "cycle" ? d3.scaleLinear() : d3.scaleTime()
      const getClosestPoint = (cursorPixelPosition: number) => {
        const resultArray: any = []
        let closestIndex

        if (type === "cycle") {
          const bisect = d3.bisector(function (d) {
            return d.cycle
          }).right
          const xPos = cursorPixelPosition[0]
          closestIndex = bisect(data[0].values, xScale.invert(xPos))
        } else {
          const bisect = d3.bisector(function (d) {
            return d.time
          }).right
          const xPos = cursorPixelPosition[0]
          closestIndex = bisect(data[0].values, xScale.invert(xPos))
        }
        if (closestIndex) {
          data.map((item) => {
            return resultArray.push({ value: item.values[closestIndex - 1], color: item.color, name: item.name })
          })
        }
        return resultArray
      }
      if (type === "cycle") {
        xScale.domain([0, data[0].values[data[0].values.length - 1].cycle]).range([40, width])
      } else {
        xScale.domain([data[0].values[0].time, data[0].values[data[0].values.length - 1].time]).range([40, width])
      }
      const brushended = (value1, value2) => {
        if (handleBrushed) {
          handleBrushed({ start: dayjs(xScale.invert(value1)), end: dayjs(xScale.invert(value2)) })
        }
      }
      const yScale = d3.scaleLinear().domain([minPoint, maxPoint]).range([boundsHeight, 10])
      const yRightScale = d3.scaleLinear()
      if (multiAxis) {
        yRightScale.domain([minPoint, maxPoint]).range([boundsHeight, 0])
      }
      const context = d3
        .select(chartRef.current)
        .attr("width", width)
        .attr("height", boundsHeight)
        .attr("class", "canvas-plot")
        .node()
        .getContext("2d")
      const contextTooltip = d3
        .select(tooltipRef.current)
        .attr("width", width)
        .attr("height", boundsHeight)
        .node()
        .getContext("2d")
      const line = d3
        .line()
        .x((d) => {
          return xScale(type === "cycle" ? d.cycle : d.time)
        })
        .y((d) => {
          return yScale(d.value)
        })
        .context(context)
      const svg = d3.select(chartMiniRef.current).attr("width", width).attr("height", boundsHeight)
      const svgAxis = d3
        .select(axisSvg.current)
        .attr("width", width)
        .attr("height", height + margin.top + margin.bottom)

      const xAxisGenerator =
        type === "cycle" ? d3.axisBottom(xScale) : d3.axisBottom(xScale).tickFormat(d3.timeFormat("%Y/%m/%d %H:%M"))

      const yAxisGenerator = d3.axisLeft(yScale).tickFormat((d) => `${d}${scale[0]}`)
      const xAxisGrid = d3.axisBottom(xScale).tickSize(-boundsHeight).tickFormat("")
      const yAxisGrid = d3.axisLeft(yScale).tickSize(-width).tickFormat("")

      svg.selectAll("*").remove()
      svgAxis.selectAll("*").remove()
      if (brush) {
        const brushArea = d3.select(brushRef.current)
        brushArea
          .attr("class", "brush-area")
          .attr("width", width)
          .attr("height", boundsHeight)
          .on("mousedown", function (clickEvent: React.MouseEvent<Element, MouseEvent>) {
            const pos = d3.pointer(clickEvent)
            const deltaX1 = pos[0]
            let deltaX2 = 0
            const bruchRect = brushArea
              .append("div")
              .attr("class", "brush-rect")
              .attr("height", boundsHeight)
              .style("position", "absolute")
            const mouseMoveHandler = (moveEvent: MouseEvent) => {
              const mouse = d3.pointer(moveEvent)
              if (deltaX1 < mouse[0]) {
                bruchRect
                  .style("top", "20px")
                  .style("left", `${Math.abs(deltaX1)}px`)
                  .style("width", `${Math.abs(deltaX1 - mouse[0]) - 45}px`)
                  .style("height", `${boundsHeight}px`)
                  .style("background-color", "#000")
                  .style("opacity", 0.3)
                  .style("z-index", 1000)
              } else {
                bruchRect
                  .style("top", "20px")
                  .style("left", `${Math.abs(mouse[0])}px`)
                  .style("width", `${Math.abs(mouse[0] - deltaX1) - 45}px`)
                  .style("height", `${boundsHeight}px`)
                  .style("background-color", "#000")
                  .style("opacity", 0.3)
                  .style("z-index", 1000)
              }
            }

            const mouseUpHandler = (e: MouseEvent) => {
              const position = d3.pointer(e)
              deltaX2 = position[0]
              document.removeEventListener("mousemove", mouseMoveHandler)
              if (deltaX2 > deltaX1) {
                brushended(Math.abs(deltaX1), Math.abs(deltaX2) - 45)
                bruchRect.remove("*")
              } else {
                brushended(Math.abs(deltaX2) - 45, Math.abs(deltaX1))
              }
            }

            document.addEventListener("mousemove", mouseMoveHandler)
            document.addEventListener("mouseup", mouseUpHandler, { once: true })
          })
      }

      svgAxis
        .append("g")
        .attr("class", "_x_axis")
        .attr("transform", "translate(0," + boundsHeight + ")")
        .call(xAxisGenerator)
      // .selectAll("text")
      // .style("text-anchor", "end")
      // .attr("dx", "-.8em")
      // .attr("dy", ".15em")
      // .attr("transform", "rotate(-20)")
      svgAxis
        .append("g")
        .attr("class", "x-axis-grid")
        .attr("transform", "translate(0," + boundsHeight + ")")
        .attr("stroke-width", 0.5)
        .attr("opacity", 0.3)
        .call(xAxisGrid)

      const yLeftAxis = svgAxis.append("g").attr("transform", `translate(${40},0)`).call(yAxisGenerator)
      svgAxis
        .append("g")
        .attr("transform", `translate(${40},0)`)
        .attr("class", "y-axis-grid")
        .attr("stroke-width", 0.5)
        .attr("opacity", 0.3)
        .call(yAxisGrid)
      if (multiAxis) {
        const yRightAxisGenerator = d3.axisLeft(yRightScale).format((d) => `${d}${scale[1]}`)
        const yRightAxis = svgAxis.append("g").attr("transform", `translate(${40},0)`).call(yRightAxisGenerator)
        yLeftAxis.selectAll("path,line").remove()
        yRightAxis.selectAll("path,line").remove()
      }
      const svgGroup = svg.append("g")

      var transpRect = svgGroup.append("rect").attr("width", width).attr("height", boundsHeight).attr("opacity", 0)

      var verticalLine = svgGroup
        .append("line")
        .attr("opacity", 0)
        .attr("y1", 0)
        .attr("y2", boundsHeight)
        .attr("stroke", "var(--chart-axis-line)")
        .attr("stroke-width", 0.5)
        .attr("pointer-events", "none")

      var horizontalLine = svgGroup
        .append("line")
        .attr("opacity", 0)
        .attr("x1", 0)
        .attr("x2", width)
        .attr("stroke", "var(--chart-axis-line)")
        .attr("stroke-width", 0.5)
        .attr("pointer-events", "none")

      transpRect
        .on("mousemove", function (e) {
          const mouse = d3.pointer(e)
          verticalLine.attr("x1", mouse[0]).attr("x2", mouse[0]).attr("opacity", 1)
          horizontalLine.attr("y1", mouse[1]).attr("y2", mouse[1]).attr("opacity", 1)
          setInteractionData(null)
          contextTooltip.clearRect(0, 0, width, boundsHeight + 10)
          const result: any = getClosestPoint(mouse)
          // contextTooltip.reset()

          if (result && result.length > 0) {
            const arrayTooltip: any = []
            // eslint-disable-next-line array-callback-return
            result.map((item) => {
              arrayTooltip.push({
                xPos: mouse[0] + 30,
                yPos: mouse[1] + 20,
                color: item.color,
                x:
                  type === "cycle" ? `Cycle ${item.value.cycle}` : dayjs(item.value.time).format("YYYY/MM/DD HH:mm:ss"),
                y: `${item.value.value}${scale[0]}`,
                name: item.name,
              })

              contextTooltip.beginPath()
              contextTooltip.arc(
                xScale(type === "cycle" ? item.value.cycle : item.value.time),
                yScale(item.value.value),
                5,
                0,
                2 * Math.PI
              )
              contextTooltip.fillStyle = item.color
              contextTooltip.fill()
            })
            setInteractionData(arrayTooltip)
          }
        })
        .on("mouseout", function () {
          verticalLine.attr("opacity", 0)
          horizontalLine.attr("opacity", 0)
          setInteractionData(null)
          contextTooltip.clearRect(0, 0, width, boundsHeight + 10)
        })
      // eslint-disable-next-line array-callback-return
      data.map((item, index) => {
        if (item.values.length > 0) {
          context.beginPath()
          line(item.values)
          context.lineWidth = 0.5
          context.strokeStyle = item.color
          context.stroke()
          // eslint-disable-next-line array-callback-return
          // item.values.map((d) => {
          //   context.beginPath()
          //   context.arc(xScale(type === "cycle" ? d.cycle : d.time), yScale(d.value), 1, 0, 2 * Math.PI)
          //   context.fillStyle = item.color
          //   context.fill()
          //   context.closePath()
          // })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width])

  return (
    <div
      style={{ position: "relative", backgroundColor: "var(--report-list-tab-wrapper)" }}
      id={divId}
      ref={parentElementDIV}
    >
      <canvas
        ref={tooltipRef}
        style={{ margin: "20px", position: "absolute", top: 0, left: 0, padding: "20px" }}
      ></canvas>
      <canvas
        ref={chartRef}
        style={{ margin: "20px", position: "absolute", top: 0, left: 0, padding: "20px" }}
      ></canvas>
      <svg
        ref={axisSvg}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: "20px",
          margin: "20px",
          color: "var(--chart-axis-line)",
        }}
      ></svg>
      <div ref={brushRef} style={{ position: "absolute", top: 0, left: 0, padding: "20px", margin: "20px" }}>
        <EETooltip interactionData={interactionData} isMulti />
        <svg
          ref={chartMiniRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            margin: "20px",
            color: "var(--chart-axis-line)",
          }}
        ></svg>
      </div>
    </div>
  )
}

export default EEOperationChart
