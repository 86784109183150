import * as React from "react"
import "./stackCard.scss"
import { useState } from "react"
import { StackStatus, StackStatusColor } from "../common/systemData"
import { ShutdownIcon } from "../assets/images/do_disturb_on.js"
import { BatteryAlarmIcon } from "../assets/images/battery_alert.js"
import { BatteryChargingIcon } from "../assets/images/battery_charging_full.js"
import { BatteryDischargingIcon } from "../assets/images/battery_3_bar.js"
import { PauseIcon } from "../assets/images/pauseIcon.js"
import {
  SYSTEM_NODE_STATUS_TEXT,
  SYSTEM_STACK_STATUS,
  SYSTEM_STACK_STATUS_COLOR,
  SYSTEM_STACK_STATUS_KEY,
  SYSTEM_STACK_STATUS_TEXT,
} from "../constant"
interface IStackCardProps {
  stackLabel: string
  stackId: number
  stackStatus: number
  info?: { label: string; value: number }[]
  style?: {}
  onClick: () => void
  nodeId?: number
  hover?: boolean
}

const StackCard: React.FunctionComponent<IStackCardProps> = ({
  stackId,
  stackLabel,
  stackStatus,
  style,
  info,
  onClick,
  nodeId,
  hover = true,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  let modulePosition = 0
  // const warningNodeId = (stackId - 1) * 4 + Math.floor((nodeId - 1) / 4) + 1
  if (nodeId) {
    modulePosition = stackId * 4 + Math.floor((nodeId - 1) / 4) + 1
  }
  const handleClick = () => {
    if (!info) {
      return
    }
    onClick()
  }

  // const iconStatus = {
  //   [StackStatus.charging]: <BatteryChargingFullIcon />,
  //   [StackStatus.discharging]: <Battery4BarIcon />,
  //   [StackStatus.standby]: <PauseCircleOutlineIcon />,
  //   [StackStatus.shutdown]: <DoNotDisturbOnOutlined />,
  //   [StackStatus.alert]: <BatteryAlertIcon />,
  //   [StackStatus.nodata]: <BatteryAlertIcon />,
  //   [StackStatus.warning]: <BatteryAlertIcon />,
  // }
  const isAlert = info && info[0].value > 0 ? true : false
  const alarmCount = info && info[0].value > 0 ? `${info[0].value} Alarms` : ""
  // const warningCount = info && info[0].value > 0 ? `${info[0].value} Warnings` : ""
  // const alertCount = info && info[1].value > 0 ? `${info[1].value} Alerts` : ""
  // console.log("isAlert", stackId, info)
  return (
    <div
      className="stack-card-wrapper"
      style={{
        ...style,
        border: `1px solid ${SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[stackStatus]]}`,
        cursor: info ? "pointer" : "",
      }}
      onClick={handleClick}
    >
      <div
        className="stack-card-title"
        style={{ backgroundColor: `${SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[stackStatus]]}` }}
      >
        {stackLabel}
        <div className="stack-status-title">{SYSTEM_STACK_STATUS_TEXT[SYSTEM_STACK_STATUS[stackStatus]]}</div>
      </div>
      <div
        id={`stack-card-body-${stackId}`}
        className={isHover ? "stack-card-body-hover" : "stack-card-body"}
        onMouseEnter={() => {
          if (hover) {
            setIsHover(true)
          }
        }}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className="stack-card-info-wrapper"
          id={`stack-info-${stackId}`}
          style={{ display: isHover ? "flex" : "none" }}
        >
          {isHover && info
            ? info.map((item, index) => {
                if (item.value === "Not Implemented") {
                  return null
                }
                return (
                  <div key={index}>
                    <strong>{item.label}</strong>&nbsp;:&nbsp;{item.value}
                  </div>
                )
              })
            : null}
        </div>

        <div style={{ display: isHover ? "none" : "flex", flexDirection: "row" }}>
          <div className={"stack-card-node"}>{stackId * 4 + 1}</div>
          <div className={"stack-card-node"}>{stackId * 4 + 2}</div>
        </div>
        <div style={{ display: isHover ? "none" : "flex", flexDirection: "row" }}>
          <div className={"stack-card-node"} style={{ background: "rgba(181, 181, 181, 0.50)" }}>
            {stackId * 4 + 3}
          </div>
          <div className={"stack-card-node"} style={{ background: "rgba(181, 181, 181, 0.50)" }}>
            {stackId * 4 + 4}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StackCard
