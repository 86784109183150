import * as React from "react"
import AxiosApi from "./AxiosApi"
import { getAccessToken } from "../cookie"
import { createContext, useContext } from "react"
import URLs from "../routes/urls"
import { useNavigate } from "react-router-dom"
import { Logout } from "../common/util"

export interface IApiContextProps {
  postApi: (url: string, postData: {}, option?: {}) => any
  token: string
  getToken: () => any
  getApi: (param: string, config?: any) => any
  deleteApi: (param: string, config?: any) => any
  patchApi: (url: string, postData: {}, option?: {}) => any
}

export const ApiContext = createContext<IApiContextProps>({} as IApiContextProps)
const ApiContextProvider = (props: any) => {
  const token = getAccessToken()
  const navigate = useNavigate()
  AxiosApi.defaults.headers.common["authorization"] = `Bearer ${token}`

  const postApi = async (url: string, postData: {}, option?: {}) => {
    try {
      const response = await AxiosApi.post(url, postData, Object.assign({}, option))
      return response.data
    } catch (ex: any) {
      if (ex.response.status === 401) {
        Logout()
        return
      }
      return ex.response
    }
  }
  const patchApi = async (url: string, postData: {}, option?: {}) => {
    try {
      const response = await AxiosApi.patch(url, postData, Object.assign({}, option))
      return response.data
    } catch (ex: any) {
      if (ex.response.status === 401) {
        // alert("Please login")
        Logout()
        return
      }
      return ex.response
    }
  }

  const getApi = async (url: string, option?: {}) => {
    try {
      const response = await AxiosApi.get(url, option)

      return response.data
    } catch (ex: any) {
      // return ex.response.data
      if (ex.response.status === 401) {
        console.log(ex.response.status)
        // alert("Please login")
        Logout()
        navigate(URLs.Login)
        return
      }
      return ex.response
    }
  }
  const deleteApi = async (url: string, option?: {}) => {
    try {
      const response = await AxiosApi.delete(url, option)
      return response.data
    } catch (ex: any) {
      if (ex.response.status === 401) {
        Logout()
        navigate(URLs.Login)
        return
      }
      return ex.response
    }
  }

  return <ApiContext.Provider value={{ postApi, getApi, deleteApi, patchApi }} {...props} />
}

const useApiContext = () => {
  const store = useContext(ApiContext)
  if (!store) {
    throw new Error("You have forgot to use StoreProvider!")
  }
  return store
}

export { ApiContextProvider, useApiContext }
