import * as React from "react"
import "./header.scss"
import "./leftMenu.scss"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import URLs from "../routes/urls"
import { useAuthContext } from "../context/AuthContext"
import { useAppContext } from "../context/AppContext"
import { USER_ROLE } from "../constant"
import { Logout } from "../common/util"
import { EELogo } from "../assets/images/ee-logo"
import EEAppBar from "./EEAppBar"
import EEToolbar from "./EEToolbar"
import { EEMenuItem } from "./EEMenuItem"
import EEMenu from "./EEMenu"
import { AccountCircleIcon } from "../assets/images/account_circle"
import { DarkModeIcon } from "../assets/images/dark_mode"
import { LightModeIcon } from "../assets/images/light_mode"
interface HeaderProps {}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const [pathname, setPathname] = React.useState<string>()
  const location = useLocation()
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const { userRole, logout } = authContext
  const appContext = useAppContext()
  const { isDarkMode, updateIsDarkMode } = appContext
  useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname])
  const onChange = React.useCallback(() => {
    updateIsDarkMode()
  }, [updateIsDarkMode])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState(false)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const handleLogout = async () => {
    await logout()
    Logout()
    navigate(URLs.Login)
  }

  // const handleClickOutside = (event) => {
  //   if (anchorEl && !anchorEl.contains(event?.currentTarget as Node)) {
  //     handleClose()
  //   }
  // }

  // React.useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside)
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside)
  //   }
  // }, [])
  return (
    <div className="header-wrapper">
      <div className="ee-app-bar">
        <div className="logo-wrapper">
          <EELogo fill={"var(--ee-logo-color)"} width="155px" />
          {/* <img src={EELogo} width="155px" /> */}
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="ee-menu-wrapper">
            <EEMenuItem
              onClick={() => {
                navigate(URLs.Plant)
                setPathname(URLs.Plant)
              }}
              className={pathname === URLs.Plant || pathname === URLs.Home ? "menu-item-selected" : "menu-item"}
            >
              <div>Plant</div>
            </EEMenuItem>
            <EEMenuItem
              onClick={() => {
                navigate(URLs.System)
                setPathname(URLs.System)
              }}
              className={pathname === URLs.System ? "menu-item-selected" : "menu-item"}
            >
              <div>System</div>
            </EEMenuItem>

            {userRole === USER_ROLE.onmManager ? (
              <>
                {/* <EEMenuItem
                  onClick={() => {
                    navigate(URLs.Report)
                    setPathname(URLs.Report)
                  }}
                  className={
                    pathname === URLs.Report || pathname === URLs.Report || pathname === URLs.ReportDetail
                      ? "menu-item-selected"
                      : "menu-item"
                  }
                >
                  <div>SoX</div>
                </EEMenuItem>
                <EEMenuItem
                  onClick={() => {
                    navigate(URLs.RXL)
                    setPathname(URLs.RXL)
                  }}
                  className={pathname === URLs.RXL ? "menu-item-selected" : "menu-item"}
                >
                  <div>RXL</div>
                </EEMenuItem> */}
                <EEMenuItem
                  onClick={() => {
                    navigate(URLs.OperationSystem)
                    setPathname(URLs.OperationSystem)
                  }}
                  className={pathname === URLs.OperationSystem ? "menu-item-selected" : "menu-item"}
                >
                  <div>Operation</div>
                </EEMenuItem>
                {/* <EEMenuItem
                  onClick={() => {
                    navigate(URLs.Performance)
                    setPathname(URLs.Performance)
                  }}
                  className={pathname === URLs.Performance ? "menu-item-selected" : "menu-item"}
                >
                  <div>Performance</div>
                </EEMenuItem> */}
              </>
            ) : (
              <EEMenuItem
                onClick={() => {
                  navigate(URLs.OperatorRequest)
                  setPathname(URLs.OperatorRequest)
                }}
                className={pathname === URLs.OperatorRequest ? "menu-item-selected" : "menu-item"}
              >
                <div>Request</div>
              </EEMenuItem>
            )}
          </div>
        </div>
        <div className="header-wrapper">
          {/* this is temporary to test user role needs to be removed before deploy to prod */}
          <div style={{ paddingRight: "12px", cursor: "pointer" }} onClick={onChange}>
            {!isDarkMode ? <DarkModeIcon fill="var(--tooltip-text)" /> : <LightModeIcon fill="var(--tooltip-text)" />}
          </div>
          {/* <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <Notifications />
              </Badge>
            </IconButton> */}
          <div onClick={(e) => handleClick(e)}>
            <AccountCircleIcon fill="var(--tooltip-text)" />
          </div>
          <EEMenu isOpen={open}>
            <EEMenuItem
              onClick={() => {
                navigate(URLs.Profile)
                handleClose()
              }}
              className={"dropdown-menu-item"}
            >
              <div>Setting</div>
            </EEMenuItem>

            <EEMenuItem onClick={handleLogout} className={"dropdown-menu-item"}>
              Logout
            </EEMenuItem>
          </EEMenu>
        </div>
      </div>
    </div>
  )
}

export default Header
