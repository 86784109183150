import * as React from "react"
import EEToggle from "../EEToggle"
import "./eeLegend.scss"

interface IEELegendProps {
  title: string
  color: string
  isToggle?: boolean
  checked?: boolean
  onToggleChange?: (toggleIndex) => void
}

const EELegend: React.FunctionComponent<IEELegendProps> = ({
  title,
  color,
  isToggle = false,
  checked = false,
  onToggleChange,
}) => {
  return (
    <div className={isToggle ? "ee-legend-wrapper" : "ee-legend-wrapper-no-toggle"}>
      {color ? <div className="ee-legend-symbol" style={{ backgroundColor: color }}></div> : null}
      <div className="ee-legend-text">{title}</div>
      {isToggle && onToggleChange ? (
        <EEToggle isToggle={checked} size="small" handleToggleChange={onToggleChange} />
      ) : (
        ""
      )}
    </div>
  )
}

export default EELegend
