import * as React from "react"
import { createContext, useContext, useState } from "react"
import URLs from "../routes/urls"
import { useNavigate } from "react-router-dom"
import { useApiContext } from "./ApiContext"
import { LoginResponseData, UserInfo } from "../type/user"
import { CookieName, setCookie } from "../cookie"
import ServerURLs from "../routes/serverUrls"
import { Logout } from "../common/util"

export interface IAuthContextProps {
  isLoggedin: boolean
  userRole: number
  updateUserRole: (role: number) => void
  emailLogin: (email: string, password: string) => boolean
  userInfo: any
  ssoLogin: (token) => boolean
  checkLogin: () => void
  getUserInfo: () => void
  logout: () => void
  requestAuthCode: (mobile: string) => any
  verifyAuthCode: (mobile: string, code: number) => any
}
export const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps)
const AuthContextProvider = (props: any) => {
  const navigate = useNavigate()
  const apiContext = useApiContext()
  const { postApi, getApi } = apiContext
  const [isLoggedin, setIsLoggedIn] = useState<boolean>(false)
  const [userRole, setUserRole] = useState<number>()
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const getUserInfo = React.useCallback(async () => {
    const response = await getApi(ServerURLs.GetUserInfo)
    if (response && response.statusCode === 200) {
      setUserInfo(response.data)
      setUserRole(response.data.roleId)
      return true
    }
    return false
  }, [getApi])
  const checkLogin = React.useCallback(async () => {
    const response = await getApi(ServerURLs.LoginCheck)

    if (response && response.statusCode === 200) {
      await getUserInfo()
      // navigate(URLs.Plant)
    } else {
      setIsLoggedIn(false)
      Logout()
    }
  }, [getApi, getUserInfo])
  const logout = React.useCallback(async () => {
    Logout()
    navigate(URLs.Login)
  }, [navigate])

  const requestAuthCode = React.useCallback(
    async (mobile) => {
      const response = await getApi(`${ServerURLs.AuthCodeRequest}/${mobile}`)
      return response
    },
    [getApi]
  )
  const verifyAuthCode = React.useCallback(
    async (mobile, code) => {
      const response = await getApi(`${ServerURLs.AuthCodeVerify}/${mobile}/${code}`)
      return response
    },
    [getApi]
  )
  const emailLogin = React.useCallback(
    async (email, password) => {
      const response: LoginResponseData = await postApi("/user/login/email", { email, pw: password })
      if (response.data && response.data.activated) {
        setCookie(CookieName.ACCESS_TOKEN, response.data.token)
        setIsLoggedIn(true)
        setUserInfo(response.data)
        setUserRole(response.data.roleId)
        return response.data
      } else {
        return false
      }
    },
    [postApi]
  )
  const ssoLogin = React.useCallback(
    async (token) => {
      setCookie(CookieName.ACCESS_TOKEN, token)
      const response = await getApi(ServerURLs.GetUserInfo, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      if (response) {
        navigate(URLs.System)
        return true
      } else {
        navigate(URLs.Login)
        return false
      }
    },
    [getApi, navigate]
  )

  const updateUserRole = React.useCallback((role) => {
    setUserRole(role)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isLoggedin,
        userRole,
        updateUserRole,
        emailLogin,
        checkLogin,
        ssoLogin,
        logout,
        userInfo,
        requestAuthCode,
        verifyAuthCode,
      }}
      {...props}
    />
  )
}

const useAuthContext = () => {
  const store = useContext(AuthContext)

  if (!store) {
    throw new Error("You have forgot to use StoreProvider!")
  }
  return store
}

export { AuthContextProvider, useAuthContext }
