import React, { useState } from "react"
import "./eeDataGrid.scss"
import { ArrowUpIcon } from "../assets/images/arrow-up-solid"
import { ArrowDownIcon } from "../assets/images/arrow-down-solid"

interface IColumn {
  field: string
  headerName: string
  valueGetter?: (row: any) => any
  renderCell?: (row: any) => React.ReactNode
}

interface IEEDataGridProps {
  columns: IColumn[]
  rows: any[]
  filter?: boolean
  height?: string
  onRowClick?: (row: any) => void
}
export const EEDataGrid: React.FunctionComponent<IEEDataGridProps> = ({
  columns,
  rows,
  filter,
  height = "auto",
  onRowClick = () => {},
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string
    direction: string
    valueGetter?: (row: any) => any
  } | null>(null)

  const sortedRows = React.useMemo(() => {
    if (sortConfig !== null) {
      return [...rows].sort((a, b) => {
        const aValue = sortConfig.valueGetter ? sortConfig.valueGetter(a) : a[sortConfig.key]
        const bValue = sortConfig.valueGetter ? sortConfig.valueGetter(b) : b[sortConfig.key]

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1
        }
        return 0
      })
    }
    return rows
  }, [rows, sortConfig])

  const requestSort = (key, valueGetter) => {
    let direction = "ascending"
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction, valueGetter })
  }

  return (
    <div className="custom-data-grid">
      <div className="custom-data-grid-header">
        {columns.map((column) => (
          <div
            key={column.field}
            onClick={() => requestSort(column.field, column.valueGetter)}
            className="custom-data-grid-cell header-cell"
          >
            {column.headerName}
            {sortConfig && sortConfig.key === column.field ? (
              sortConfig.direction === "ascending" ? (
                <span style={{ marginLeft: "6px" }}>
                  <ArrowUpIcon fill={"var(--page-section-title)"} width="12px" />
                </span>
              ) : (
                <span style={{ marginLeft: "8px" }}>
                  <ArrowDownIcon fill={"var(--page-section-title)"} width="12px" />
                </span>
              )
            ) : null}
          </div>
        ))}
      </div>
      <div style={{ height: height }} className="custom-data-grid-body" id="ee-data-grid">
        {sortedRows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="custom-data-grid-row"
            id={`ee-data-row-${rowIndex}`}
            onClick={() => {
              onRowClick(row)
            }}
          >
            {columns.map((column) => (
              <div key={column.field} className="custom-data-grid-cell">
                {column.renderCell
                  ? column.renderCell(row)
                  : column.valueGetter
                  ? column.valueGetter(row)
                  : row[column.field]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
