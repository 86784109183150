import * as React from "react"

interface IEEBadgeProps {
  color?: string
  size?: "8px" | "12px" | "16px" | "24px"
}

const EEBadge: React.FunctionComponent<IEEBadgeProps> = ({ color = "rgb(211,47,47)", size = "8px" }) => {
  const radius =
    size === "8px" ? "4px" : size === "12px" ? "6px" : size === "16px" ? "8px" : size === "24px" ? "12px" : "4px"
  return <div style={{ backgroundColor: color, width: size, borderRadius: radius, height: size, float: "left" }}></div>
}

export default EEBadge
