import * as React from "react"
import { useState } from "react"
import "./eeToggle.scss"

interface IEEToggleProps {
  isToggle: boolean
  size: string
  handleToggleChange: (toggleIndex) => void
  disabled?: boolean
}

const EEToggle: React.FunctionComponent<IEEToggleProps> = ({
  isToggle = false,
  size,

  handleToggleChange,
  disabled = false,
}) => {
  // console.log("isToggle", isToggle)
  // const [isChecked, setIsChecked] = useState(isToggle)

  const handleToggle = () => {
    if (disabled) return

    // setIsChecked(!isChecked)
    if (handleToggleChange) {
      handleToggleChange(!isToggle)
    }
  }

  return (
    <div className={`custom-switch ${isToggle ? "checked" : ""} ${disabled ? "disabled" : ""}`} onClick={handleToggle}>
      <div className="custom-switch-slider" />
    </div>
  )
}

export default EEToggle
