import * as React from "react"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useApiContext } from "./ApiContext"
import ServerURLs from "../routes/serverUrls"

import SOX from "../type/sox"
import { MainImage, MainMetrics, MainState } from "../type/system"

export interface IAppContextProps {
  landingNode: () => any
  landingStack: () => any
  containerIdx: string
  stackIdx: string
  nodeIdx: string
  cellIdx: string
  isDarkMode: boolean
  updateIsDarkMode: () => void
  updateContainerIdx: (newIdx: string) => void
  updateStackIdx: (newIdx: string) => void
  updateNodeIdx: (newIdx: string) => void
  updateCellIdx: (newIdx: string) => void

  getOperationSelectOption: () => any
  getPerformanceSelectOption: () => any
  postOperationChartData: (data) => any
  getPerformanceChartData: (data) => any
  getSosChartData: (data) => any
  getSodChartData: (data) => any
  getAlarmList: () => any
  getAlarm: (alarmId) => any
  getLandingPageStats: () => MainState
  getContainerOptions: () => any
  getStackOptions: () => any
  getNodeOptions: () => any
  getLandingPageImageInfo: (params) => MainImage
  getLandingPageMetrics: (containerId?: string) => any
  getAlarmDetail: (id) => any
  getRequestList: () => {}
  getRequest: (id) => {}
  createRequest: (param: { rollback?: boolean; alarmId: number; close?: boolean }) => any
  getFlaggedSoS: (param) => any
  updateRequestStatus: (param) => any
  getUserList: () => any
  getChartSetting: () => any
  updateChartSetting: (data) => any
  deleteChartSetting: (id) => any
  updateUserRole: (userId: number, role: number) => void
  updateUserActivate: (userId: number, activated: boolean) => void
  getPlantPageData: () => any
  updateContainerName: (newName: string, containerId: number) => any
  getPlotCategory: (param: { plotType: number; orgId: number }) => any
  updatePlotCategoryState: (data) => any
  getAlarmSettingList: (param: { projectId: number; orgId: number }) => any
  updateAlarmSetting: (data) => any
  getEnergyThroughput: (container?: string) => any
}

export const AppContext = createContext<IAppContextProps>({} as IAppContextProps)
const AppContextProvider = (props: any) => {
  const apiContext = useApiContext()
  const { postApi, getApi, deleteApi, patchApi } = apiContext
  const [containerIdx, setContainerIdx] = useState<string>("1")
  const [stackIdx, setStackIdx] = useState<string>("1")
  const [nodeIdx, setNodeIdx] = useState<string>("1L")
  const [cellIdx, setCellIdx] = useState<string>("1")
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true)

  const updateChartSetting = useCallback(
    async (data) => {
      const response = await postApi(ServerURLs.UserChartSetting, data)
      return response
    },
    [postApi]
  )
  const getChartSetting = useCallback(async () => {
    const response = await getApi(ServerURLs.UserChartSetting)
    return response
  }, [getApi])
  const deleteChartSetting = useCallback(
    async (id) => {
      const response = await deleteApi(`${ServerURLs.UserChartSetting}/${id}`)
      return response
    },
    [deleteApi]
  )
  const getUserList = useCallback(async () => {
    const response = await getApi(ServerURLs.GetUserList)
    return response
  }, [getApi])
  const updateUserRole = useCallback(
    async (userId: number, role: number) => {
      const response = await patchApi(ServerURLs.UpdateUser, { id: userId, activated: true, roleID: role })
      return response
    },
    [patchApi]
  )
  const updateUserActivate = useCallback(
    async (userId: number, activated: boolean) => {
      const response = await patchApi(ServerURLs.UpdateUser, { id: userId, activated })
      return response
    },
    [patchApi]
  )
  const updateIsDarkMode = useCallback(() => {
    setIsDarkMode(!isDarkMode)
  }, [isDarkMode])
  const getPlotCategory = useCallback(
    async ({ plotType, orgId }) => {
      const response = await getApi(`${ServerURLs.GetPlotCategory}?plotType=${plotType}&orgId=${orgId}`)
      return response.data
    },
    [getApi]
  )
  const updatePlotCategoryState = useCallback(
    async (data) => {
      const response = await patchApi(ServerURLs.UpdatePlotCategoryState, data)
      return response
    },
    [patchApi]
  )
  const getAlarmSettingList = useCallback(
    async ({ projectId, orgId }) => {
      const response = await getApi(`${ServerURLs.GetAlarmSettingList}?projectId=${projectId}&orgId=${orgId}`)
      return response.data
    },
    [getApi]
  )
  const updateAlarmSetting = useCallback(
    async (data) => {
      const response = await patchApi(ServerURLs.UpdateAlarmSetting, data)
      return response
    },
    [patchApi]
  )
  useEffect(() => {
    const mode = isDarkMode ? "dark" : "light"
    document.documentElement.setAttribute("data-theme", mode)
  }, [isDarkMode])
  const updateRequestStatus = useCallback(
    async (param) => {
      const response = await getApi(`${ServerURLs.UpdateOperateStatus}/${param.requestStatus}/${param.requestId}`)
      return response
    },
    [getApi]
  )
  const updateContainerName = useCallback(
    async (newContainerLabel, containerId) => {
      const response = await patchApi(ServerURLs.UpdateContainerName, { containerId, newContainerLabel })
      return response
    },
    [patchApi]
  )
  const getPlantPageData = useCallback(async () => {
    const response = await getApi(ServerURLs.GetPlantPageData)
    return response
  }, [getApi])
  const getContainerOptions = useCallback(async () => {
    const response = await getApi(ServerURLs.GetContainerOptions)
    return response
  }, [getApi])
  const getStackOptions = useCallback(async () => {
    const response = await getApi(ServerURLs.GetStackOptions)
    return response
  }, [getApi])
  const getNodeOptions = useCallback(async () => {
    const response = await getApi(ServerURLs.GetNodeOptions)
    return response
  }, [getApi])
  const getLandingPageStats = useCallback(async () => {
    const response = await getApi(ServerURLs.GetMainStats)
    return response.data
  }, [getApi])
  const getLandingPageImageInfo = useCallback(
    async (params) => {
      const response = await getApi(ServerURLs.GetMainImage + params)
      return response.data
    },
    [getApi]
  )
  const getLandingPageMetrics = useCallback(
    async (containerId) => {
      if (containerId) {
        const response = await getApi(`${ServerURLs.GetMainMetrics}?container=${containerId}`)
        return response.data
      } else {
        const response = await getApi(`${ServerURLs.GetMainMetrics}`)
        return response.data
      }
    },
    [getApi]
  )
  const getOperationSelectOption = useCallback(async () => {
    // console.log("getAccesstoken", getAccessToken())

    const response = await getApi(ServerURLs.GetOperationSelectOption)
    return response
  }, [getApi])
  const getFlaggedSoS = useCallback(
    async (param) => {
      const response = await getApi(
        `${ServerURLs.GetFlagedSoS}?container=${param.container}&stack=${param.stack}&node=${param.node}`
      )
      if (response && response.data) {
        return response.data
      } else {
        return []
      }
    },
    [getApi]
  )
  const getRequestList = useCallback(async () => {
    const response = await getApi(ServerURLs.GetRequestList)
    return response
  }, [getApi])
  const getRequest = useCallback(
    async (id) => {
      const response = await getApi(`${ServerURLs.GetRequest}/${id}`)
      return response
    },
    [getApi]
  )
  const getPerformanceSelectOption = useCallback(async () => {
    // console.log("getAccesstoken", getAccessToken())

    const response = await getApi(ServerURLs.GetPerformanceOption)
    return response
  }, [getApi])
  const getAlarmList = useCallback(async () => {
    const response: SOX.AlarmList = await getApi(ServerURLs.GetAlarmList)
    if (response && response.data) {
      return response.data
    } else {
      return []
    }
  }, [getApi])
  const getAlarm = useCallback(
    async (alarmId) => {
      const response = await getApi(`${ServerURLs.GetAlarm}/${alarmId}`)
      return response
    },
    [getApi]
  )
  const postOperationChartData = useCallback(
    async (data) => {
      console.log("postOperationChartData data", data)
      let query = `${data.plotCategoryId}?start=${data.start}&end=${data.end}`
      query += data.container ? `&container=${data.container}` : ""
      query += data.stack ? `&stack=${data.stack}` : ""
      query += data.node ? `&node=${data.node}` : ""
      const response = await getApi(`${ServerURLs.PostOperationData}/${query}`)
      return response.data
    },
    [getApi]
  )
  const getPerformanceChartData = useCallback(
    async (data) => {
      const response = await getApi(
        `${ServerURLs.GetPerformanceData}/${data.plotType}?container=${data.container}&stack=${data.stack}&node=${data.node}`
      )
      return response.data
    },
    [getApi]
  )
  const getSosChartData = useCallback(
    async (data) => {
      const response = await getApi(
        `${ServerURLs.GetSosData}/${data.plotType}?container=${data.container}&stack=${data.stack}&node=${data.node}`
      )
      return response.data
    },
    [getApi]
  )
  const getSodChartData = useCallback(
    async (data) => {
      const response = await getApi(
        `${ServerURLs.GetSodData}/${data.plotType}?container=${data.container}&stack=${data.stack}&node=${data.node}`
      )
      return response.data
    },
    [getApi]
  )
  const landingNode = async () => {
    try {
      const response = await postApi(ServerURLs.PostLandingNode, {
        acId: "0",
        endTimestamp: 1701927145,
        stackIdx: 1,
      })
      return response.data
    } catch (ex) {
      return ex
    }
  }
  const createRequest = async (param) => {
    try {
      const response = await postApi(ServerURLs.CreateRequest, param)
      return response
    } catch (ex) {
      return ex
    }
  }
  const updateContainerIdx = useCallback((newId) => {
    return setContainerIdx(newId)
  }, [])
  const updateStackIdx = useCallback((newId) => {
    return setStackIdx(newId)
  }, [])
  const updateNodeIdx = useCallback((newId) => {
    return setNodeIdx(newId)
  }, [])
  const updateCellIdx = useCallback((newId) => {
    return setCellIdx(newId)
  }, [])
  const landingStack = async () => {
    try {
      const response = await postApi(ServerURLs.PostLandingStack, {
        acId: "2392ADF45C7",
        endTimestamp: 1701927145,
      })

      return response.data
    } catch (ex) {
      return ex
    }
  }

  const getEnergyThroughput = useCallback(
    async (param) => {
      if (param) {
        const response = await getApi(`${ServerURLs.GetEnergyThoughput}?container=${param}`)
        return response
      } else {
        const response = await getApi(`${ServerURLs.GetEnergyThoughput}`)
        return response
      }
    },
    [getApi]
  )
  return (
    <AppContext.Provider
      value={{
        landingNode,
        landingStack,
        updateCellIdx,
        cellIdx,
        updateContainerIdx,
        containerIdx,
        updateNodeIdx,
        nodeIdx,
        updateStackIdx,
        stackIdx,
        getOperationSelectOption,
        postOperationChartData,
        getAlarmList,
        getLandingPageStats,
        getLandingPageImageInfo,
        getLandingPageMetrics,
        getPerformanceSelectOption,
        getPerformanceChartData,
        getSodChartData,
        getSosChartData,
        updateIsDarkMode,
        isDarkMode,
        getAlarm,
        getRequestList,
        createRequest,
        getFlaggedSoS,
        updateRequestStatus,
        getRequest,
        getUserList,
        getChartSetting,
        updateChartSetting,
        deleteChartSetting,
        updateUserRole,
        updateUserActivate,
        getPlantPageData,
        getContainerOptions,
        getStackOptions,
        getNodeOptions,
        updateContainerName,
        getPlotCategory,
        updatePlotCategoryState,
        getAlarmSettingList,
        updateAlarmSetting,
        getEnergyThroughput,
      }}
      {...props}
    />
  )
}

const useAppContext = () => {
  const store = useContext(AppContext)
  if (!store) {
    throw new Error("You have forgot to use StoreProvider!")
  }
  return store
}

export { AppContextProvider, useAppContext }
