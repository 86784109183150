import * as React from "react"
import { useEffect, useState } from "react"
import "./systemPage.scss"
import StackCard from "../../component/StackCard"
import {
  SYSTEM_NODE_STATUS_LEGEND,
  SYSTEM_STACK_STATUS,
  SYSTEM_STACK_STATUS_COLOR,
  SYSTEM_STACK_STATUS_LEGEND,
} from "../../constant"
import { EEBasicDialog } from "../../component/dialogs/EEBasicDialogs"
import StackDialogContent from "../../component/StackDialogContent"
import { useAppContext } from "../../context/AppContext"
import { MainImage } from "../../type/system"
import EESelect from "../../component/EESelect"
import EELoading from "../../component/Loading"
import { useLocation } from "react-router-dom"

import _ from "lodash"
import Gauge from "../../assets/soc"
import { StackBar } from "../../assets/stackBar"
import { EETooltip } from "../../component/charts/EETooltip"
import ToogleButton from "../../component/ToggleButton"
import WeatherSection from "../../component/WeatherSection"
import dayjs from "dayjs"
export interface IMainPageProps {}

export default function SystemPage(props: IMainPageProps) {
  const location = useLocation()
  const { container } = location.state || { containerId: "container 1" }
  const appContext = useAppContext()
  const { getContainerOptions, getLandingPageImageInfo, isDarkMode, getLandingPageMetrics, getEnergyThroughput } =
    appContext
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [selectedStack, setSelectedStack] = useState<number>(-1)
  const [stackData, setStackData] = useState<any>()
  const [nodeData, setNodeData] = useState<MainImage[]>([])
  const [selecteContainer, setSelectedContainer] = useState<{ value: string; label: string }>()
  const [tabData, setTabData] = useState<any>([])
  const [containerOption, setContainerOptions] = useState<any>([])
  const [energyThroughputData, setEnergyThroughputData] = useState<any>()
  const [toggleState, setToggleState] = useState<boolean>(false)
  const [interactionDataET, setInteractionDataET] = useState<any>(null)

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStackImageData = async (value) => {
    const imageResponse = await getLandingPageImageInfo(`?container=${value}`)
    setStackData(imageResponse)
  }
  const getEnergyThroughputData = async (value) => {
    const energyThroughput = await getEnergyThroughput(value)
    if (energyThroughput.statusCode === 200) {
      setEnergyThroughputData(energyThroughput.data.plotData)
    }
  }
  const getMetrics = async () => {
    // const energyThroughput = await getEnergyThroughput(selecteContainer?.value)
    // console.log("energyThroughput", energyThroughput)
    // if (energyThroughput.statusCode === 200) {
    //   setEnergyThroughputData(energyThroughput.plotData)
    // }
    const metricsResponse = await getLandingPageMetrics(selecteContainer?.value)

    setTabData(metricsResponse)
  }
  useEffect(() => {
    if (selecteContainer) {
      getStackImageData(selecteContainer.value)
      getMetrics()
      getEnergyThroughputData(selecteContainer.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecteContainer])
  useEffect(() => {
    if (container) {
      setSelectedContainer(container)
    } else if (containerOption.length > 0) {
      setSelectedContainer(containerOption[0])
    }
  }, [container, containerOption])
  const getData = async () => {
    const containerOptionsResponse = await getContainerOptions()
    if (containerOptionsResponse.statusCode === 200) {
      setSelectedContainer(container ? container : containerOptionsResponse.data[0])
      setContainerOptions(containerOptionsResponse.data)
    }
    // const alarmResponse = userRole === USER_ROLE.onmManager ? await getAlarmList() : await getRequestList()
    // if (alarmResponse && alarmResponse.data) {
    //   if (userRole === USER_ROLE.onmManager) {
    //     const newAlarmList: any = _.filter(alarmResponse.data, function (o) {
    //       return !o.viewedByUserAt
    //     })
    //     setAlarmList(newAlarmList)
    //   } else {
    //     const newAlarmList: any = _.filter(alarmResponse.data.data, function (o) {
    //       return getRequestStatus(o.displayStatus) === ""
    //     })
    //     setAlarmList(newAlarmList)
    //   }
    // }
  }
  const getSocValue = () => {
    if (tabData) {
      const socValue = tabData?.soc?.percent * 100
      return +socValue.toFixed(2) % 1 === 0 ? socValue.toFixed() : socValue.toFixed(2)
    } else {
      return ""
    }
  }
  const handleOpenDialog = async (stackId) => {
    setDialogOpen(true)
    setSelectedStack(stackId)

    // const con = "0"
    const nodeResponse: MainImage = await getLandingPageImageInfo(
      `?container=${selecteContainer?.value}&stack=${stackId}`
    )
    setNodeData(nodeResponse)
    // }
  }
  const handleCloseDialog = () => {
    setNodeData([])
    setDialogOpen(false)
  }

  return (
    <div className="main-page-wrapper">
      <EETooltip interactionData={interactionDataET} id="energy-throughput-tooltip" />

      <div className="main-page-content-wrapper">
        <div className="main-page-right-content-wrapper">
          {stackData ? (
            <div className="ee-last-update">
              Last Update: &nbsp;&nbsp;&nbsp;{dayjs(stackData[0].lastUpdate).format("YYYY-MM-DD HH:mm:ss")}
            </div>
          ) : null}
          {/* <WeatherSection /> */}
          {tabData ? (
            <div className="ee-tab-wrapper">
              <div className="ee-tab-title">Metrics</div>
              <div className="ee-tab-content">
                <div>
                  <span style={{ fontSize: "16px" }}>System Voltage : </span>
                  <span style={{ fontWeight: 800, fontSize: "18px" }}>{tabData.systemVoltage}</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>Net System Current : </span>
                  <span style={{ fontWeight: 800, fontSize: "18px" }}>{tabData.netCurrent}</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>Net System Power : </span>
                  <span style={{ fontWeight: 800, fontSize: "18px" }}> {tabData.netPower}</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>Chiller Outlet Temperature : </span>
                  <span style={{ fontWeight: 800, fontSize: "18px" }}>{tabData.chillerOutletTemp}</span>
                </div>
                <div>
                  <span style={{ fontSize: "16px" }}>Chiller Inlet Temperature : </span>
                  <span style={{ fontWeight: 800, fontSize: "18px" }}>{tabData.chillerInletTemp}</span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="avg-soc-wrapper">
            <div className="plant-section-title"> {selecteContainer ? selecteContainer.label : null} SoC</div>
            <div className="plant-gauge-text">{tabData ? `${getSocValue()}%` : ""}</div>
            <div className="plant-gauge-wrapper">
              <Gauge percent={tabData ? tabData?.soc?.percent : 0} />
            </div>
            <div className="plant-sub-unit-text-wrapper">
              <div className="plant-gauge-sub-text">{tabData ? tabData?.soc?.value : ""}</div>
              {/* <div className="plant-gauge-unit-text">{ ""}</div> */}
            </div>
          </div>
          <div className="energy-throughput">
            <div>
              <ToogleButton onClick={(value) => setToggleState(value)} isOn={toggleState} />
            </div>
            <div className="energy-throughput-title">Energy Throughtput</div>
            <div className="energy-throughput-value-unit-wrapper">
              <div className="energy-throughput-value" id="energy-throughput">
                {energyThroughputData
                  ? toggleState
                    ? energyThroughputData?.chargeEnergy[6]
                    : energyThroughputData?.dischargeEnergy[6]
                  : ""}
              </div>
              <span style={{ fontSize: "22px", color: "var(--energy-throughput-title)", fontWeight: 600 }}>
                {energyThroughputData
                  ? toggleState
                    ? energyThroughputData.chargeEnergyUnit[6]
                    : energyThroughputData.dischargeEnergyUnit[6]
                  : ""}
              </span>
              <div className="energy-throughput-unit">Latest</div>
            </div>
            <div className="energy-throughput-chart-wrapper">
              {energyThroughputData
                ? energyThroughputData?.date.map((item, index) => {
                    return (
                      <div key={index}>
                        <StackBar
                          date={item}
                          height={
                            toggleState
                              ? energyThroughputData?.chargeEnergyPercent[index] * 100
                              : energyThroughputData?.dischargeEnergyPercent[index] * 100
                          }
                          fill="#564cff"
                          onMouseOver={(e) => {
                            setInteractionDataET({
                              xPos: e.pageX - 30,
                              yPos: e.pageY - 80,
                              color: "rgb(94, 246, 159)",
                              x: item,
                              y: `${
                                toggleState
                                  ? energyThroughputData.chargeEnergy[index] +
                                    energyThroughputData.chargeEnergyUnit[index]
                                  : energyThroughputData.dischargeEnergy[index] +
                                    energyThroughputData.dischargeEnergyUnit[index]
                              } `,
                              name: "",
                            })
                          }}
                          id={`energy-throughput-chart-${index}`}
                          onMouseLeave={() => setInteractionDataET(null)}
                        />
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
          {/* <EETabs tabData={tabData} isDarkMode={isDarkMode} /> */}
        </div>
        <div className="main-page-system-content-wrapper">
          <div className="main-page-system-top-wrapper">
            <div className="main-page-title-wrapper">
              <div className="main-page-subtitle">Inertia</div>
              {containerOption && containerOption.length > 0 ? (
                <EESelect
                  value={selecteContainer?.value ?? ""}
                  onChange={(newValue) => setSelectedContainer(newValue)}
                  options={containerOption}
                  border={false}
                />
              ) : null}
            </div>
            <div className="main-page-system-title" id="container-name">
              {selecteContainer ? selecteContainer.label : null}
              {/* {containerOption.filter((option) => option.value === selecteContainer)[0].label} */}
            </div>
            <div className="system-legend-wrapper">
              {SYSTEM_STACK_STATUS_LEGEND.map((item, index) => (
                <div className="system-legend" key={index}>
                  <div style={{ backgroundColor: item.color, width: "8px", height: "8px", borderRadius: "4px" }}></div>
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={isDarkMode ? "main-page-system-dark" : "main-page-system"}>
            <div className="main-page-system-wrapper">
              <React.Suspense fallback={<EELoading />}>
                {stackData ? (
                  stackData.map((item, index) => {
                    if (index < 6) {
                      return (
                        <div className="main-page-stack-wrapper" key={index}>
                          <StackCard
                            stackId={item.stack - 1}
                            stackLabel={item.label}
                            stackStatus={item.stackStatus}
                            key={item.stack}
                            info={item.info}
                            nodeId={item.nodeId}
                            onClick={() => handleOpenDialog(item.stack)}
                          />
                          <div
                            className="main-page-system-bpu"
                            style={{
                              border: `1px solid ${SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[item.stackStatus]]}`,
                            }}
                          >
                            BPU {item.stack}
                          </div>
                          <div
                            className="main-page-system-bpu"
                            style={{
                              border: `1px solid ${
                                SYSTEM_STACK_STATUS_COLOR[SYSTEM_STACK_STATUS[stackData[index + 6].stackStatus]]
                              }`,
                            }}
                          >
                            BPU {stackData[index + 6].stack}
                          </div>
                          <StackCard
                            style={{ marginRight: "8px" }}
                            stackId={stackData[index + 6].stack - 1}
                            stackLabel={stackData[index + 6].label}
                            stackStatus={stackData[index + 6].stackStatus}
                            info={stackData[index + 6].info}
                            key={stackData[index + 6].stack}
                            onClick={() => handleOpenDialog(stackData[index + 6].stack)}
                          />
                        </div>
                      )
                    }
                    return null
                  })
                ) : (
                  <EELoading />
                )}
              </React.Suspense>
            </div>
          </div>
        </div>
      </div>

      <EEBasicDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={
          <div className="main-page-dialog-title-wrapper">
            <div id={`stack-dialog-${selectedStack}`}>Stack {selectedStack}</div>
            <div className="dialog-title-chip-wrapper">
              {SYSTEM_NODE_STATUS_LEGEND.map((item, index) => (
                <div className="system-legend" key={index}>
                  <div style={{ backgroundColor: item.color, width: "8px", height: "8px", borderRadius: "4px" }}></div>
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        }
      >
        <StackDialogContent stackInfo={nodeData} stackId={selectedStack} />
      </EEBasicDialog>
    </div>
  )
}
