import * as React from "react"

interface IEeRadioProps {
  name: string
  value: any
  checked: boolean
  onChange?: (value: string) => void
  label?: string
}

const EERadio: React.FunctionComponent<IEeRadioProps> = ({ name, value, checked, onChange, label }) => {
  const handleChange = () => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <label className="custom-radio-container">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        className="custom-radio-input"
      />
      <span className="custom-radio-checkmark"></span>
      {label && <span className="custom-radio-label">{label}</span>}
    </label>
  )
}

export default EERadio
