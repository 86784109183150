import * as React from "react"
import { useEffect, useState } from "react"
import { chartColors } from "../../common/chartColors"
import { useAppContext } from "../../context/AppContext"
import { useQuery } from "@tanstack/react-query"
import EELoading from "../../component/Loading"
import _ from "lodash"
import { createContainerOption, createNodeOption, createStackOption, dataCycleConvert } from "../../common/util"
import EEOperationChart from "../../component/charts/EEOperationChart"
import "../OperateAndManager/sox.scss"
import EESelect from "../../component/EESelect"
import { OPTION_NONE_SELECT_VALUE, PlotType } from "../../constant"
import EELegend from "../../component/charts/EELegend"

interface IMultiChartPanelProps {
  dataParam: any
  style?: any
  select?: boolean
  isSox?: boolean
}

const MultiChartPanel: React.FunctionComponent<IMultiChartPanelProps> = ({
  dataParam,
  style,
  select = false,
  isSox = false,
}) => {
  const [sodCurrentData, setSodCurrentData] = useState<any>([])
  const [sodUpdateData, setSodUpdateData] = useState<any>([])
  const [sosUpdateData, setSosUpdateData] = useState<any>([])
  const [newSodLegend, setNewSodLegend] = useState<any>()
  const [newSosLegend, setNewSosLegend] = useState<any>()
  const [sosCurrentData, setSosCurrentData] = useState<any>([])
  const [sodNoData, setSodNoData] = useState<boolean>(false)
  const [sosNoData, setSosNoData] = useState<boolean>(false)
  const appContext = useAppContext()
  const { getSodChartData, getSosChartData } = appContext
  const [containerSelect, setContainerSelect] = useState("1")
  const [stackSelect, setStackSelect] = useState("1")
  const [nodeSelect, setNodeSelect] = useState(1)
  const containerOption = createContainerOption()
  const stackOption = createStackOption({ level: { stack: { noneValue: true } } })
  const nodeOption = createNodeOption({ level: { node: { noneValue: true } } })
  const [sodError, setSodError] = useState("")
  const [sosError, setSosError] = useState("")
  const [soxState, setSoxState] = useState(2)
  const [sosUnit, setSosUnit] = useState()
  const [sodUnit, setSodUnit] = useState()
  const stateOption = [
    { label: "Discharge", value: 2 },
    { value: 1, label: "Charge" },
  ]
  const { data: sos } = useQuery({
    queryKey: [
      "sosDefault",
      {
        plotType: PlotType.sosDefault,
        container: dataParam.container,
        node: dataParam.node,
        stack: dataParam.stack,
        state: dataParam.state,
      },
    ],
    queryFn: async () => {
      const data = await getSosChartData({
        plotType: PlotType.sosDefault,
        container: dataParam.container,
        node: dataParam.node,
        stack: dataParam.stack,
        state: dataParam.state,
      })
      console.log("data,data,data checkckckckc", data)

      if (data.statusCode === 500 || data.statusCode === 400) {
        setSosError("Internal Server Error")
        setSosCurrentData(null)
        setSosUpdateData(null)
        setNewSosLegend(null)
        setSosNoData(false)
        return null
      }
      return data
    },
  })
  const { data: sod } = useQuery({
    queryKey: [
      "sodDefault",
      {
        plotType: PlotType.sodDefault,
        container: dataParam.container,
        node: dataParam.node,
        stack: dataParam.stack,
        state: soxState,
      },
    ],
    queryFn: async () => {
      const data = await getSodChartData({
        plotType: PlotType.sodDefault,
        container: dataParam.container,
        node: dataParam.node,
        stack: dataParam.stack,
        state: soxState,
      })
      if (data.statusCode > 399) {
        setSodError("Internal Server Error")
        setSodNoData(false)
        setSodCurrentData(null)
        setSodUpdateData(null)
        setNewSodLegend(null)
        return null
      }
      return data
    },
  })
  React.useEffect(() => {
    const legend: any = []

    for (let i = 0; i < 10; i++) {
      legend.push({ title: `Cell ${i + 1}`, color: chartColors[i], checked: true })
    }
    setNewSodLegend(legend)
    setNewSosLegend(legend)
  }, [])
  const handleToggleChange = (toggleIndex, type) => {
    if (type === "sod") {
      const nextLegend = newSodLegend.map((item, index) => {
        if (toggleIndex === index) {
          return { ...item, checked: !item.checked }
        }
        return item
      })

      setNewSodLegend(nextLegend)
    } else {
      const nextLegend = newSosLegend.map((item, index) => {
        if (toggleIndex === index) {
          return { ...item, checked: !item.checked }
        }
        return item
      })

      setNewSosLegend(nextLegend)
    }
  }
  const handleDisplayAll = (type) => {
    if (newSodLegend && type === "sod") {
      const nextLegend = newSodLegend.map((item) => {
        return { ...item, checked: true }
      })
      setNewSodLegend(nextLegend)
    }
    if (newSosLegend && type === "sos") {
      const nextLegend = newSosLegend.map((item) => {
        return { ...item, checked: true }
      })
      setNewSosLegend(nextLegend)
    }
  }
  const createSosData = () => {
    if (sos) setSosNoData(false)
    setSosError("")
    setSosUnit(sos.unit)
    const plotData = sos.plotData
    const keyArray = Object.keys(plotData).filter(function (letter) {
      return letter !== "cycle"
    })
    if (plotData && keyArray.length > 0) {
      const timeValue = plotData.cycle
      if (timeValue.length < 1) {
        setSosNoData(true)
        return
      }
      const newDataValue = _.pick(plotData, keyArray)
      const chartData: any = []
      _.times(keyArray.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = keyArray[item]
        dataNew.values = dataCycleConvert({ cycle: timeValue, value: newDataValue[keyArray[item]] || [] })
        chartData.push(dataNew)
      })
      setSosCurrentData(chartData)
      setSosUpdateData(chartData)
      setSosNoData(false)
    } else {
      setSosNoData(true)
    }
  }
  const createSodData = () => {
    setSodNoData(false)
    setSodError("")
    setSodUnit(sod.unit)

    const plotData = sod.plotData
    const keyArray = Object.keys(plotData).filter(function (letter) {
      return letter !== "cycle"
    })
    if (plotData && keyArray.length > 0) {
      const timeValue = plotData.cycle
      if (timeValue.length < 1) {
        setSodNoData(true)
        return
      }
      const newDataValue = _.pick(plotData, keyArray)
      const chartData: any = []
      _.times(keyArray.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = keyArray[item]
        dataNew.values = dataCycleConvert({ cycle: timeValue, value: newDataValue[keyArray[item]] || [] })
        chartData.push(dataNew)
      })

      setSodCurrentData(chartData)
      setSodUpdateData(chartData)
      setSodNoData(false)
    } else {
      setSodNoData(true)
    }
  }

  useEffect(() => {
    console.log("sos data checkckckck")
    if (sod && Object.keys(sod).length > 0) {
      createSodData()
    } else {
      setSodNoData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sod])
  useEffect(() => {
    if (sos && Object.keys(sos).length > 0) {
      createSosData()
    } else {
      setSosNoData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sos])

  useEffect(() => {
    if (sodCurrentData && sodCurrentData.length > 0) {
      const legend: any = []

      const dataKeys = Object.keys(sodCurrentData)
      dataKeys.map((item, index) => {
        return legend.push({ title: sodCurrentData[index].name, color: sodCurrentData[index].color, checked: true })
      })
      setNewSodLegend(legend)
    }
    if (sosCurrentData && sosCurrentData.length > 0) {
      const legend: any = []

      const dataKeys = Object.keys(sosCurrentData)
      dataKeys.map((item, index) => {
        return legend.push({ title: sosCurrentData[index].name, color: sosCurrentData[index].color, checked: true })
      })
      setNewSosLegend(legend)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sodCurrentData, sosCurrentData])

  useEffect(() => {
    if (newSodLegend) {
      const nextData: any = []
      // eslint-disable-next-line array-callback-return
      newSodLegend.map((item, index) => {
        if (item.checked) nextData.push(sodCurrentData[index])
      })
      setSodUpdateData(nextData)
    }
    if (newSosLegend) {
      const nextData: any = []
      // eslint-disable-next-line array-callback-return
      newSosLegend.map((item, index) => {
        if (item.checked) nextData.push(sosCurrentData[index])
      })
      setSosUpdateData(nextData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSodLegend, newSosLegend])
  return (
    <div className="line-chart-wrapper" style={style}>
      <div className="page-section-title-wrapper">
        <div className="page-section-title">SoS / SoD</div>
        {select ? (
          <div className="chart-form-wrapper" style={{ padding: "34px" }}>
            {isSox ? (
              <EESelect
                value={soxState}
                onChange={(newValue) => {
                  setSoxState(Number(newValue.value))
                }}
                options={stateOption}
              />
            ) : null}
            <EESelect
              value={containerSelect}
              onChange={(newValue) => {
                setContainerSelect(newValue)
                dataParam.container = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
              }}
              options={containerOption}
            />

            <EESelect
              value={stackSelect}
              onChange={(newValue) => {
                setStackSelect(newValue)
                dataParam.stack = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
              }}
              options={stackOption}
            />
            <EESelect
              disabled={false}
              value={nodeSelect}
              onChange={(newValue) => {
                setNodeSelect(newValue)
                dataParam.node = newValue.value === OPTION_NONE_SELECT_VALUE ? null : newValue.value
              }}
              options={nodeOption}
            />
          </div>
        ) : null}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <div style={{ width: "50%" }}>
          <span style={{ paddingLeft: "24px", color: "var(--chart-title)" }}>SOS</span>
          <div className="chart-legend-wrapper">
            <div style={{ width: "100%" }}>
              {sos && sosUpdateData && sosUpdateData.length > 0 ? (
                <EEOperationChart
                  data={sosUpdateData}
                  type="cycle"
                  range={[sos.minPoint, sos.maxPoint]}
                  handleBrushed={function (timeRange: any): boolean {
                    throw new Error("Function not implemented.")
                  }}
                  minPoint={sos.minValuePoint}
                  maxPoint={sos.maxValuePoint}
                  divId={"1"}
                  multi
                  brush={false}
                  scale={sosUnit}
                />
              ) : !sosNoData && !sosError ? (
                <EELoading />
              ) : sosError ? (
                <div className="chart-no-data">{sosError}</div>
              ) : (
                <div className="chart-no-data">No Data</div>
              )}
            </div>
            <div className="ee-legend-container">
              <div className="ee-legend-button" onClick={() => handleDisplayAll("sos")}>
                Display All
              </div>
              {newSosLegend &&
                newSosLegend.map((item, index) => (
                  <EELegend
                    title={item.title}
                    color={item.color}
                    isToggle
                    checked={item.checked}
                    onToggleChange={() => handleToggleChange(index, "sos")}
                    key={index}
                  />
                ))}
            </div>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <span style={{ paddingLeft: "24px", color: "var(--chart-title)" }}>SOD</span>

          <div className="chart-legend-wrapper">
            <div style={{ width: "100%" }}>
              {sod && sodUpdateData && sodUpdateData.length > 0 ? (
                <EEOperationChart
                  data={sodUpdateData}
                  type="cycle"
                  range={[sod.minPoint, sod.maxPoint]}
                  handleBrushed={function (timeRange: any): boolean {
                    throw new Error("Function not implemented.")
                  }}
                  minPoint={sod.minValuePoint}
                  maxPoint={sod.maxValuePoint}
                  divId={"2"}
                  multi
                  brush={false}
                  scale={sodUnit}
                />
              ) : !sodNoData && !sodError ? (
                <EELoading />
              ) : sodError ? (
                <div className="chart-no-data">{sodError}</div>
              ) : (
                <div className="chart-no-data">No Data</div>
              )}
            </div>
            <div className="ee-legend-container">
              <div className="ee-legend-button" onClick={() => handleDisplayAll("sod")}>
                Display All
              </div>
              {newSodLegend &&
                newSodLegend.map((item, index) => (
                  <EELegend
                    title={item.title}
                    color={item.color}
                    isToggle
                    checked={item.checked}
                    onToggleChange={() => handleToggleChange(index, "sod")}
                    key={index}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultiChartPanel
