import * as React from "react"
import "./chartWithPeriod.scss"

import { useEffect, useState } from "react"
import EELegend from "../../component/charts/EELegend"

import EELoading from "../../component/Loading"
import { chartColors } from "../../common/chartColors"
import ScatterChart from "../../component/charts/ScatterChart"
import EEOperationChart from "../../component/charts/EEOperationChart"
import _ from "lodash"
import { dataConvert } from "../../common/util"
import { useAppContext } from "../../context/AppContext"
import { OperationParam } from "../../type/chart"
import { useQuery } from "@tanstack/react-query"

interface IChartWithLegendProps {
  title: string
  apiCall: any //this prop will be used to fetch chartdata react-query key will be passed
  // data: any // post api data
  chartLevel: string
  chartType?: string
  dataParam: OperationParam
  range: [number, number]
  style?: any
}

const ChartWithLegend: React.FunctionComponent<IChartWithLegendProps> = ({
  apiCall,
  title,
  chartLevel,
  // data,
  dataParam,
  chartType = "line",
  range,
  style,
}) => {
  const appContext = useAppContext()
  const { postOperationChartData } = appContext
  const [currentData, setCurrentData] = useState<any>([])
  const [updateData, setUpdateData] = useState<any>([])
  const [newLegend, setNewLegend] = useState<any>()
  // const [maxPoint, setMaxPoint] = useState()
  // const [minPoint, setMinPoint] = useState()
  const [noData, setNodata] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [unit, setUnit] = useState()
  const { data } = useQuery({
    queryKey: apiCall,
    queryFn: async () => {
      const data = await postOperationChartData(dataParam)
      if (data.statusCode === 500 || data.statusCode === 400) {
        setErrorMessage("Internal Server Error")
        setCurrentData(null)
        setUpdateData(null)
        setNewLegend(null)
        return null
      }
      return data
    },
  })

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      createData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, apiCall])
  const createData = () => {
    setErrorMessage("")
    setNodata(false)
    // setMaxPoint(data.maxPoint)
    // setMinPoint(data.minPoint)
    setUnit(data.unit)
    const keyArray = Object.keys(data.plotData).filter(function (letter) {
      return letter !== "time"
    })
    if (data.plotData && keyArray.length > 0) {
      const timeValue = data.plotData.time
      if (timeValue.length < 1) {
        setNodata(true)
        return
      }
      const newDataValue = _.pick(data.plotData, keyArray)
      const chartData: any = []
      _.times(keyArray.length, function (item) {
        const dataNew: any = { color: "", values: [], name: "" }
        dataNew.color = chartColors[item]
        dataNew.name = keyArray[item]
        dataNew.values = dataConvert({ time: timeValue, value: newDataValue[keyArray[item]] || [] })

        chartData.push(dataNew)
      })

      setCurrentData(chartData)
      setUpdateData(chartData)
      setNodata(false)
    } else {
      setNodata(true)
    }
  }
  const handleToggleChange = (toggleIndex) => {
    const nextLegend = newLegend.map((item, index) => {
      if (toggleIndex === index) {
        return { ...item, checked: !item.checked }
      }
      return item
    })

    setNewLegend(nextLegend)
  }

  useEffect(() => {
    if (newLegend) {
      const nextData: any = []
      newLegend.map((item, index) => {
        if (item.checked) return nextData.push(currentData[index])
        return null
      })
      setUpdateData(nextData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLegend])

  const handleDisplayAll = () => {
    if (newLegend) {
      const nextLegend = newLegend.map((item) => {
        return { ...item, checked: true }
      })
      setNewLegend(nextLegend)
    }
  }

  // const handleBrushed = React.useCallback(
  //   (timeRange) => {
  //     if (dayjs(timeRange.end).valueOf() - dayjs(timeRange.start).valueOf() < 10000) {
  //       // setUpdateData(currentData)
  //       return
  //     }
  //     setZoomedData([...zoomedData, { data: currentData, minPoint, maxPoint }])
  //     setTodate(timeRange.end)
  //     setFromdate(timeRange.start)
  //     setUpdateData(null)

  //     return true
  //   },
  //   [zoomedData, currentData]
  // )
  // const handleZoomout = React.useCallback(() => {
  //   if (zoomedData.length > 0) {
  //     setCurrentData(zoomedData[zoomedData.length - 1].data)
  //     setMaxPoint(zoomedData[zoomedData.length - 1].maxPoint)
  //     setMinPoint(zoomedData[zoomedData.length - 1].minPoint)
  //     setZoomedData((prev) => prev.slice(0, zoomedData.length - 1))
  //   }
  // }, [zoomedData, currentData])
  return (
    <div className="line-chart-wrapper" style={style}>
      <div className="line-chart-title">{title}</div>
      <div className="chart-legend-wrapper">
        <div style={{ width: "100%" }}>
          {updateData && updateData.length > 0 ? (
            <>
              {chartType === "scatter" ? (
                <ScatterChart data={updateData} type={""} range={[0, 100]} />
              ) : (
                <EEOperationChart
                  data={updateData}
                  handleBrushed={function (timeRange: any): boolean {
                    console.log("brushed")
                    return true
                  }}
                  scale={unit}
                  minPoint={range[0]}
                  maxPoint={range[1]}
                  divId={""}
                  type={""}
                />
              )}
            </>
          ) : !noData ? (
            <EELoading />
          ) : errorMessage ? (
            <div className="chart-no-data">{errorMessage}</div>
          ) : (
            <div className="chart-no-data">No Data</div>
          )}
        </div>
        <div className="ee-legend-container">
          {updateData && updateData.length > 0 ? (
            <div className="ee-legend-button" onClick={handleDisplayAll}>
              Display All
            </div>
          ) : null}
          {newLegend &&
            newLegend.map((item, index) => (
              <EELegend
                title={item.title}
                color={item.color}
                isToggle
                checked={item.checked}
                onToggleChange={() => handleToggleChange(index)}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ChartWithLegend
