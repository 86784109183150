import * as React from "react"
import { useLocation } from "react-router-dom"
import AlertLocation from "../../component/AlertLocation"
import "./soxDetail.scss"
import { useState } from "react"
// import ReportDialog from "../../component/dialogs/ReportDialog"
// import RequestDialog from "../../component/dialogs/RequestDialog"
import ChartWithLegend from "../Chart/ChartWithLegend"
import dayjs from "dayjs"
import { PlotType } from "../../constant"
import MultiChartPanel from "../Chart/MultiChartPanel"
import { useAppContext } from "../../context/AppContext"

interface IEEReportDetailProps {}

const SOXDetail: React.FunctionComponent<IEEReportDetailProps> = (props) => {
  const [requestDialogOpen, setRequestDialogOpen] = useState(false)
  const [reportDialogOpen, setReportDialogOpen] = useState(false)
  const [alarmData, setAlarmData] = useState()
  // path: 이전 경로, data: 이전 데이터
  const location = useLocation()
  const { state } = location
  const appContext = useAppContext()
  const { getAlarm, createRequest } = appContext

  React.useEffect(() => {
    if (state) {
      getAlarmHistory()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getAlarmHistory = async () => {
    const response = await getAlarm(state.id)
    setAlarmData(response.data)
  }

  const handleRequest = async (paramData) => {
    const response = await createRequest(paramData)
    if (response.statusCode === 200) {
      getAlarmHistory()
    }
  }
  return (
    <div className="report-detail-wrapper">
      <span style={{ marginBottom: "24px" }}>Time: {dayjs(state.createdAt).format("YYYY/MM/DD hh:mm:ss")}</span>

      <div className="alert-location-section ">
        {alarmData ? <AlertLocation data={alarmData} isDialog={false} request={handleRequest} /> : null}
      </div>

      <MultiChartPanel dataParam={state} style={{ marginLeft: "0px !important", marginRight: "0px" }} isSox />
      <ChartWithLegend
        title={"Cell Power & Node Current"}
        style={{ marginLeft: "0px !important", marginRight: "0px" }}
        apiCall={[
          "operationData",
          {
            plotType: PlotType.currentPower,
            container: 0,
            node: state.node,
            stack: state.stack,
            start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
            end: dayjs(state.createdAt).add(30, "minute").valueOf(),
          },
        ]}
        dataParam={{
          plotType: PlotType.currentPower,
          container: 0,
          node: state.node,
          stack: state.stack,
          start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
          end: dayjs(state.createdAt).add(30, "minute").valueOf(),
        }}
        // data={undefined}
        chartLevel="node"
        range={[-62, -64]}
      />
      <ChartWithLegend
        style={{ marginLeft: "0px !important", marginRight: "0px" }}
        range={[3.6, 3.3]}
        title={"Cell Voltage"}
        apiCall={[
          "operationData",
          {
            plotType: PlotType.voltage,
            container: 0,
            node: state.node,
            stack: state.stack,
            start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
            end: dayjs(state.createdAt).add(30, "minute").valueOf(),
          },
        ]}
        dataParam={{
          plotType: PlotType.voltage,
          container: 0,
          node: state.node,
          stack: state.stack,
          start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
          end: dayjs(state.createdAt).add(30, "minute").valueOf(),
        }}
        // data={undefined}
        chartLevel="node"
      />
      <ChartWithLegend
        style={{ marginLeft: "0px !important", marginRight: "0px" }}
        range={[14, 30]}
        title={"Cell State of Charge"}
        apiCall={[
          "operationData",
          {
            plotType: PlotType.stateOfCharge,
            container: 0,
            node: state.node,
            stack: state.stack,
            start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
            end: dayjs(state.createdAt).add(30, "minute").valueOf(),
          },
        ]}
        dataParam={{
          plotType: PlotType.stateOfCharge,
          container: 0,
          node: state.node,
          stack: state.stack,
          start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
          end: dayjs(state.createdAt).add(30, "minute").valueOf(),
        }}
        // data={undefined}
        // data={ChartData.soc}
        chartLevel="node"
      />
      <ChartWithLegend
        style={{ marginLeft: "0px !important", marginRight: "0px" }}
        range={[17, 23]}
        title={"Sensor Temperature"}
        apiCall={[
          "operationData",
          {
            plotType: PlotType.temperature,
            container: 0,
            node: state.node,
            stack: state.stack,
            start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
            end: dayjs(state.createdAt).add(30, "minute").valueOf(),
          },
        ]}
        dataParam={{
          plotType: PlotType.temperature,
          container: 0,
          node: state.node,
          stack: state.stack,
          start: dayjs(state.createdAt).subtract(30, "minute").valueOf(),
          end: dayjs(state.createdAt).add(30, "minute").valueOf(),
        }}
        // data={undefined}
        chartLevel="node"
      />
      {/* <ReportDialog
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        title={`Report ${dayjs(state.createdAt).format("YYYY/MM/DD hh:mm:ss")}`}
      />
      <RequestDialog
        open={requestDialogOpen}
        onClose={() => setRequestDialogOpen(false)}
        title={`Alert ${dayjs(state.createdAt).format("YYYY/MM/DD hh:mm:ss")}`}
      /> */}
    </div>
  )
}

export default SOXDetail
